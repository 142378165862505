import React, { useState, useEffect } from 'react';
import { Button, TableCell, TableRow } from 'shared';
import { makeStyles, Typography } from '@material-ui/core';
import { useMutation } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import { VALIDATE_VOUCHER } from './queries';

const useStyles = makeStyles((theme) => ({
    center: {
        display: 'inline-flex',
        alignItems: 'center',
        color: theme.palette.text.secondaryLight
    },
    title: {
        color: theme.palette.text.secondary
    },
    time: {
        marginRight: theme.spacing(1)
    },

    actionButton: {
        border: '1px solid ' + theme.palette.text.secondaryLight,
        borderRadius: '50%',
        color: theme.palette.text.secondary,
        background: theme.palette.common.white,
        width: '50px',
        height: '50px',
        padding: '10px',
        marginLeft: '20px',
        cursor: 'pointer',
        transition: 'all 300ms ease',
        '&:hover': {
            border: '1px solid ' + theme.palette.text.secondary,
            background: theme.palette.text.secondary,
            color: theme.palette.common.white
        }
    }
}));

const VoucherRow = (props) => {
    const {
        voucher: {
            id,
            code,
            claimedOn,
            user: {
                careerProfile: { careerAdvisor = {} }
            }
        }
    } = props;
    const { t } = useTranslation();
    const [validated, setValidated] = useState(false);
    const [validationDate, setValidationDate] = useState(claimedOn);
    const classes = useStyles();
    const [validateVoucher, { data = {}, loading }] = useMutation(VALIDATE_VOUCHER);

    useEffect(() => {
        if (!loading && data.validateVoucher && data.validateVoucher.claimedOn) {
            setValidated(true);
            setValidationDate(data.validateVoucher.claimedOn);
        }
    }, [loading, data.validateVoucher]);

    const handleClick = (id) => {
        validateVoucher({
            variables: {
                id: id
            }
        });
    };

    const formatDate = (string) => {
        let _date = new Date(string);
        return _date.toLocaleString();
    };

    return (
        <TableRow className={classes.row}>
            <TableCell className={classes.nameCell}>
                <Typography className={classes.center}>{id}</Typography>
            </TableCell>
            <TableCell>
                <Typography className={classes.title}>{code}</Typography>
            </TableCell>
            <TableCell>
                <Typography className={classes.title}>
                    {careerAdvisor && careerAdvisor.profile && careerAdvisor.profile.fullName
                        ? careerAdvisor.profile.fullName
                        : '-'}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography className={classes.title}>
                    {claimedOn || validated ? (
                        <React.Fragment>
                            {t('vouchers.activatedSince')} {formatDate(validationDate)}
                        </React.Fragment>
                    ) : (
                        <Button label={t('vouchers.activate')} variant="outlined" onClick={() => handleClick(id)} />
                    )}
                </Typography>
            </TableCell>
        </TableRow>
    );
};

export default VoucherRow;
