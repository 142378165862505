import gql from 'graphql-tag';

export const GET_VACANCY_TYPES = gql`
    query vacancyTypes($pagination: PaginationInput) {
        paginatedVacancyTypes(pagination: $pagination) {
            count
            types {
                id
                description
            }
        }
    }
`;

export const GET_VACANCY_TYPE = gql`
    query vacancyType($id: Long!) {
        vacancyType(id: $id) {
            description
            id
            isAvailableAsSpecialty
            explanation
        }
    }
`;

export const CREATE_VACANCY_TYPE = gql`
    mutation createVacancyType($input: VacancyTypeInput!) {
        createVacancyType(input: $input) {
            id
        }
    }
`;

export const UPDATE_VACANCY_TYPE = gql`
    mutation updateVacancyType($input: VacancyTypeInput!) {
        updateVacancyType(input: $input) {
            id
        }
    }
`;

export const DELETE_VACANCY_TYPE = gql`
    mutation deleteVacancyType($input: VacancyTypeInput!) {
        deleteVacancyType(input: $input)
    }
`;
