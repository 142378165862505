import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { TableCell, TableRow } from 'shared';
import { ArrowRightAlt } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    center: {
        display: 'inline-flex',
        alignItems: 'center',
        color: theme.palette.text.secondaryLight
    },
    title: {
        color: theme.palette.text.secondary
    },
    actionButton: {
        border: '1px solid ' + theme.palette.text.secondaryLight,
        borderRadius: '50%',
        color: theme.palette.text.secondary,
        background: theme.palette.common.white,
        width: '50px',
        height: '50px',
        padding: theme.spacing(1.25),
        marginLeft: theme.spacing(2.5),
        cursor: 'pointer',
        transition: 'all 300ms ease',
        '&:hover': {
            border: '1px solid ' + theme.palette.text.secondary,
            background: theme.palette.text.secondary,
            color: theme.palette.common.white
        }
    }
}));

const CrawlerSiteRow = (props) => {
    const {
        site: { id, name, active, searchTerms, positiveKeywords, negativeKeywords },
        onClick
    } = props;
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <TableRow className={classes.row}>
            <TableCell className={classes.nameCell}>
                <Typography className={classes.center}>{name}</Typography>
            </TableCell>
            <TableCell className={classes.nameCell}>
                <Typography className={classes.title}>{t(`crawler.sites.active.${active ? 'yes' : 'no'}`)}</Typography>
            </TableCell>
            <TableCell className={classes.nameCell}>
                <Typography className={classes.title}>
                    {(searchTerms && searchTerms.split(',').join(', ')) || '-'}
                </Typography>
            </TableCell>
            <TableCell className={classes.nameCell}>
                <Typography className={classes.title}>
                    {(positiveKeywords && positiveKeywords.split(',').join(', ')) || '-'}
                </Typography>
            </TableCell>
            <TableCell className={classes.nameCell}>
                <Typography className={classes.title}>
                    {(negativeKeywords && negativeKeywords.split(',').join(', ')) || '-'}
                </Typography>
            </TableCell>
            <TableCell align="right">
                <div className={classes.center}>
                    <ArrowRightAlt onClick={() => onClick(id)} className={classes.actionButton} />
                </div>
            </TableCell>
        </TableRow>
    );
};

export default CrawlerSiteRow;
