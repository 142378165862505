import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { Table } from 'shared';
import CrawlerVacancyRow from './CrawlerVacancyRow';
import { FilterOverview } from 'shared';

const DuplicateVacancyDialog = (props) => {
    const { open, sites, onDeleteVacancy, onVisitVacancy, vacancies, vacancy, isPlacedVacancy = false } = props;

    const { t } = useTranslation();

    const handleClose = () => {
        if (props.onClose) props.onClose();
    };

    const duplicatesInVacancy = vacancy === undefined ? [] : Object.keys(vacancy.duplicateOffers || []);
    const duplicateVacancies = vacancies.filter(
        (foundVacancy) => duplicatesInVacancy.find((id) => id === foundVacancy.id) !== undefined
    );
    const duplicatePortalVacancies =
        (vacancy &&
            vacancy.duplicateOffersPortal &&
            Object.keys(vacancy.duplicateOffersPortal).map((index) => vacancy.duplicateOffersPortal[index])) ||
        [];

    if (vacancy) {
        if (vacancies.find((foundVacancy) => foundVacancy.id === vacancy.id) === undefined) {
            // User has removed vacancy that is the "current" vacancy in the dialog
            handleClose();
        }
    }

    return (
        <Dialog fullWidth={true} maxWidth="lg" open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{t('crawler.vacancies.duplication.dialog.title')}</DialogTitle>
            <DialogContent>
                <DialogContentText>{t('crawler.vacancies.duplication.dialog.originalVacancy')}</DialogContentText>
                <FilterOverview loading={false} useResults={false} count={vacancy ? 1 : 0}>
                    {vacancy && (
                        <Table
                            head={[
                                t('crawler.vacancies.table.company'),
                                t('crawler.vacancies.table.title'),
                                t('crawler.vacancies.table.crawlDate'),
                                ''
                            ]}
                            renderRows={() =>
                                [vacancy].map((vacancy, i) => (
                                    <CrawlerVacancyRow
                                        key={i}
                                        site={sites[vacancy.siteId]}
                                        vacancy={vacancy}
                                        showDuplicatePercentage={false}
                                        onClick={onVisitVacancy}
                                        onDelete={onDeleteVacancy}
                                        isPlacedVacancy={isPlacedVacancy}
                                        isDuplicateRow
                                    />
                                ))
                            }
                        />
                    )}
                </FilterOverview>

                <DialogContentText>{t('crawler.vacancies.duplication.dialog.description')}</DialogContentText>
                <FilterOverview loading={false} useResults={false} count={duplicateVacancies.length || 0}>
                    {duplicateVacancies && (
                        <Table
                            head={[
                                t('crawler.vacancies.table.company'),
                                t('crawler.vacancies.table.title'),
                                t('crawler.vacancies.table.crawlDate'),
                                t('crawler.vacancies.table.duplicatePercentage'),
                                ''
                            ]}
                            renderRows={() =>
                                duplicateVacancies.map((vacancy, i) => (
                                    <CrawlerVacancyRow
                                        key={i}
                                        site={sites[vacancy.siteId]}
                                        vacancy={vacancy}
                                        isDuplicateRow
                                        onClick={onVisitVacancy}
                                        onDelete={onDeleteVacancy}
                                    />
                                ))
                            }
                        />
                    )}
                </FilterOverview>

                <DialogContentText>
                    {duplicatePortalVacancies && duplicatePortalVacancies.length > 0
                        ? t('crawler.vacancies.duplication.dialog.alreadyInPortal')
                        : t('crawler.vacancies.duplication.dialog.notAlreadyInPortal')}
                </DialogContentText>
                <FilterOverview loading={false} useResults={false} count={duplicatePortalVacancies.length || 0}>
                    {duplicatePortalVacancies && duplicatePortalVacancies.length > 0 && (
                        <Table
                            head={[
                                t('crawler.vacancies.table.company'),
                                t('crawler.vacancies.table.title'),
                                t('crawler.vacancies.table.createdOn'),
                                t('crawler.vacancies.table.duplicatePercentage'),
                                ''
                            ]}
                            renderRows={() =>
                                duplicatePortalVacancies.map((vacancy, i) => (
                                    <CrawlerVacancyRow
                                        key={i}
                                        site={sites[vacancy.siteId]}
                                        portalVacancy={vacancy}
                                        isDuplicatePortalRow
                                        onClick={() => window.open(vacancy.url, '_blank')}
                                        onDelete={onDeleteVacancy}
                                    />
                                ))
                            }
                        />
                    )}
                </FilterOverview>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary" autoFocus>
                    {t('crawler.vacancies.duplication.dialog.close')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DuplicateVacancyDialog;
