import React, { useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import logo from 'assets/images/logo.svg';
import { useMutation } from '@apollo/react-hooks';
import { LOGOUT } from 'api';
import { logoutUser } from 'store/actions';

import { Button, LoginIcon } from 'shared';

import { KeyboardArrowDown, Person } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar, IconButton, MenuItem, Menu } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

const drawerWidth = 340;

const useStyles = makeStyles((theme) => ({
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
        backgroundColor: 'white',
        height: 80
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        })
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    logo: {
        display: 'flex',
        marginRight: 'auto',
        '& img': {
            display: 'block'
        }
    },
    menuButton: {
        marginRight: 36
    },
    menuButtonHidden: {
        display: 'none'
    },
    userInfo: {
        display: 'flex',
        marginLeft: 'auto',
        [theme.breakpoints.up('md')]: {
            alignItems: 'center',
            marginLeft: 'initial'
        }
    },
    loginIcon: {
        marginRight: theme.spacing(1.25)
    }
}));

const AppHeader = (props) => {
    const { open, openDrawer } = props;
    const [buttonOpen, setButtonOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const classes = useStyles();
    const [logout] = useMutation(LOGOUT);
    const history = useHistory();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const user = useSelector((state) => state.auth.user);

    const handleSignOut = () => {
        logout().then((resp) => {
            history.push('/login');
            dispatch(logoutUser());
        });
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);

        if (buttonOpen) {
            setButtonOpen(false);
        } else {
            setButtonOpen(true);
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
        setButtonOpen(false);
    };

    return (
        <>
            <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
                <Toolbar className={classes.toolbar}>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={openDrawer}
                        className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
                    >
                        <MenuIcon color="primary" />
                    </IconButton>
                    <Link className={classes.logo} to="/">
                        <img src={logo} alt="logo" />
                    </Link>
                    {user && (
                        <React.Fragment>
                            <div className={classes.userInfo}>
                                {!user || Object.keys(user).length < 1 ? (
                                    <Button
                                        color="primary"
                                        variant="outlined"
                                        component={Link}
                                        to={'/login'}
                                        label="Inloggen"
                                        iconLeft={() => <LoginIcon className={classes.loginIcon} />}
                                    />
                                ) : (
                                    <React.Fragment>
                                        <div className={classes.buttonWrap}>
                                            <Button
                                                color="primary"
                                                variant="outlined"
                                                onClick={handleClick}
                                                className={classNames(classes.buttonMenu, {
                                                    [classes.buttonMenuActive]: buttonOpen
                                                })}
                                                classes={{ label: classes.personLabel }}
                                                label={
                                                    user ? (
                                                        (user && user.profile && user.profile.fullName) ||
                                                        t('general.profile')
                                                    ) : (
                                                        <Person className={classes.person} />
                                                    )
                                                }
                                                iconRight={() => <KeyboardArrowDown className={classes.arrow} />}
                                            />
                                            <Menu
                                                id="simple-menu"
                                                className={classes.menu}
                                                anchorEl={anchorEl}
                                                keepMounted
                                                open={Boolean(anchorEl)}
                                                onClose={handleClose}
                                            >
                                                <MenuItem onClick={handleSignOut}>
                                                    <ExitToAppIcon className={classes.iconSVG} />
                                                    {t('general.logout')}
                                                </MenuItem>
                                            </Menu>
                                        </div>
                                    </React.Fragment>
                                )}
                            </div>
                        </React.Fragment>
                    )}
                </Toolbar>
            </AppBar>
        </>
    );
};

export default AppHeader;
