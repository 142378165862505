import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, LoaderModal } from 'shared';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useSnackbar } from 'notistack';
import { useMutation } from '@apollo/react-hooks';
import { ARCHIVE_AND_START_NEW_SKILLTEST_FOR_USER } from './query';

const RequestSkillTestResetDialog = (props) => {
    const { onClose, open, user } = props;
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [submitHandle, { loading: mutationLoading, data: handledData }] = useMutation(
        ARCHIVE_AND_START_NEW_SKILLTEST_FOR_USER
    );

    useEffect(() => {
        if (handledData) {
            enqueueSnackbar({
                variant: 'success',
                message: 'De loopbaanwijzer is gereset'
            });
            onClose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [handledData]);

    const handleSubmit = (e) => {
        e.preventDefault();
        submitHandle({
            variables: {
                id: user.id
            }
        });
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">Reset de Loopbaanwijzer-test</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Weet je zeker dat je de Loopbaanwijzer-test voor de gebruiker wilt resetten?
                    <br />
                    Hiermee wordt de huidige Loopbaanwijzer-test gearchiveerd en een nieuwe aangemaakt.
                    <br />
                    Er wordt geen mail verstuurd.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary" label={t('general.cancel')} variant={'contained'} />
                <Button onClick={handleSubmit} color="secondary" label="Reset" variant={'contained'} />
            </DialogActions>
            <LoaderModal loading={mutationLoading} />
        </Dialog>
    );
};

export default RequestSkillTestResetDialog;
