import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'shared';
import { makeStyles } from '@material-ui/styles';
import { useSnackbar } from 'notistack';
import { useMutation } from '@apollo/react-hooks';

import PasswordResetLinkDialog from './PasswordResetLinkDialog';
import { REQUEST_PASSWORD_RESET_LINK_AS_ADMIN } from './query';

const useStyles = makeStyles((theme) => ({
    button: {
        margin: theme.spacing(3, 0, 1)
    }
}));

const RequestPasswordReset = (props) => {
    const { userId } = props;

    const [dialogOpen, setDialogOpen] = useState(false);

    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [requestPasswordResetLinkAsAdmin, { data }] = useMutation(REQUEST_PASSWORD_RESET_LINK_AS_ADMIN);
    const classes = useStyles();

    useEffect(() => {
        if (data) {
            if (data.requestPasswordResetLinkAsAdmin) {
                setDialogOpen(true);
            } else {
                enqueueSnackbar({
                    variant: 'warning',
                    message: t('general.saveError')
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const handleRequest = () => {
        requestPasswordResetLinkAsAdmin({
            variables: {
                id: userId
            }
        });
    };

    return (
        <div>
            <Button
                variant={'outlined'}
                label={t('userEdit.passwordResetRequest') + '..'}
                onClick={handleRequest}
                className={classes.button}
            />

            <PasswordResetLinkDialog open={dialogOpen} onClose={() => setDialogOpen(false)} data={data} />
        </div>
    );
};

export default RequestPasswordReset;
