import gql from 'graphql-tag';

export const SEARCH_VOUCHER = gql`
    query searchVoucher($code: String!) {
        searchVoucher(code: $code) {
            id
            code
            claimedOn
            user {
                careerProfile {
                    careerAdvisor {
                        profile {
                            fullName
                        }
                    }
                }
            }
        }
    }
`;

export const VALIDATE_VOUCHER = gql`
    mutation validateVoucher($id: Long!) {
        validateVoucher(input: { voucherId: $id, validated: true }) {
            id
            code
            claimedOn
        }
    }
`;
