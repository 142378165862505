import gql from 'graphql-tag';

export const GET_FOLDERS = gql`
    query AssetFolders($pagination: PaginationInput!) {
        assetFolders(pagination: $pagination) {
            assetFolders {
                id
                name
                canDelete
                isPrivate
                createdOn
                rolePermissions {
                    id
                    filesCreate
                    filesDelete
                    role {
                        id
                        name
                        title
                    }
                }
            }
            count
        }
    }
`;

export const GET_FOLDER = gql`
    query assetFolder($id: Long!) {
        assetFolder(id: $id) {
            id
            name
            canDelete
            isPrivate
            rolePermissions {
                id
                filesCreate
                filesDelete
                role {
                    id
                    name
                    title
                }
            }
        }
    }
`;

export const CREATE_FOLDER = gql`
    mutation createAssetFolder($input: AssetFolderInput!) {
        createAssetFolder(input: $input) {
            id
        }
    }
`;

export const DELETE_FOLDER = gql`
    mutation deleteAssetFolder($input: DeleteAssetFolderInput!) {
        deleteAssetFolder(input: $input)
    }
`;

export const GET_ROLES = gql`
    query getRoles {
        roles {
            roles {
                id
                name
                title
            }
        }
    }
`;

export const UPDATE_FOLDER = gql`
    mutation updateAssetFolder($input: AssetFolderInput!) {
        updateAssetFolder(input: $input) {
            id
            name
            canDelete
            isPrivate
            rolePermissions {
                filesCreate
                filesDelete
                role {
                    id
                }
            }
        }
    }
`;

export const GET_HOUR = gql`
    query getWorkingHour($id: Long!) {
        workingHours(id: $id) {
            upperLimit
            id
            value
            lowerLimit
        }
    }
`;

export const CREATE_WORKINGHOURS = gql`
    mutation createWorkingHours($input: WorkingHoursInput!) {
        createWorkingHours(input: $input) {
            id
        }
    }
`;

export const UPDATE_WORKINGHOURS = gql`
    mutation updateWorkingHours($input: WorkingHoursInput!) {
        updateWorkingHours(input: $input) {
            id
        }
    }
`;

export const DELETE_WORKINGHOURS = gql`
    mutation deleteWorkingHours($input: WorkingHoursInput!) {
        deleteWorkingHours(input: $input)
    }
`;

export const GET_USERS = gql`
    query users {
        users {
            users {
                id
                email
                profile {
                    dateOfBirth
                    firstName
                    middleName
                    lastName
                    fullName
                }
                roles {
                    id
                    name
                    title
                }
                lastLogin
                firstLogin
            }
            count
        }
    }
`;

export const UPLOAD_DOCUMENT = gql`
    mutation UploadDocument($file: Upload, $input: UploadDocumentInput) {
        uploadDocument(file: $file, input: $input) {
            asset {
                id
                contentType
                url
            }
        }
    }
`;
