import { useState } from 'react';
import Cookie from 'js-cookie';

const useHandleSso = (domain) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const handleSso = () => {
        const token = Cookie.get('jwt_flow');
        new Promise(() => {
            fetch(`/api/cookie`, {
                method: 'POST',
                headers: {
                    Authorization: token
                }
            });
        })
            .then(() => {
                setLoading(false);
            })
            .catch((e) => {
                setLoading(false);
                setError(e);
            });
    };

    return [handleSso, loading, error];
};

export default useHandleSso;
