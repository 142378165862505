import React, { useEffect, useState } from 'react';
import { SelectField } from 'shared';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import { CrawlerConfig, CrawlerSites, CrawlerVacancy } from './index';
import queryString from 'query-string';
import { useLocation, useHistory } from 'react-router-dom';
import CrawlerPlacedVacancies from './PlacedVacancies/CrawlerPlacedVacancies';

export const firebaseConfig = {
    apiKey: 'AIzaSyBjHD4qaLPDWVxV-fDbyFIaROVauaCV_iY',
    authDomain: 'flow-crawler.firebaseapp.com',
    databaseURL: 'https://flow-crawler.firebaseio.com',
    projectId: 'flow-crawler',
    storageBucket: 'flow-crawler.appspot.com',
    messagingSenderId: '1036608519632',
    appId: '1:1036608519632:web:4d2c701804fc4bca240dd5'
};

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: '1200px',
        margin: `${theme.spacing(10)}px auto`
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: theme.spacing(0, 4),
        marginBottom: theme.spacing(2)
    }
}));

const Crawler = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const location = useLocation();
    const classes = useStyles();
    const [component, setComponent] = useState('vacancies');

    const options = [
        {
            value: 'vacancies',
            label: t('crawler.vacancies.title')
        },
        {
            value: 'placedVacancies',
            label: t('crawler.placedVacancies.title')
        },
        {
            value: 'sites',
            label: t('crawler.sites.title')
        },
        {
            value: 'config',
            label: t('crawler.config.title')
        }
    ];

    const handleDataChange = (data) => {
        setComponent(data);
        history.push({
            search: queryString.stringify({ option: data })
        });
    };

    useEffect(() => {
        const option = queryString.parse(location.search).option;
        if (option !== undefined && option !== null && options.find((foundOption) => foundOption.value === option)) {
            setComponent(option);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.search]);

    return (
        <Grid container className={classes.root}>
            <Grid item className={classes.header} xs={12}>
                <SelectField
                    placeholder={false}
                    initialValue={component}
                    options={options}
                    label="Selecteer optie"
                    onChange={handleDataChange}
                />
            </Grid>
            <Grid item xs={12}>
                {component === 'vacancies' && <CrawlerVacancy />}
                {component === 'placedVacancies' && <CrawlerPlacedVacancies />}
                {component === 'sites' && <CrawlerSites />}
                {component === 'config' && <CrawlerConfig />}
            </Grid>
        </Grid>
    );
};

export default Crawler;
