import moment from 'moment';
import Moment from 'moment';
import 'moment/locale/nl';

moment.locale('nl');

export function isEmpty(obj) {
    for (var key in obj) {
        // eslint-disable-next-line no-prototype-builtins
        if (obj.hasOwnProperty(key)) return false;
    }
    return true;
}

export const numberToOctalString = (number) => (number < 10 ? `0${number}` : `${number}`);

export const stringIsEmpty = (string) =>
    string === '' || string === undefined || string === null || string.length === 0;

export const setObjectKeysToDefault = (object, keys, defaultValue) => {
    keys.forEach((key) => {
        object[key] = defaultValue;
    });
};

export const isJson = (possibleJson) => {
    try {
        JSON.parse(possibleJson);
        return true;
    } catch (e) {
        return false;
    }
};

export const isUndefinedOrNull = (value) => value === undefined || value === null;
export const isUndefinedOrNullOrEmptyString = (value) => isUndefinedOrNull(value) || value === '';

/**
 * Convert date to readable string
 *
 * @param date {Date} Date to convert
 * @param config {Object} Either a verb with boolean (e.g. year: true, month: true) or a date / time exclude (disabled: {date: true, time: true})
 * @returns {String} Readable date
 */
export const dateToString = (date, config) => {
    if (date === undefined || typeof date.getDate !== 'function' || isNaN(date.getDate())) return '-';

    const conf = {
        day: config.day === undefined ? true : config.day,
        month: config.month === undefined ? true : config.month,
        year: config.year === undefined ? true : config.year,
        hour: config.hour === undefined ? true : config.hour,
        minute: config.minute === undefined ? true : config.minute,
        seconds: config.seconds === undefined ? true : config.seconds
    };

    if (config.disabled && config.disabled.date) setObjectKeysToDefault(conf, ['day, month, year'], false);
    if (config.disabled && config.disabled.time) setObjectKeysToDefault(conf, ['hour', 'minute', 'seconds'], false);

    const dateString = [
        conf.day ? numberToOctalString(date.getDate()) : null,
        conf.month ? numberToOctalString(date.getMonth() + 1) : null,
        conf.year ? numberToOctalString(date.getFullYear()) : null
    ]
        .filter((value) => value !== null)
        .join('-');
    const timeString = [
        conf.hour ? numberToOctalString(date.getHours()) : null,
        conf.minute ? numberToOctalString(date.getMinutes() + 1) : null,
        conf.seconds ? numberToOctalString(date.getSeconds()) : null
    ]
        .filter((value) => value !== null)
        .join(':');

    return [dateString, timeString].filter((item) => !stringIsEmpty(item)).join(' ');
};

export function mapToSelect(array, label, value) {
    if (!array) return;

    const newArray = array.map((item) => {
        return {
            label: item[label],
            value: item[value]
        };
    });

    return newArray;
}

export function formatDate(date) {
    return moment(date).format('D MMMM Y');
}

export function formatDateTime(dateTime, format = 'DD-MM-YYYY', emptyString = '-') {
    return dateTime ? Moment(dateTime).format(format) : emptyString;
}

export function convertToSlug(name) {
    if (!name) return '';
    return name
        .toLowerCase()
        .replace(/ /g, '-')
        .replace(/[^\w-]+/g, '');
}

export function lightenDarkenColor(col, amt) {
    if (!col) return;

    var usePound = false;

    if (col[0] === '#') {
        col = col.slice(1);
        usePound = true;
    }

    var num = parseInt(col, 16);

    var r = (num >> 16) + amt;

    if (r > 255) r = 255;
    else if (r < 0) r = 0;

    var b = ((num >> 8) & 0x00ff) + amt;

    if (b > 255) b = 255;
    else if (b < 0) b = 0;

    var g = (num & 0x0000ff) + amt;

    if (g > 255) g = 255;
    else if (g < 0) g = 0;

    return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16);
}
