import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { MEETINGS_CSV, OPEN_MEETINGS } from 'api';
import { useFilter } from 'hooks';
import { useLazyQuery } from '@apollo/react-hooks';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Table, Button, InputField, DatePicker, FilterOverview, LoaderModal } from 'shared';

import MeetingRow from './MeetingRow';
import { handleExport } from './HandleExport';
import classNames from 'classnames';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
    number: {
        fontWeight: 300,
        color: theme.palette.text.secondaryLight,
        fontSize: '24px',
        marginLeft: `${theme.spacing(1)}px`
    },
    item: {
        marginTop: `${theme.spacing(8)}px`
    },
    title: {
        display: 'flex',
        alignItems: 'center'
    },
    icon: {
        marginRight: `${theme.spacing(2)}px`,
        color: theme.palette.primary.main,
        padding: '2px'
    },
    searchBar: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        padding: theme.spacing(0, 4),
        marginBottom: theme.spacing(2),
        zIndex: 10
    },
    searchButton: {
        width: '220px',
        padding: theme.spacing(1)
    },
    exportButton: {
        marginBottom: theme.spacing(1)
    },
    search: {
        width: '100%',
        marginTop: theme.spacing(1),
        paddingRight: theme.spacing(3),
        marginBottom: 0
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: theme.spacing(0, 4)
    },
    exportHeader: {
        marginTop: theme.spacing(2)
    },
    button: {
        zIndex: '10'
    },
    center: {
        display: 'flex',
        justifyContent: 'center'
    }
}));

const Meetings = () => {
    const classes = useStyles();
    const history = useHistory();
    const [search, setSearch] = useState('');
    const { enqueueSnackbar } = useSnackbar();
    const [exportDates, setExportDates] = useState({
        from: null,
        to: null
    });

    const query = useFilter({
        pagination: { sortProperties: ['id'] },
        filter: {}
    });

    const [getOpenMeetings, { loading, data: meetingsData = {} }] = useLazyQuery(OPEN_MEETINGS);
    const [exportCSV, exportData = {}] = useLazyQuery(MEETINGS_CSV);
    const { meetings = {} } = meetingsData;

    // effect hooks
    useEffect(() => {
        getOpenMeetings({ variables: { ...query.query } });
    }, [getOpenMeetings, query.query]);

    useEffect(() => {
        if (!exportData.loading && exportData.data) {
            if (exportData.data.meetingsCsv && exportData.data.meetingsCsv.base64Content) {
                handleExport(exportData.data.meetingsCsv.base64Content);
            } else {
                enqueueSnackbar({
                    variant: 'success',
                    message: 'Geen meetings om te exporteren binnen de gekozen data'
                });
            }
        }
    }, [exportData.loading, exportData, enqueueSnackbar]);

    // functions
    const handleDeleteClick = (id, owner) => {
        history.push(`/meetings/remove/${owner.id}/${id}`);
    };

    const handleSearch = () => {
        query.setQuery({
            filter: {
                search: search || ''
            }
        });
    };

    const handleExportClick = () => {
        exportCSV({
            variables: {
                filter: {
                    finishedOnFrom: exportDates.from,
                    finishedOnTo: exportDates.to
                }
            }
        });
    };

    const handleExportDate = (key, value) => {
        setExportDates({
            ...exportDates,
            [key]: value
        });
    };

    if (exportData.loading) return <LoaderModal loading={true} />;
    return (
        <Grid container>
            <Grid item className={classes.header} xs={12}>
                <Typography variant="h4">Openstaande verzoeken</Typography>
            </Grid>

            <Grid item className={classes.searchBar} xs={12}>
                <InputField
                    name="Zoeken"
                    className={classes.search}
                    initialValue={query.filters.search || ''}
                    onChange={(value) => setSearch(value)}
                    type="text"
                    label="Zoeken naar gebruiker of meeting-code"
                />
                <Button
                    className={classes.searchButton}
                    variant="contained"
                    color="primary"
                    label="Zoek"
                    onClick={handleSearch}
                />
            </Grid>
            <Grid item className={classNames(classes.header, classes.exportHeader)} xs={12}>
                <Typography variant="h4">Exporteren</Typography>
            </Grid>
            <Grid item className={classes.searchBar} xs={12}>
                <Grid item xs={4}>
                    <DatePicker
                        label="Startdatum"
                        placeholder=""
                        disablePast={false}
                        helper={false}
                        name="from"
                        onChange={(value) => handleExportDate('from', value)}
                        initialValue={exportDates.from}
                        setHours="00"
                    />
                </Grid>
                <Grid item xs={4}>
                    <DatePicker
                        label="Einddatum"
                        placeholder=""
                        disablePast={false}
                        helper={false}
                        name="to"
                        onChange={(value) => handleExportDate('to', value)}
                        initialValue={exportDates.to}
                        setHours="24"
                    />
                </Grid>
                <Grid item xs={3}>
                    <Button
                        className={classNames(classes.searchButton, classes.exportButton)}
                        variant="contained"
                        color="primary"
                        label="Exporteer csv"
                        onClick={handleExportClick}
                    />
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <FilterOverview query={query} loading={loading} count={meetings.count || 0}>
                    {meetings.meetings && (
                        <Table
                            head={['Ronde', 'Code', 'Naam', 'E-mailadres', 'Datum gesprek', 'status', '']}
                            renderRows={() =>
                                meetings.meetings.map((meeting) => (
                                    <MeetingRow onDelete={handleDeleteClick} key={meeting.id} meeting={meeting} />
                                ))
                            }
                        />
                    )}
                </FilterOverview>
            </Grid>
        </Grid>
    );
};

export default Meetings;
