export const setExpertises = (value) => (dispatch) => {
    dispatch({
        type: SET_EXPERTISES,
        payload: value
    });
};

export const setSkills = (value) => (dispatch) => {
    dispatch({
        type: SET_SKILLS,
        payload: value
    });
};

export const fetchExpertises = (id) => (dispatch) => {
    // client
    //     .query({
    //         query: GET_USER,
    //         variables: {
    //             id: id
    //         }
    //     })
    //     .then(result => {
    //         const userData = result.data.user;
    //         if (userData) {
    //             return dispatch(setExpertises(userData));
    //         }
    //     });
    setTimeout(() => {
        dispatch(setExpertises(mockData));
    }, 1000);
};

export const fetchSkills = (id) => (dispatch) => {
    // client
    //     .query({
    //         query: GET_USER,
    //         variables: {
    //             id: id
    //         }
    //     })
    //     .then(result => {
    //         const userData = result.data.user;
    //         if (userData) {
    //             return dispatch(setExpertises(userData));
    //         }
    //     });
    setTimeout(() => {
        dispatch(setSkills(mockData));
    }, 1000);
};

export const SET_EXPERTISES = 'SET_EXPERTISES';
export const SET_SKILLS = 'SET_SKILLS';

const mockData = [
    {
        label: 'Managment',
        value: 'managment'
    },
    {
        label: 'Directie / RvB',
        value: 'seo'
    },
    {
        label: 'Financieel / Administratief / Assetmanagement',
        value: 'financial'
    },
    {
        label: 'HRM',
        value: 'hrm'
    },
    {
        label: 'ICT',
        value: 'ict'
    },
    {
        label: 'RvC',
        value: 'rvc'
    },
    {
        label: 'Strategie en Beleid',
        value: 'strategy'
    },
    {
        label: 'Verhuur en verkoop',
        value: 'sales'
    },
    {
        label: 'Managment',
        value: 'managment'
    },
    {
        label: 'Directie / RvB',
        value: 'seo'
    },
    {
        label: 'Financieel / Administratief / Assetmanagement',
        value: 'financial'
    },
    {
        label: 'HRM',
        value: 'hrm'
    },
    {
        label: 'ICT',
        value: 'ict'
    },
    {
        label: 'RvC',
        value: 'rvc'
    },
    {
        label: 'Strategie en Beleid',
        value: 'strategy'
    },
    {
        label: 'Verhuur en verkoop',
        value: 'sales'
    }
];
