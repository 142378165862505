import gql from 'graphql-tag';

export const UPDATE_MEETING = gql`
    mutation updateMeeting($input: MeetingInput!) {
        updateMeeting(input: $input) {
            id
            status
            finishedOn
            owner {
                id
                email
            }
        }
    }
`;
