import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useMutation } from '@apollo/react-hooks';
import { InputField, Button, FileUpload } from 'shared';
import { LoaderModal } from 'shared';
import { useForm } from 'hooks';

import { CREATE_REGION, UPLOAD_FILE } from './queries';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: `${theme.spacing(10)}px auto`,
        padding: theme.spacing(0, 4),
        maxWidth: '800px'
    },
    paper: {
        width: '100%',
        padding: theme.spacing(7, 8, 8, 7),
        borderRadius: '25px',

        '& .MuiGrid-grid-xs-6': {
            maxWidth: 'calc(50% - 50px)',
            flexBasis: 'calc(50% - 50px)',
            '& .MuiGrid-grid-xs-6': {
                maxWidth: 'calc(50% - 15px)',
                flexBasis: 'calc(50% - 15px)'
            }
        }
    },
    title: {
        textAlign: 'center',
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(2)
    },
    label: {
        padding: theme.spacing(2.25, 2)
    },
    heading: {
        ...theme.typography.h5,
        fontWeight: 600,
        paddingBottom: theme.spacing(2),
        borderBottom: '1px solid ' + theme.palette.borderColor.main,
        marginBottom: theme.spacing(1),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    input: {
        width: '100%',
        marginLeft: 0,
        '& .MuiInputLabel-shrink': {
            transform: 'none',
            top: '-20px',
            lineHeight: '28px',
            fontWeight: '500',
            fontSize: '18px'
        }
    },
    loaderPopup: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        background: theme.palette.common.white,
        padding: theme.spacing(4, 5),
        borderRadius: '20px',
        zIndex: theme.zIndex.modal
    },
    logoHeading: {
        margin: theme.spacing(2, 0)
    },
    bottom: {
        marginTop: theme.spacing(2)
    },
    button: {
        marginRight: theme.spacing(2)
    },
    logo: {
        maxWidth: '450px',
        width: '100%',
        marginBottom: theme.spacing(2),

        '& img': {
            width: '100%'
        }
    }
}));

const formSettings = {};

const AddRegion = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const [form, submitForm] = useForm(formSettings);
    const [createRegion, { loading: mutationLoading, data }] = useMutation(CREATE_REGION);

    const [uploadFile] = useMutation(UPLOAD_FILE);

    useEffect(() => {
        if (data && data.createRegion) {
            const id = data.createRegion.id;
            if (id) {
                enqueueSnackbar({
                    variant: 'success',
                    message: t('addRegion.saved')
                });
                history.push(`/regions`);
            } else {
                enqueueSnackbar({
                    variant: 'warning',
                    message: t('addRegion.saveError')
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const handleCancel = () => {
        history.goBack();
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        submitForm().then((resp) => {
            if (resp) {
                createRegion({
                    variables: {
                        input: {
                            description: resp.description,
                            ...(resp.logo && {
                                logo: {
                                    id: resp.logo
                                }
                            })
                        }
                    }
                })
                    .then((result) => {
                        if (result.errors) {
                            enqueueSnackbar({
                                variant: 'warning',
                                message: t('addRegion.saveError')
                            });
                        }
                    })
                    .catch(() => {
                        enqueueSnackbar({
                            variant: 'warning',
                            message: t('addRegion.saveError')
                        });
                    });
            }
        });
    };

    const handleFile = (file) => {
        if (file) {
            uploadFile({
                variables: {
                    file: file
                },
                context: {
                    hasUpload: true
                }
            })
                .then((response) => {
                    const fileId = response.data.uploadFile.asset.id;
                    if (fileId) form.onFieldChange({ key: 'logo', value: fileId });
                })
                .catch((error) => {
                    console.warn('uploadFile error: ', error);
                });
        }
    };

    const clearFile = (file) => {
        form.onFieldChange({ key: 'logo', value: '' });
    };

    return (
        <Grid container justify="center" className={classes.root}>
            <Paper className={classes.paper} elevation={0}>
                <Typography className={classes.heading}>
                    <span>{t('addRegion.title')}</span>
                </Typography>

                <Grid container justify="space-between">
                    <Grid item xs={12}>
                        <InputField
                            name="description"
                            className={classes.input}
                            initialValue={''}
                            type="text"
                            label={t('addRegion.description')}
                            form={form}
                        />

                        <FileUpload
                            label={t('corporations.form.logo')}
                            onSucces={handleFile}
                            name="logo"
                            initialValue={''}
                            onClear={clearFile}
                            form={form}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} className={classes.bottom}>
                    <Button
                        variant="contained"
                        className={classes.button}
                        color="primary"
                        label={t('general.cancel')}
                        onClick={handleCancel}
                    />

                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleSubmit}
                        label={t('addRegion.button')}
                    />
                </Grid>
            </Paper>
            <LoaderModal loading={mutationLoading} />
        </Grid>
    );
};

export default AddRegion;
