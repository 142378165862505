import gql from 'graphql-tag';

export const GET_EXPERTISES = gql`
    query expertises($pagination: PaginationInput) {
        expertises(pagination: $pagination) {
            count
            expertises {
                id
                description
            }
        }
    }
`;

export const GET_EXPERTISE = gql`
    query expertise($id: Long!) {
        expertise(id: $id) {
            description
            id
        }
    }
`;

export const CREATE_EXPERTISE = gql`
    mutation createExpertise($input: ExpertiseInput!) {
        createExpertise(input: $input) {
            id
        }
    }
`;

export const UPDATE_EXPERTISE = gql`
    mutation updateExpertise($input: ExpertiseInput!) {
        updateExpertise(input: $input) {
            id
        }
    }
`;

export const DELETE_EXPERTISE = gql`
    mutation deleteExpertise($input: ExpertiseInput!) {
        deleteExpertise(input: $input)
    }
`;
