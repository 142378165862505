import React, { useState, useEffect } from 'react';
import { Button } from 'shared';
import { makeStyles } from '@material-ui/styles';

import SendSkillTestReinviteDialog from './SendSkillTestReinviteDialog';

const useStyles = makeStyles((theme) => ({
    button: {
        margin: theme.spacing(3, 0, 1)
    }
}));

const SendSkillTestReinvite = (props) => {
    const { user } = props;
    const [dialogOpen, setDialogOpen] = useState(false);
    const classes = useStyles();

    const openDialog = () => {
        setDialogOpen(true);
    };

    return (
        <div>
            <Button
                variant={'outlined'}
                label="Verstuur de reset loopbaanwijzer e-mail..."
                onClick={openDialog}
                className={classes.button}
            />
            <SendSkillTestReinviteDialog open={dialogOpen} onClose={() => setDialogOpen(false)} user={user} />
        </div>
    );
};

export default SendSkillTestReinvite;
