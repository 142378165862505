import React from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Add } from '@material-ui/icons';
import { Table, Button } from 'shared';
import { useFilter } from 'hooks';
import { FilterOverview, LoaderModal } from 'shared';
import { GET_SKILLS } from './queries';
import { useTranslation } from 'react-i18next';
import SkillsRow from './SkillsRow';

const useStyles = makeStyles((theme) => ({
    number: {
        fontWeight: 300,
        color: theme.palette.text.secondaryLight,
        fontSize: '24px',
        marginLeft: `${theme.spacing(1)}px`
    },
    item: {
        marginTop: `${theme.spacing(8)}px`
    },
    title: {
        display: 'flex',
        alignItems: 'center'
    },
    icon: {
        marginRight: `${theme.spacing(2)}px`,
        color: theme.palette.primary.main,
        padding: theme.spacing(0.25)
    },
    button: {
        zIndex: '10'
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: theme.spacing(0, 4),
        marginBottom: theme.spacing(2)
    }
}));

const Skills = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const history = useHistory();
    const query = useFilter();

    const { loading, data = {} } = useQuery(GET_SKILLS, {
        variables: {
            ...query.query
        }
    });

    const { skills = [] } = data;

    const handleDeleteClick = (id) => {
        history.push(`/skills/remove/${id}`);
    };

    const handleEditClick = (id) => {
        history.push(`/skills/edit/${id}`);
    };

    return (
        <Grid container>
            <Grid item className={classes.header} xs={12}>
                <Typography variant="h4">{t('skills.title')}</Typography>
                <Button
                    component={Link}
                    to={'/skills/add'}
                    variant="contained"
                    label="Toevoegen"
                    color="secondary"
                    className={classes.button}
                    iconLeft={() => <Add />}
                />
            </Grid>
            <Grid item xs={12}>
                <FilterOverview query={query} loading={loading} useResults={false} count={skills.count || 0}>
                    {skills.skills && (
                        <Table
                            head={['ID', 'Beschrijving', '']}
                            renderRows={() =>
                                skills.skills.map((skill) => (
                                    <SkillsRow
                                        onDelete={handleDeleteClick}
                                        onClick={handleEditClick}
                                        key={skill.id}
                                        skill={skill}
                                    />
                                ))
                            }
                        />
                    )}
                </FilterOverview>
            </Grid>
            <LoaderModal loading={loading} />
        </Grid>
    );
};

export default Skills;
