import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Add } from '@material-ui/icons';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { InputField, Button } from 'shared';
import { LoaderModal } from 'shared';
import { useForm } from 'hooks';

import { UPDATE_SKILL, GET_SKILL } from './queries';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: `${theme.spacing(10)}px auto`,
        padding: theme.spacing(0, 4),
        maxWidth: '800px'
    },
    paper: {
        width: '100%',
        padding: theme.spacing(7, 8, 8, 7),
        borderRadius: '25px',

        '& .MuiGrid-grid-xs-6': {
            maxWidth: 'calc(50% - 50px)',
            flexBasis: 'calc(50% - 50px)',
            '& .MuiGrid-grid-xs-6': {
                maxWidth: 'calc(50% - 15px)',
                flexBasis: 'calc(50% - 15px)'
            }
        }
    },
    title: {
        textAlign: 'center',
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(2)
    },
    label: {
        padding: theme.spacing(2.25, 2)
    },
    heading: {
        ...theme.typography.h5,
        fontWeight: 600,
        paddingBottom: theme.spacing(2),
        borderBottom: '1px solid ' + theme.palette.borderColor.main,
        marginBottom: theme.spacing(1),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    input: {
        width: '100%',
        marginLeft: 0,
        '& .MuiInputLabel-shrink': {
            transform: 'none',
            top: '-20px',
            lineHeight: '28px',
            fontWeight: '500',
            fontSize: '18px'
        }
    },
    bottom: {
        marginTop: theme.spacing(2)
    },
    button: {
        marginRight: theme.spacing(2)
    },
    loaderPopup: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        background: theme.palette.common.white,
        padding: theme.spacing(4, 5),
        borderRadius: '20px',
        zIndex: theme.zIndex.modal
    }
}));

const EditSkills = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const params = useParams();
    const { id } = params;
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const [form, submitForm] = useForm();
    const [updateSkills, { loading: mutationLoading, data: updateData }] = useMutation(UPDATE_SKILL);

    const { data = {} } = useQuery(GET_SKILL, {
        variables: {
            id: id
        }
    });

    const [draft, setDraft] = useState({
        id: '',
        description: ''
    });

    useEffect(() => {
        if (updateData) {
            const id = updateData.updateSkill.id;
            if (id) {
                enqueueSnackbar({
                    variant: 'success',
                    message: t('editSkills.saved')
                });
                history.push(`/skills`);
            } else {
                enqueueSnackbar({
                    variant: 'warning',
                    message: t('editSkills.saveError')
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateData]);

    useEffect(() => {
        if (data && data.skill) {
            setDraft(data.skill);
        }
    }, [data]);

    const handleCancel = () => {
        history.goBack();
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        submitForm().then((resp) => {
            if (resp) {
                updateSkills({
                    variables: {
                        input: {
                            id: id,
                            ...resp
                        }
                    }
                });
            }
        });
    };

    return (
        <Grid container justify="center" className={classes.root}>
            <Paper className={classes.paper} elevation={0}>
                <Typography className={classes.heading}>
                    <span>{t('editSkills.title')}</span>
                </Typography>

                <Grid container justify="space-between">
                    <Grid item xs={12}>
                        <InputField
                            name="description"
                            className={classes.input}
                            initialValue={draft.description || ''}
                            type="text"
                            label={t('editSkills.description')}
                            form={form}
                        />
                    </Grid>
                    <Grid item xs={12} className={classes.bottom}>
                        <Button
                            variant="contained"
                            className={classes.button}
                            color="primary"
                            label={t('general.cancel')}
                            onClick={handleCancel}
                        />

                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={handleSubmit}
                            label={t('editSkills.button')}
                            iconLeft={() => <Add />}
                        />
                    </Grid>
                </Grid>
            </Paper>
            <LoaderModal loading={mutationLoading} />
        </Grid>
    );
};

export default EditSkills;
