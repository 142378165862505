import gql from 'graphql-tag';

export const GET_REGIONS = gql`
    query regions($pagination: PaginationInput) {
        regions(pagination: $pagination) {
            count
            regions {
                id
                description
            }
        }
    }
`;

export const GET_REGION = gql`
    query region($id: Long!) {
        region(id: $id) {
            description
            id
            logo {
                url
                thumbnailUrl
                id
            }
        }
    }
`;

export const CREATE_REGION = gql`
    mutation createRegion($input: RegionInput!) {
        createRegion(input: $input) {
            id
        }
    }
`;

export const UPDATE_REGION = gql`
    mutation updateRegion($input: RegionInput!) {
        updateRegion(input: $input) {
            id
        }
    }
`;

export const DELETE_REGION = gql`
    mutation deleteRegion($input: RegionInput!) {
        deleteRegion(input: $input)
    }
`;

export const UPLOAD_FILE = gql`
    mutation UploadFile($file: Upload!) {
        uploadFile(file: $file) {
            asset {
                id
                contentType
                url
            }
        }
    }
`;
