import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { FilterOverview } from 'shared';
import { InputField, Button, Table } from 'shared';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import { useLazyQuery } from '@apollo/react-hooks';
import { SEARCH_VOUCHER } from './queries';
import VoucherRow from './VoucherRow';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: `${theme.spacing(10)}px auto`,
        maxWidth: '1200px'
    },
    header: {
        position: 'relative',
        zIndex: 2,
        padding: theme.spacing(0, 4)
    },
    title: {
        marginBottom: theme.spacing(2)
    },
    searchWrapper: {
        display: 'flex',
        marginTop: theme.spacing(4)
    },
    inputField: {
        width: '100%',
        margin: 0
    },
    inputComponent: {
        height: '100%',
        marginRight: theme.spacing(2),
        '& input': {
            boxSizing: 'border-box',
            height: '100%',
            fontSize: '1.125rem'
        }
    },
    error: {
        marginTop: theme.spacing(1),
        color: theme.palette.error.main
    }
}));

const Vouchers = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [searchInput, setSearchInput] = useState('');
    const [voucherData, setVoucherData] = useState();
    const [searchVoucher, { loading, data = {} }] = useLazyQuery(SEARCH_VOUCHER);

    const handleChange = (input) => {
        setSearchInput(input);
    };

    const handleClick = () => {
        if (searchInput && searchInput.length >= 14) {
            searchVoucher({
                variables: {
                    code: searchInput
                }
            });
        } else {
            setVoucherData(t('voucher.error'));
        }
    };

    useEffect(() => {
        if (!loading && data && data.searchVoucher) {
            setVoucherData(data.searchVoucher);
        }
    }, [loading, data]);

    return (
        <Grid container className={classes.root}>
            <Grid item xs={12} className={classes.header}>
                <Typography variant="h2" className={classes.title}>
                    {t('vouchers.title')}
                </Typography>
                <Typography variant="body1">{t('vouchers.subtitle')}</Typography>

                <div className={classes.searchWrapper}>
                    <InputField
                        className={classes.inputField}
                        placeholder={t('vouchers.placeholder')}
                        InputProps={{
                            className: classes.inputComponent,
                            disableUnderline: true
                        }}
                        autoFocus={true}
                        onChange={handleChange}
                    />
                    <Button label={t('vouchers.check')} variant="contained" color="secondary" onClick={handleClick} />
                </div>
            </Grid>

            <Grid item xs={12}>
                <FilterOverview loading={loading} useResults={false}>
                    {voucherData && (
                        <React.Fragment>
                            {Array.isArray(voucherData) && voucherData.length > 0 ? (
                                <Table
                                    head={['ID', 'Code', 'Loopbaanadviseur', 'Status']}
                                    renderRows={() =>
                                        voucherData.map((voucher, idx) => <VoucherRow key={idx} voucher={voucher} />)
                                    }
                                />
                            ) : (
                                <Typography className={classes.error}>{t('vouchers.error')}</Typography>
                            )}
                        </React.Fragment>
                    )}
                </FilterOverview>
            </Grid>
        </Grid>
    );
};

export default Vouchers;
