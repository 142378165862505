import React from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Add } from '@material-ui/icons';
import { useQuery } from '@apollo/react-hooks';
import { FilterOverview, LoaderModal } from 'shared';
import { Table, Button } from 'shared';
import { useFilter } from 'hooks';
import { GET_FOLDERS } from './queries';
import FolderRow from './FolderRow';

const useStyles = makeStyles((theme) => ({
    number: {
        fontWeight: 300,
        color: theme.palette.text.secondaryLight,
        fontSize: '24px',
        marginLeft: `${theme.spacing(1)}px`
    },
    item: {
        marginTop: `${theme.spacing(8)}px`
    },
    title: {
        display: 'flex',
        alignItems: 'center'
    },
    icon: {
        marginRight: `${theme.spacing(2)}px`,
        color: theme.palette.primary.main,
        padding: '2px'
    },
    button: {
        zIndex: '10'
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: theme.spacing(0, 4),
        marginBottom: theme.spacing(2)
    }
}));

const Folders = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const history = useHistory();
    const query = useFilter();

    const { loading, data = {} } = useQuery(GET_FOLDERS, {
        variables: {
            ...query.query
        }
    });

    const { assetFolders = {} } = data;

    const handleDeleteClick = (id) => {
        history.push(`/folders/remove/${id}`);
    };

    const handleEditClick = (id) => {
        history.push(`/folders/edit/${id}`);
    };

    return (
        <Grid container>
            <Grid item className={classes.header} xs={12}>
                <Typography variant="h4">{t('folders.title')}</Typography>
                <Button
                    component={Link}
                    to={'/folders/add'}
                    variant="contained"
                    label="Toevoegen"
                    color="secondary"
                    className={classes.button}
                    iconLeft={() => <Add />}
                />
            </Grid>
            <Grid item xs={12}>
                <FilterOverview query={query} loading={loading} useResults={false} count={assetFolders.count || 0}>
                    {assetFolders.assetFolders && (
                        <Table
                            head={['ID', 'Naam', 'Rol(len)', 'Privémap', '']}
                            renderRows={() =>
                                assetFolders.assetFolders.map((folder) => (
                                    <FolderRow
                                        onDelete={() => handleDeleteClick(folder.id)}
                                        onClick={() => handleEditClick(folder.id)}
                                        key={folder.id}
                                        folder={folder}
                                    />
                                ))
                            }
                        />
                    )}
                </FilterOverview>
            </Grid>
            <LoaderModal loading={loading} />
        </Grid>
    );
};

export default Folders;
