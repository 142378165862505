import gql from 'graphql-tag';

export const GET_DURATIONS = gql`
    query durations($pagination: PaginationInput) {
        durations(pagination: $pagination) {
            count
            durations {
                id
                description
            }
        }
    }
`;

export const GET_DURATION = gql`
    query duration($id: Long!) {
        duration(id: $id) {
            description
            id
        }
    }
`;

export const CREATE_DURATION = gql`
    mutation createDuration($input: DurationInput!) {
        createDuration(input: $input) {
            id
        }
    }
`;

export const UPDATE_DURATION = gql`
    mutation updateDuration($input: DurationInput!) {
        updateDuration(input: $input) {
            id
        }
    }
`;

export const DELETE_DURATION = gql`
    mutation deleteDuration($input: DurationInput!) {
        deleteDuration(input: $input)
    }
`;
