import gql from 'graphql-tag';

export const GET_HOURS = gql`
    query getWorkingHours {
        workingHoursList(filter: {}) {
            count
            workingHoursList {
                upperLimit
                id
                value
                lowerLimit
            }
        }
    }
`;

export const GET_HOUR = gql`
    query getWorkingHour($id: Long!) {
        workingHours(id: $id) {
            upperLimit
            id
            value
            lowerLimit
        }
    }
`;

export const CREATE_WORKINGHOURS = gql`
    mutation createWorkingHours($input: WorkingHoursInput!) {
        createWorkingHours(input: $input) {
            id
        }
    }
`;

export const UPDATE_WORKINGHOURS = gql`
    mutation updateWorkingHours($input: WorkingHoursInput!) {
        updateWorkingHours(input: $input) {
            id
        }
    }
`;

export const DELETE_WORKINGHOURS = gql`
    mutation deleteWorkingHours($input: WorkingHoursInput!) {
        deleteWorkingHours(input: $input)
    }
`;
