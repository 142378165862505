import React, { useEffect, useState } from 'react';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import { useHistory, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Add, ImportExport } from '@material-ui/icons';
import { FilterOverview, LoaderModal } from 'shared';
import { Table, Button, InputField } from 'shared';
import { useFilter } from 'hooks';
import classNames from 'classnames';
import FuseDialog from './FuseDialog';

import { GET_CORPORATIONS, GET_CORPORATIONS_FOR_FUSE } from './queries';
import CorporationRow from './CorporationRow';

const useStyles = makeStyles((theme) => ({
    root: {},
    fuseButton: {
        marginLeft: '10px'
    },
    number: {
        fontWeight: 300,
        color: theme.palette.text.secondaryLight,
        fontSize: '24px',
        marginLeft: `${theme.spacing(1)}px`
    },
    item: {
        marginTop: `${theme.spacing(8)}px`
    },
    title: {
        display: 'flex',
        alignItems: 'center'
    },
    icon: {
        marginRight: `${theme.spacing(2)}px`,
        color: theme.palette.primary.main,
        padding: '2px'
    },
    searchBar: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        padding: theme.spacing(0, 4),
        marginBottom: theme.spacing(2),
        zIndex: 10
    },
    searchButton: {
        width: '220px',
        padding: theme.spacing(1)
    },
    search: {
        width: '100%',
        marginTop: theme.spacing(1),
        paddingRight: theme.spacing(3),
        marginBottom: 0
    },
    button: {
        zIndex: '10'
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: theme.spacing(0, 4)
    }
}));

const Corporations = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const history = useHistory();
    const [search, setSearch] = useState('');
    const [open, setOpen] = useState(false);
    const query = useFilter();

    const [getCorporations, { loading, data: corporationData = {} }] = useLazyQuery(GET_CORPORATIONS);
    const { data: corporationsForFuse, loading: fuseLoading } = useQuery(GET_CORPORATIONS_FOR_FUSE);
    const { corporations = {} } = corporationData;

    useEffect(() => {
        getCorporations({
            variables: { ...query.query }
        });
    }, [getCorporations, query.query]);

    const handleRowClick = (id) => {
        history.push(`/corporations/edit/${id}`);
    };

    const handleDelete = (id) => {
        history.push(`/corporations/remove/${id}`);
    };

    const handleSearch = () => {
        query.setQuery({
            filter: {
                search: search || ''
            }
        });
    };

    const handleFuse = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Grid container className={classes.root}>
            <Grid item className={classes.header} xs={12}>
                <Typography variant="h4">{t('corporations.title')}</Typography>
                <div>
                    <Button
                        component={Link}
                        to={'/corporations/add'}
                        variant="contained"
                        label="Toevoegen"
                        color="secondary"
                        className={classes.button}
                        iconLeft={() => <Add />}
                    />
                    <Button
                        variant="contained"
                        label="Fuseren"
                        color="secondary"
                        className={classNames(classes.button, classes.fuseButton)}
                        iconLeft={() => <ImportExport />}
                        onClick={handleFuse}
                    />
                </div>
            </Grid>

            <Grid item className={classes.searchBar} xs={12}>
                <InputField
                    name="Zoeken"
                    className={classes.search}
                    initialValue={query.filters.search || ''}
                    onChange={(value) => setSearch(value)}
                    type="text"
                    label="Zoeken naar corporatie"
                />
                <Button
                    className={classes.searchButton}
                    variant="contained"
                    color="primary"
                    label="Zoek"
                    onClick={handleSearch}
                />
            </Grid>

            <Grid item xs={12}>
                <FilterOverview query={query} loading={loading} count={corporations.count || 0}>
                    {corporations && corporations.corporations && (
                        <Table
                            head={[
                                t('corporations.table.name'),
                                t('corporations.table.domain'),
                                t('corporations.table.region'),
                                'Actief',
                                ''
                            ]}
                            renderRows={() =>
                                corporations.corporations.map((corporation) => (
                                    <CorporationRow
                                        onClick={handleRowClick}
                                        onDelete={handleDelete}
                                        key={corporation.id}
                                        corporation={corporation}
                                        regionId={corporation.region.id}
                                    />
                                ))
                            }
                        />
                    )}
                </FilterOverview>
            </Grid>
            <FuseDialog open={open} onClose={handleClose} corporations={corporationsForFuse} loading={fuseLoading} />
            <LoaderModal loading={loading} />
        </Grid>
    );
};

export default Corporations;
