import React, { useEffect, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { GET_USERS, GET_ROLES, USER_CSV } from 'api';
import { useFilter } from 'hooks';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import { Grid, Typography } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import { Table, Button, InputField, SelectField, DatePicker } from 'shared';
import { FilterOverview, LoaderModal } from 'shared';
import UserRow from './UserRow';
import { handleExport } from './HandleExport';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
    number: {
        fontWeight: 300,
        color: theme.palette.text.secondaryLight,
        fontSize: '24px',
        marginLeft: `${theme.spacing(1)}px`
    },
    item: {
        marginTop: `${theme.spacing(8)}px`
    },
    title: {
        display: 'flex',
        alignItems: 'center'
    },
    icon: {
        marginRight: `${theme.spacing(2)}px`,
        color: theme.palette.primary.main,
        padding: '2px'
    },
    searchBar: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        padding: theme.spacing(0, 4),
        marginBottom: theme.spacing(2),
        zIndex: 10
    },
    searchButton: {
        width: '220px',
        padding: theme.spacing(1)
    },
    exportButton: {
        marginBottom: theme.spacing(1)
    },
    search: {
        width: '100%',
        marginTop: theme.spacing(1),
        paddingRight: theme.spacing(3),
        marginBottom: 0
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: theme.spacing(0, 4)
    },
    exportHeader: {
        marginTop: theme.spacing(2)
    },
    button: {
        zIndex: '10'
    },
    center: {
        display: 'flex',
        justifyContent: 'center'
    }
}));

const Users = () => {
    const classes = useStyles();
    const history = useHistory();
    const [search, setSearch] = useState('');
    const [options, setOptions] = useState([]);
    const [exportDates, setExportDates] = useState({ from: null, to: null });

    const query = useFilter({
        pagination: { sortProperties: ['email'] },
        filter: { type: '' }
    });

    const [getUsers, { loading, data: userData = {} }] = useLazyQuery(GET_USERS);
    const { ...roleObj } = useQuery(GET_ROLES);

    const [exportCSV, exportData = {}] = useLazyQuery(USER_CSV);
    const { users = {} } = userData;

    useEffect(() => {
        getUsers({
            variables: { ...query.query }
        });
    }, [getUsers, query.query]);

    useEffect(() => {
        if (!roleObj.loading) {
            const { roles = {} } = roleObj.data;
            if (roles.roles && roles.roles.length) {
                setOptions(
                    roles.roles.map((i) => {
                        return {
                            label: i.title,
                            value: i.name
                        };
                    })
                );
            }
        }
    }, [roleObj.loading, roleObj.data]);

    useEffect(() => {
        if (!exportData.loading && exportData.data) {
            if (exportData.data.usersCsv && exportData.data.usersCsv.base64Content) {
                handleExport(exportData.data.usersCsv.base64Content);
            }
        }
    }, [exportData.loading, exportData]);

    const handleDeleteClick = (id) => {
        history.push(`/users/remove/${id}`);
    };

    const handleEditClick = (id) => {
        history.push(`/users/edit/${id}`);
    };

    const handleSearch = () => {
        query.setQuery({
            filter: {
                search: search || ''
            }
        });
    };

    const handleChange = (value) => {
        query.setQuery({ filter: { type: value || '' } });
    };

    const handleExportClick = () => {
        exportCSV({
            variables: {
                filter: {
                    ...query.query.filter,
                    fromDate: exportDates.from,
                    toDate: exportDates.to
                }
            }
        });
    };

    const handleExportDate = (key, value) => {
        setExportDates({
            ...exportDates,
            [key]: value
        });
    };

    if (exportData.loading) return <LoaderModal loading={true} />;
    return (
        <Grid container>
            <Grid item className={classes.header} xs={12}>
                <Typography variant="h4">Gebruikers</Typography>
                <Button
                    component={Link}
                    to={'/users/add'}
                    variant="contained"
                    label="Toevoegen"
                    color="secondary"
                    className={classes.button}
                    iconLeft={() => <Add />}
                />
            </Grid>

            <Grid item xs={12} className={classes.searchBar}>
                <SelectField
                    label="Filteren op rol"
                    placeholder="Rol"
                    helper={false}
                    options={options || []}
                    name="type"
                    onChange={handleChange}
                />
            </Grid>

            <Grid item className={classes.searchBar} xs={12}>
                <InputField
                    name="Zoeken"
                    className={classes.search}
                    initialValue={query.filters.search || ''}
                    onChange={(value) => setSearch(value)}
                    type="text"
                    label="Zoeken naar gebruiker"
                />
                <Button
                    className={classes.searchButton}
                    variant="contained"
                    color="primary"
                    label="Zoek"
                    onClick={handleSearch}
                />
            </Grid>
            <Grid item className={classNames(classes.header, classes.exportHeader)} xs={12}>
                <Typography variant="h4">Exporteren</Typography>
            </Grid>
            <Grid item className={classes.searchBar} xs={12}>
                <Grid item xs={4}>
                    <DatePicker
                        label="Startdatum"
                        placeholder=""
                        disablePast={false}
                        helper={false}
                        name="from"
                        onChange={(value) => handleExportDate('from', value)}
                        initialValue={exportDates.from}
                    />
                </Grid>
                <Grid item xs={4}>
                    <DatePicker
                        label="Einddatum"
                        placeholder=""
                        disablePast={false}
                        helper={false}
                        name="to"
                        onChange={(value) => handleExportDate('to', value)}
                        initialValue={exportDates.to}
                    />
                </Grid>
                <Grid item xs={3}>
                    <Button
                        className={classNames(classes.searchButton, classes.exportButton)}
                        variant="contained"
                        color="primary"
                        label="Exporteer csv"
                        onClick={handleExportClick}
                    />
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <FilterOverview query={query} loading={loading || roleObj.loading} count={users.count || 0}>
                    {users.users && (
                        <Table
                            head={['Naam', 'email', 'rol(len)', '']}
                            renderRows={() =>
                                users.users.map((user) => (
                                    <UserRow
                                        onDelete={handleDeleteClick}
                                        onClick={handleEditClick}
                                        key={user.id}
                                        user={user}
                                    />
                                ))
                            }
                        />
                    )}
                </FilterOverview>
            </Grid>
        </Grid>
    );
};

export default Users;
