import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Moment from 'moment';
import { useSnackbar } from 'notistack';
import { useQuery, useMutation } from '@apollo/react-hooks';
import {
    GET_USER,
    GET_PROVINCES,
    SAVE_USER,
    GET_ROLES,
    GET_CORPORATIONS,
    GET_REGIONS,
    GET_CITIES,
    SAVE_PROFILE_PICTURE
} from 'api';
import {
    InputField,
    Button,
    Loader,
    SelectField,
    CheckboxGroup,
    Switch,
    ImageUpload,
    RichInputField,
    AutoCompleteInput
} from 'shared';
import { Grid, Paper, Typography, Modal } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import { useForm } from 'hooks';

import RequestPasswordReset from './RequestPasswordReset';
import RequestSkillTestReset from './RequestSkillTestReset';
import SendSkillTestReinvite from './SendSkillTestReinvite';
import RequestMeetingsReset from './RequestMeetingsReset';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: `${theme.spacing(10)}px auto`,
        padding: theme.spacing(0, 4),
        maxWidth: '1200px'
    },
    paper: {
        width: '100%',
        padding: `${theme.spacing(7)}px ${theme.spacing(8)}px ${theme.spacing(8)}px`,
        borderRadius: '25px',
        display: 'flex',
        flexFlow: 'row wrap',
        justifyContent: 'space-between',

        '& .MuiGrid-grid-xs-6': {
            maxWidth: 'calc(50% - 50px)',
            flexBasis: 'calc(50% - 50px)',
            '& .MuiGrid-grid-xs-6': {
                maxWidth: 'calc(50% - 15px)',
                flexBasis: 'calc(50% - 15px)'
            }
        }
    },
    title: {
        textAlign: 'center',
        paddingTop: `${theme.spacing(8)}px`,
        paddingBottom: `${theme.spacing(2)}px`
    },
    label: {
        padding: `${theme.spacing(2.25)}px 0 ${theme.spacing(2)}px`
    },
    heading: {
        ...theme.typography.h6,
        fontWeight: 600,
        paddingBottom: `${theme.spacing(2)}px`,
        borderBottom: '1px solid ' + theme.palette.borderColor.main,
        marginBottom: `${theme.spacing(1)}px`
    },
    formControl: {
        width: '100%',
        margin: theme.spacing(3.125, 0, 1, 0),
        minWidth: '120px'
    },
    switch: {
        margin: theme.spacing(2, 0, 1)
    },
    input: {
        width: '100%',
        marginLeft: 0,
        '& .MuiInputLabel-shrink': {
            transform: 'none',
            top: '-20px',
            lineHeight: '28px',
            fontWeight: '500',
            fontSize: '18px'
        }
    },
    button: {
        margin: theme.spacing(3, 0, 1)
    },
    loaderPopup: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        background: theme.palette.common.white,
        padding: theme.spacing(4, 5),
        borderRadius: '20px',
        zIndex: theme.zIndex.modal
    },
    information: {
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        flexDirection: 'column'
    },
    imageUpload: {
        margin: theme.spacing(0, 0, 2.625, 0)
    },
    richTextBox: {
        margin: '0 0 -40px'
    },
    checkboxLabel: {
        position: 'relative',
        fontSize: '1.125rem',
        fontWeight: 500,
        transform: 'none',
        userSelect: 'none',
        paddingBottom: `${theme.spacing(0.5)}px`,
        margin: 0
    }
}));

const formSettings = {
    roles: {
        required: true
    }
};

const EditUser = () => {
    const classes = useStyles();
    const params = useParams();
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const [form, submitForm] = useForm(formSettings);
    const { t } = useTranslation();
    const { id } = params;

    const [saveProfilePicture] = useMutation(SAVE_PROFILE_PICTURE);
    const [saveUser, { loading: mutationLoading, data: dataSave }] = useMutation(SAVE_USER);

    const roleState = useSelector((state) => state.auth.roles);

    const { data: rolesData = {} } = useQuery(GET_ROLES);
    const { data: corporationsData = {} } = useQuery(GET_CORPORATIONS);
    const { data: regionsData = {} } = useQuery(GET_REGIONS);
    const { data: provincesData = {} } = useQuery(GET_PROVINCES);

    const roles = rolesData && rolesData.roles ? rolesData.roles.roles : [];
    const regions = regionsData && regionsData.regions ? regionsData.regions.regions : [];
    const provinces = provincesData && provincesData.provinces ? provincesData.provinces : [];

    const corporations =
        corporationsData && corporationsData.corporations
            ? corporationsData.corporations.corporations.sort((a, b) => {
                  var nameA = a.label.toUpperCase();
                  var nameB = b.label.toUpperCase();
                  if (nameA < nameB) {
                      return -1;
                  }
                  if (nameA > nameB) {
                      return 1;
                  }

                  return 0;
              })
            : [];

    Moment.locale('nl');

    const { loading, data = {} } = useQuery(GET_USER, {
        variables: {
            id: id
        }
    });

    const [isSaving, setIsSaving] = useState(false);
    const [draft, setDraft] = useState({
        id: '',
        email: '',
        preferredEmail: '',
        accountEnabled: false,
        profile: {
            firstName: '',
            lastName: '',
            middleName: '',
            profilePicture: [],
            phone: '',
            provinces: []
        },
        regions: { name: '' },
        roles: [
            {
                value: '',
                label: ''
            }
        ],
        corporation: {
            academyUrl: ''
        }
    });

    useEffect(() => {
        if (dataSave) {
            const id = dataSave.updateUser.user.id;
            if (id) {
                enqueueSnackbar({
                    variant: 'success',
                    message: t('userEdit.saved')
                });
                history.push(`/users`);
            } else {
                enqueueSnackbar({
                    variant: 'success',
                    message: t('userEdit.saveError')
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataSave]);

    const handleCancel = () => {
        history.goBack();
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        submitForm().then((resp) => {
            if (resp) {
                setIsSaving(true);

                // Check whether a new profile picture was uploaded
                let profilePicPromise;
                if (resp.profilePicture === null) {
                    // If explicitly set to null, remove the profile picture
                    profilePicPromise = new Promise((resolve, reject) => {
                        resolve({ id: -1 }); // -1 indicates removal
                    });
                } else if (resp.profilePicture) {
                    // If a new one was added, upload the picture
                    profilePicPromise = saveProfilePicture({
                        variables: {
                            file: resp.profilePicture
                        },
                        context: {
                            hasUpload: true
                        }
                    });
                } else {
                    // If not, return the current profile picture
                    profilePicPromise = new Promise((resolve, reject) => {
                        resolve(data.user.profile.profilePicture);
                    });
                }

                profilePicPromise.then((profilePictureAsset) => {
                    saveUser({
                        variables: {
                            input: {
                                id: id,
                                email: resp.email,
                                preferredEmail: resp.preferredEmail,
                                accountEnabled: resp.accountEnabled,
                                roles: resp.roles,
                                corporation: resp.corporation ? resp.corporation : -1,
                                profile: {
                                    biography: resp.biography,
                                    firstName: resp.firstName,
                                    lastName: resp.lastName,
                                    middleName: resp.middleName,
                                    phone: resp.phone,
                                    regionList: resp.regionList ? resp.regionList : -1,
                                    provinces: resp.provinces ? resp.provinces : -1,
                                    city: resp.city ? resp.city.id || -1 : -1,

                                    ...(resp.profilePicture && {
                                        // A new profile picture has been uploaded
                                        profilePicture: profilePictureAsset.data
                                            ? profilePictureAsset.data.uploadProfilePicture.asset
                                            : -1
                                    }),
                                    ...(!resp.profilePicture &&
                                        profilePictureAsset && {
                                            profilePicture: {
                                                id: profilePictureAsset.id
                                            }
                                        })
                                }
                            }
                        }
                    })
                        .then((result) => {
                            setIsSaving(false);
                            if (result.errors) {
                                enqueueSnackbar({
                                    variant: 'warning',
                                    message: t('userEdit.saveError')
                                });
                            }
                        })
                        .catch(() => {
                            setIsSaving(false);
                            enqueueSnackbar({
                                variant: 'warning',
                                message: t('userEdit.saveError')
                            });
                        });
                });
            }
        });
    };

    useEffect(() => {
        if (!loading && data && data.user) {
            setDraft(data.user);
        }
    }, [loading, data]);

    // check if string is JSON (RichTextField)
    const isJSON = (data) => {
        try {
            JSON.parse(data);
        } catch (e) {
            return false;
        }
        return true;
    };

    if (loading || isSaving || !draft.id) {
        return (
            <Modal
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={loading || mutationLoading}
            >
                <div className={classes.loaderPopup}>
                    <Loader />
                    <Typography>Loading...</Typography>
                </div>
            </Modal>
        );
    }

    return (
        <Grid container justify="center" className={classes.root}>
            <Paper className={classes.paper} elevation={0}>
                <Grid item xs={6}>
                    <Typography className={classes.heading}>{t('userEdit.accountSettings')}</Typography>
                    <Switch
                        name="accountEnabled"
                        className={classes.switch}
                        label={t('userEdit.accountEnabledSwitch')}
                        checked={draft.accountEnabled}
                        form={form}
                    />
                    <Typography variant="subtitle1" className={classes.label}>
                        Profielfoto
                    </Typography>
                    <ImageUpload
                        name="profilePicture"
                        form={form}
                        initialValue={data.user.profile.profilePicture ? data.user.profile.profilePicture : {}}
                    />
                    {isJSON(data.user.profile.biography) ? (
                        <RichInputField
                            classes={{ box: classes.richTextBox }}
                            initialValue={data.user.profile.biography || ''}
                            name="biography"
                            title="Biografie"
                            form={form}
                        />
                    ) : (
                        <InputField
                            name="biography"
                            className={classes.input}
                            initialValue={data.user.profile.biography || ''}
                            label="Biografie"
                            error={false}
                            form={form}
                            rows={4}
                        />
                    )}
                    <InputField
                        name="email"
                        className={classes.input}
                        initialValue={draft.email || ''}
                        type="text"
                        label={t('userAdd.email')}
                        form={form}
                    />
                    <InputField
                        name="firstName"
                        className={classes.input}
                        initialValue={draft.profile.firstName || ''}
                        type="text"
                        label={t('userEdit.firstName')}
                        error={false}
                        form={form}
                        errorMessage={t('loginForm.errorEmail')}
                    />
                    <InputField
                        name="lastName"
                        className={classes.input}
                        initialValue={draft.profile.lastName || ''}
                        type="text"
                        label={t('userEdit.lastName')}
                        error={false}
                        form={form}
                        errorMessage={t('loginForm.errorEmail')}
                    />
                    <InputField
                        name="middleName"
                        className={classes.input}
                        initialValue={draft.profile.middleName || ''}
                        type="text"
                        label={t('userEdit.middleName')}
                        error={false}
                        form={form}
                    />
                    <InputField
                        name="preferredEmail"
                        className={classes.input}
                        initialValue={draft.preferredEmail || ''}
                        type="text"
                        label={t('userEdit.extraEmail')}
                        error={false}
                        form={form}
                    />
                    <InputField
                        name="phone"
                        className={classes.input}
                        initialValue={draft.profile.phone || ''}
                        type="text"
                        label={t('userEdit.phone')}
                        error={false}
                        form={form}
                    />
                    <CheckboxGroup
                        label={t('userEdit.roles')}
                        classes={{ formControl: classes.formControl }}
                        labelClass={classes.checkboxLabel}
                        name="roles"
                        options={
                            roles &&
                            roles.map((item) => {
                                return {
                                    value: item.name,
                                    label: item.title
                                };
                            })
                        }
                        initialValue={
                            data.user.roles
                                ? data.user.roles.map((role) => {
                                      return { id: role.name };
                                  })
                                : []
                        }
                        helper={false}
                        form={form}
                    />
                    <SelectField
                        label={t('userEdit.usedCorporation')}
                        helper={false}
                        options={corporations || []}
                        placeholder={t('userEdit.usedCorporation')}
                        initialValue={data.user && data.user.corporation ? data.user.corporation.id : null}
                        name="corporation"
                        form={form}
                    />

                    <CheckboxGroup
                        label={
                            data.user.profile.regionList && data.user.profile.regionList.length > 1
                                ? t('userEdit.usedRegions')
                                : t('userEdit.usedRegion')
                        }
                        name="regionList"
                        classes={{ formControl: classes.formControl }}
                        labelClass={classes.checkboxLabel}
                        options={
                            regions &&
                            regions.map((item) => {
                                return {
                                    value: item.value,
                                    label: item.label
                                };
                            })
                        }
                        initialValue={
                            data.user.profile.regionList
                                ? data.user.profile.regionList.map((region) => {
                                      return { id: region.id };
                                  })
                                : []
                        }
                        helper={false}
                        form={form}
                    />
                    <CheckboxGroup
                        label={t('userEdit.provinces')}
                        name="provinces"
                        classes={{ formControl: classes.formControl }}
                        labelClass={classes.checkboxLabel}
                        options={
                            provinces &&
                            provinces.map((item) => {
                                return {
                                    value: item,
                                    label: item
                                };
                            })
                        }
                        initialValue={
                            data.user.profile.provinces
                                ? data.user.profile.provinces.map((province) => {
                                      return { id: province };
                                  })
                                : []
                        }
                        helper={false}
                        form={form}
                    />
                    <AutoCompleteInput
                        label={t('userEdit.city')}
                        placeholder={t('userEdit.chooseCity')}
                        noOptionsText={t('userEdit.searchCity')}
                        className={classes.input}
                        initialValue={data.user.profile.city || {}}
                        name="city"
                        form={form}
                        data={{
                            query: GET_CITIES,
                            response: 'cities'
                        }}
                    />
                    <Grid container justify="space-between">
                        <Button
                            variant="contained"
                            className={classes.button}
                            color="secondary"
                            label={t('userEdit.cancel')}
                            onClick={handleCancel}
                        />
                        <Button
                            variant="contained"
                            className={classes.button}
                            color="primary"
                            label={t('userEdit.save')}
                            onClick={handleSubmit}
                        />
                    </Grid>
                </Grid>

                {data.user && (
                    <Grid item xs={6} className={classes.information}>
                        <Typography className={classes.heading}>{t('userEdit.accountInfo')}</Typography>
                        {data.user.accountEnabled ? (
                            <Typography variant="body1">{t('userEdit.activated')}</Typography>
                        ) : (
                            <Typography variant="body1">{t('userEdit.notActivated')}</Typography>
                        )}
                        {data.user.email && (
                            <Typography variant="body1">
                                <b>{t('userEdit.email')}:</b> {data.user.email}
                            </Typography>
                        )}
                        {data.user.createdOn && (
                            <Typography variant="body1">
                                <b>{t('userEdit.madeOn')}:</b> {Moment(data.user.createdOn).format('D-MM-YYYY')}
                            </Typography>
                        )}
                        {data.user.lastLogin && (
                            <Typography variant="body1">
                                <b>{t('userEdit.lastLogin')}:</b> {Moment(data.user.lastLogin).format('D-MM-YYYY')}
                            </Typography>
                        )}
                        {data.user.preferredEmail && (
                            <Typography variant="body1">
                                <b>{t('userEdit.extraEmail')}:</b> {data.user.preferredEmail}
                            </Typography>
                        )}

                        {data.user.corporation && (
                            <Typography variant="body1">
                                <b>{t('userEdit.usedCorporation')}:</b> {data.user.corporation.name}
                            </Typography>
                        )}

                        {data.user.profile && data.user.profile.regionList && (
                            <Typography variant="body1">
                                <b>
                                    {data.user.profile.regionList && data.user.profile.regionList.length > 1
                                        ? t('userEdit.usedRegions')
                                        : t('userEdit.usedRegion')}
                                    :
                                </b>{' '}
                                {data.user.profile.regionList.map((region) => region.description).join(', ')}
                            </Typography>
                        )}

                        {data.user.origin && (
                            <Typography variant="body1">
                                <b>{t('userEdit.userOrigin')}:</b> {data.user.origin}
                            </Typography>
                        )}

                        {roleState.includes('SUPER_ADMIN') && data.user.id && (
                            <div>
                                <br />
                                <Typography className={classes.heading}>Wachtwoord herstellen</Typography>
                                <RequestPasswordReset userId={data.user.id} />

                                <br />
                                <Typography className={classes.heading}>Loopbaanwijzer-test</Typography>
                                <RequestSkillTestReset user={data.user} />
                                <SendSkillTestReinvite user={data.user} />

                                <br />
                                <Typography className={classes.heading}>Loopbaangesprekken</Typography>
                                <RequestMeetingsReset user={data.user} />
                            </div>
                        )}
                    </Grid>
                )}
            </Paper>
        </Grid>
    );
};

export default EditUser;
