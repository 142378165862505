import React, { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Drawer, IconButton, Divider, MenuList, MenuItem, ListItemIcon, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { useSelector } from 'react-redux';

import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import QueryBuilderOutlinedIcon from '@material-ui/icons/QueryBuilderOutlined';
import HomeWorkOutlinedIcon from '@material-ui/icons/HomeWorkOutlined';
import SyncOutlinedIcon from '@material-ui/icons/SyncOutlined';
import GroupOutlinedIcon from '@material-ui/icons/GroupOutlined';
import FolderOutlinedIcon from '@material-ui/icons/FolderOutlined';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import SchoolOutlinedIcon from '@material-ui/icons/SchoolOutlined';
import TimelapseOutlinedIcon from '@material-ui/icons/TimelapseOutlined';
import WorkOutlineOutlinedIcon from '@material-ui/icons/WorkOutlineOutlined';
import ConfirmationNumberOutlinedIcon from '@material-ui/icons/ConfirmationNumberOutlined';
import BuildOutlinedIcon from '@material-ui/icons/BuildOutlined';
import SquareFootOutlinedIcon from '@material-ui/icons/SquareFootOutlined';
import ReceiptOutlinedIcon from '@material-ui/icons/ReceiptOutlined';
import AssignmentIcon from '@material-ui/icons/Assignment';

const drawerWidth = 340;

const useStyles = makeStyles((theme) => ({
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        height: 80,
        ...theme.mixins.toolbar
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9)
        }
    },
    menu: {
        padding: 0
    },
    selected: {
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.05)'
        },
        '&.Mui-selected': {
            backgroundColor: 'rgba(0, 0, 0, 0.05)',
            borderLeft: `5px solid ${theme.palette.secondary.main}`
        },
        '& .MuiListItemIcon-root': {
            color: theme.palette.secondary.main
        },
        '& .MuiListItemText-root': {
            color: theme.palette.text.primary
        }
    }
}));

const MainMenu = (props) => {
    const { open, closeDrawer } = props;
    const classes = useStyles();
    const location = useLocation();
    const isSuperAdmin = useSelector((state) => state.auth.roles.includes('SUPER_ADMIN'));
    const isAdmin = useSelector((state) => state.auth.roles.includes('ADMIN'));
    const admin = isSuperAdmin || isAdmin || false;

    const { t } = useTranslation();

    const appLinks = useMemo(() => {
        const links = [
            {
                path: '/vouchers',
                linkText: t('menu.vouchers'),
                adminLink: true,
                icon: <ConfirmationNumberOutlinedIcon />
            },
            {
                path: '/crawler',
                linkText: t('menu.crawler'),
                adminLink: true,
                icon: <SyncOutlinedIcon />
            },
            {
                path: '/users',
                linkText: t('menu.users'),
                adminLink: true,
                icon: <GroupOutlinedIcon />
            },
            {
                path: '/meetings',
                linkText: t('menu.meetings'),
                adminLink: true,
                icon: <AssignmentIcon />
            },
            {
                path: '/corporations',
                linkText: t('menu.corporations'),
                adminLink: true,
                icon: <HomeWorkOutlinedIcon />
            },
            {
                path: '/expertises',
                linkText: t('menu.expertises'),
                adminLink: true,
                icon: <SquareFootOutlinedIcon />
            },
            {
                path: '/regions',
                linkText: t('menu.regions'),
                adminLink: true,
                icon: <LocationOnOutlinedIcon />
            },
            {
                path: '/skills',
                linkText: t('menu.skills'),
                adminLink: true,
                icon: <BuildOutlinedIcon />
            },
            {
                path: '/hours',
                linkText: t('menu.hours'),
                adminLink: true,
                icon: <QueryBuilderOutlinedIcon />
            },
            {
                path: '/folders',
                linkText: t('menu.folders'),
                adminLink: true,
                icon: <FolderOutlinedIcon />
            },
            {
                path: '/salary-scales',
                linkText: t('menu.salaryScale'),
                adminLink: true,
                icon: <WorkOutlineOutlinedIcon />
            },
            {
                path: '/durations',
                linkText: t('menu.duration'),
                adminLink: true,
                icon: <TimelapseOutlinedIcon />
            },
            {
                path: '/education-grades',
                linkText: t('menu.educationGrades'),
                adminLink: true,
                icon: <SchoolOutlinedIcon />
            },
            {
                path: '/vacancy-types',
                linkText: t('menu.vacancyType'),
                adminLink: true,
                icon: <ReceiptOutlinedIcon />
            }
        ].sort((a, b) => (a.linkText > b.linkText ? 1 : b.linkText > a.linkText ? -1 : 0));

        return links;
    }, [t]);

    return (
        <Drawer
            variant="permanent"
            classes={{
                paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose)
            }}
            open={open}
        >
            <div className={classes.toolbarIcon}>
                <IconButton onClick={closeDrawer}>
                    <ChevronLeftIcon />
                </IconButton>
            </div>
            <Divider />
            <MenuList className={classes.menu}>
                <MenuItem
                    button
                    classes={{ selected: classes.selected }}
                    component={Link}
                    to={'/'}
                    selected={location.pathname === '/'}
                >
                    <ListItemIcon>
                        <HomeOutlinedIcon fontSize="small" />
                    </ListItemIcon>
                    <Typography variant="inherit">{t('menu.dashboard')}</Typography>
                </MenuItem>
                {appLinks.map((item, index) => {
                    if (!admin && item.adminLink) return null;
                    return (
                        <MenuItem
                            key={index}
                            button
                            classes={{ selected: classes.selected }}
                            component={Link}
                            to={item.path}
                            selected={location.pathname === item.path}
                        >
                            <ListItemIcon>{item.icon}</ListItemIcon>
                            <Typography variant="inherit">{item.linkText}</Typography>
                        </MenuItem>
                    );
                })}
            </MenuList>
        </Drawer>
    );
};

export default MainMenu;
