import { SET_EXPERTISES, SET_SKILLS } from './dataActions';

export default (
    state = {
        expertises: [],
        skills: []
    },
    action
) => {
    switch (action.type) {
        case SET_EXPERTISES:
            return {
                ...state,
                expertises: action.payload
            };
        case SET_SKILLS:
            return {
                ...state,
                skills: action.payload
            };
        default:
            return state;
    }
};
