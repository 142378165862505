import React from 'react';
import { withStyles } from '@material-ui/styles';
import { TableCell, TableRow, makeStyles, Typography } from '@material-ui/core';
import classNames from 'classnames';
import { DeleteOutline } from '@material-ui/icons';

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.background
    },
    root: {
        padding: `${theme.spacing(1.5)}px ${theme.spacing(2)}px`,
        lineHeight: 0,
        '&:first-child': {
            paddingLeft: 0
        },
        '&:last-child': {
            paddingRight: 0
        }
    }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        position: 'relative',
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default
        },
        '&:last-child': {
            '& > td': {
                border: 0
            }
        }
    }
}))(TableRow);

const useStyles = makeStyles((theme) => ({
    center: {
        display: 'inline-flex',
        alignItems: 'center',
        color: theme.palette.text.secondaryLight
    },
    title: {
        color: theme.palette.text.secondary
    },
    time: {
        marginRight: theme.spacing(1)
    },

    actionButton: {
        border: '1px solid ' + theme.palette.text.secondaryLight,
        borderRadius: '50%',
        color: theme.palette.text.secondary,
        background: theme.palette.common.white,
        width: '50px',
        height: '50px',
        padding: '10px',
        marginLeft: '20px',
        cursor: 'pointer',
        transition: 'all 300ms ease',
        '&:hover': {
            border: '1px solid ' + theme.palette.text.secondary,
            background: theme.palette.text.secondary,
            color: theme.palette.common.white
        }
    }
}));

const MeetingRow = (props) => {
    const classes = useStyles();
    const { meeting, onClick, onDelete } = props;
    if (!meeting.owner) {
        console.warn('No owner found for meeting, so skipping row (for now).', meeting);
        return null;
    }

    const { id, finishedOn, code, owner, statusTitle, batch } = meeting;
    const { email, profile } = owner || {};
    const { fullName } = profile;

    const localeDateString = (date) => {
        const dateConstructor = new Date(date);
        return dateConstructor.toLocaleDateString();
    };

    return (
        <StyledTableRow className={classNames(classes.row, { [classes.link]: onClick })}>
            <StyledTableCell className={classes.nameCell} align="left">
                <Typography className={classes.center}>{batch}</Typography>
            </StyledTableCell>
            <StyledTableCell className={classes.nameCell} align="left">
                <Typography className={classes.center}>{code}</Typography>
            </StyledTableCell>
            <StyledTableCell className={classes.nameCell} align="left">
                <Typography className={classes.center}>{fullName}</Typography>
            </StyledTableCell>
            <StyledTableCell align="left">
                <Typography className={classes.title}>{email}</Typography>
            </StyledTableCell>
            <StyledTableCell align="left">
                <Typography className={classes.title}>
                    {finishedOn ? localeDateString(finishedOn) : 'Niet opgegeven'}
                </Typography>
            </StyledTableCell>
            <StyledTableCell>{statusTitle}</StyledTableCell>
            <StyledTableCell align="right">
                <div className={classes.center}>
                    <DeleteOutline onClick={() => onDelete(id, owner)} className={classes.actionButton} />
                </div>
            </StyledTableCell>
        </StyledTableRow>
    );
};

export default MeetingRow;
