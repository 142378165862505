import { FormControl, Select as MSelect, MenuItem, InputLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import FormHelperText from '@material-ui/core/FormHelperText';
import React, { useEffect, useState } from 'react';

const useStyles = makeStyles((theme) => ({
    root: {
        border: 'none',
        height: '100%',
        borderRadius: '35px',
        '& .MuiInputBase-root': {
            border: 'none'
        },
        '& .Mui-focused': {
            border: 'none',
            '&:before, &:after': {
                display: 'none'
            }
        },
        '& .MuiInput-underline:before, & .MuiInput-underline:after': {
            border: 'none',
            display: 'none'
        }
    },
    formControl: {
        minWidth: '120px',
        width: '100%',
        margin: '25px 0 8px 0',
        '& .MuiInput-underline:before, & .MuiInput-underline:after': {
            border: 'none',
            display: 'none'
        },
        '& .MuiInputBase-input': {
            padding: theme.spacing(1.5, 2.75),
            background: 'transparent'
        }
    },
    selectEmpty: {
        border: '1px solid ' + theme.palette.primary.light,
        borderRadius: '3px',
        margin: 0,
        marginTop: '8px'
    },
    icon: {
        right: '10px'
    },
    placeholder: {
        opacity: 1,
        textOverflow: 'ellipsis',
        color: theme.palette.text.disabled
    },
    optionalLabel: {
        fontSize: '1rem',
        fontWeight: 300,
        color: theme.palette.text.secondaryLight,
        marginLeft: theme.spacing(1)
    },
    label: {
        position: 'relative',
        fontSize: '1.125rem',
        fontWeight: 500,
        transform: 'none',
        userSelect: 'none',
        paddingBottom: `${theme.spacing(0.5)}px`
    }
}));

const SelectField = (props) => {
    const {
        className,
        errorMessage: errorMessageFromProps,
        error: errorFromProps,
        onChange,
        placeholder,
        initialValue = '',
        options = [],
        label,
        rootClass,
        labelClass,
        name,
        optional,
        form = false,
        otherProps,
        hint
    } = props;
    const classes = useStyles(props);

    const [error, setError] = useState(errorFromProps);
    const [errorMessage, setErrorMessage] = useState(errorMessageFromProps);
    const [option, setOption] = useState('');

    useEffect(() => {
        if (form) form.onFieldChange({ key: name, value: option });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (initialValue) {
            setOption(initialValue);
            if (form) form.onFieldChange({ key: name, value: initialValue });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialValue]);

    useEffect(() => {
        if (form.errors && form.errors[name] && form.errors[name].length) {
            setError(true);
            setErrorMessage(form.errors[name].join(','));
        } else {
            setError(false);
            setErrorMessage('');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form.errors]);

    const handleChange = (event) => {
        if (form) form.onFieldChange({ key: name, value: event.target.value });
        setOption(event.target.value.toString());
        if (onChange) onChange(event.target.value);
    };

    return (
        <FormControl classes={{ root: classNames(classes.formControl, rootClass) }} error={error}>
            {label && (
                <InputLabel className={classNames(classes.label, labelClass)}>
                    {label}
                    {optional && <span className={classes.optionalLabel}>(optioneel)</span>}
                </InputLabel>
            )}
            <MSelect
                value={option}
                {...otherProps}
                onChange={handleChange}
                displayEmpty
                classes={{
                    icon: classes.icon
                }}
                className={classNames(classes.selectEmpty, className)}
            >
                {placeholder && (
                    <MenuItem value="">
                        <span className={classes.placeholder}>{placeholder}</span>
                    </MenuItem>
                )}
                {options &&
                    options.map((option, idx) => (
                        <MenuItem key={idx} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
            </MSelect>
            {error && <FormHelperText>{error && errorMessage}</FormHelperText>}
            {hint && <FormHelperText>{hint}</FormHelperText>}
        </FormControl>
    );
};

export default SelectField;
