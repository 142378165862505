import gql from 'graphql-tag';

export const CREATE_USER = gql`
    mutation createUser($input: CreateUserInput!) {
        createUser(input: $input) {
            user {
                id
            }
        }
    }
`;

export const DELETE_USER = gql`
    mutation deleteUser($input: DeleteUserInput!) {
        deleteUser(input: $input) {
            result
        }
    }
`;

export const GET_ROLES = gql`
    query getRoles {
        roles {
            roles {
                id
                name
                title
            }
        }
    }
`;
