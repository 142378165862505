import gql from 'graphql-tag';

export const GET_CORPORATION = gql`
    query getCorporation($id: Long!) {
        corporation(id: $id) {
            id
            name
            domain
            academyUrl
            hasAcademyUrl
            provinces
            logo {
                url
                thumbnailUrl
                id
            }
            region {
                id
            }
            active
        }
    }
`;

export const GET_CORPORATIONS_FOR_FUSE = gql`
    query corporationsForFuse {
        corporations {
            count
            corporations {
                id
                domain
                name
                academyUrl
                hasAcademyUrl
                region {
                    id
                }
                logo {
                    thumbnailUrl
                }
            }
        }
    }
`;

export const GET_CORPORATIONS = gql`
    query getCorporations($pagination: PaginationInput!, $filter: CorporationFilterInput) {
        corporations(pagination: $pagination, filter: $filter) {
            count
            corporations {
                id
                active
                domain
                name
                academyUrl
                hasAcademyUrl
                region {
                    id
                }
                logo {
                    thumbnailUrl
                }
            }
        }
    }
`;

export const MERGE_CORPORATION = gql`
    mutation mergeCorporation($input: CreateCorporationMergeInput!) {
        createCorporationMerge(input: $input) {
            corporationMerge {
                id
            }
        }
    }
`;

export const CREATE_CORPORATION = gql`
    mutation createCorporation($input: CreateCorporationInput!) {
        createCorporation(input: $input) {
            corporation {
                domain
                id
            }
        }
    }
`;

export const UPDATE_CORPORATION = gql`
    mutation updateCorporation($input: UpdateCorporationInput!) {
        updateCorporation(input: $input) {
            corporation {
                domain
                id
                active
                academyUrl
            }
        }
    }
`;

export const DELETE_CORPORATION = gql`
    mutation deleteCorporation($input: DeleteCorporationInput!) {
        deleteCorporation(input: $input)
    }
`;

export const GET_REGION = gql`
    query getRegion($id: Long!) {
        region(id: $id) {
            description
        }
    }
`;

export const GET_REGIONS = gql`
    query getRegions {
        regions(pagination: { page: 0, pageSize: 30 }) {
            count
            regions {
                value: id
                label: description
            }
        }
    }
`;

export const UPLOAD_FILE = gql`
    mutation UploadFile($file: Upload!) {
        uploadFile(file: $file) {
            asset {
                id
                contentType
                url
            }
        }
    }
`;
