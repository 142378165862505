import { Grid } from '@material-ui/core';
import { Loader } from 'shared';
import { LOGIN } from 'api';
import { LoginForm } from 'shared';
import { loginUser, setToken, setUserData, setUserRoles } from 'store/actions';
import { makeStyles } from '@material-ui/styles';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import Cookie from 'js-cookie';
import jwtDecode from 'jwt-decode';
import React, { useState } from 'react';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(12, 0)
    },
    message: {
        border: '1px solid ' + theme.palette.error.main,
        borderRadius: '30px',
        padding: '10px 20px',
        color: theme.palette.error.main,
        maxWidth: '450px',
        width: '100%',
        margin: '0 auto'
    }
}));

const Login = (props) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const [login, { loading: mutationLoading, error: mutationError }] = useMutation(LOGIN);
    const [loginError, setLoginError] = useState();

    const handleLogin = (username, password) => {
        const { state } = location;
        login({
            variables: {
                email: username,
                password: password
            }
        })
            .then((resp) => {
                if (resp.data) {
                    const token = Cookie.get('jwt_flow');
                    const decodeToken = jwtDecode(token);
                    dispatch(loginUser(true));
                    dispatch(setToken(token));
                    dispatch(setUserData(decodeToken.id));
                    dispatch(setUserRoles(decodeToken.roles));
                    setLoginError();

                    if (state) {
                        history.push(state.referrer);
                    } else {
                        history.push('/');
                    }
                }
            })
            .catch((err) => {
                const errData = JSON.stringify(err);
                const parsedErrors = JSON.parse(errData);
                const statusCode = parsedErrors.networkError && parsedErrors.networkError.statusCode;
                setLoginError(statusCode);
            });
    };

    const getLoginError = () => {
        switch (loginError) {
            case 401:
                return t('loginForm.notAuthorized');
            case 502:
                return t('loginForm.serverError');
            default:
                t('loginForm.errorMessage');
        }
    };

    return (
        <Grid container justify="center" className={classes.root}>
            <Grid item xs={4}>
                <LoginForm onChange={handleLogin} links={false} />
                {mutationLoading && <Loader />}
                {mutationError && (
                    <div className={classes.message}>
                        <span>{loginError ? getLoginError() : t('loginForm.errorMessage')}</span>
                    </div>
                )}
            </Grid>
        </Grid>
    );
};

export default Login;
