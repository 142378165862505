import React, { useEffect, useState } from 'react';
import { Table } from 'shared';
import { Grid, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import { FilterOverview, LoaderModal } from 'shared';
import { useFilter } from 'hooks';
import * as firebase from 'firebase/app';
import 'firebase/database';
import CrawlerSiteRow from './CrawlerSiteRow';
import { getFirebaseDataSnapshot } from '../FirebaseInstance';
import { isUndefinedOrNull } from '../../../helpers';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
    root: {},
    number: {
        fontWeight: 300,
        color: theme.palette.text.secondaryLight,
        fontSize: '24px',
        marginLeft: `${theme.spacing(1)}px`
    },
    item: {
        marginTop: `${theme.spacing(8)}px`
    },
    title: {
        display: 'flex',
        alignItems: 'center'
    },
    icon: {
        marginRight: `${theme.spacing(2)}px`,
        color: theme.palette.primary.main,
        padding: '2px'
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: theme.spacing(0, 4),
        marginBottom: theme.spacing(2)
    }
}));

const CrawlerSites = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const history = useHistory();
    const query = useFilter();
    const { enqueueSnackbar } = useSnackbar();

    const [sites, setSites] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getFirebaseDataSnapshot(enqueueSnackbar, t, firebase, 'sites').then(
            (snapshot) => {
                const result = snapshot.val();
                setLoading(false);

                if (result !== null) {
                    // The data arrives in the following format: {vacancy1:{}, vacancy2:{}}, which
                    // will be mapped to a plain old array to be used for the table
                    const data = Object.keys(result)
                        .map((siteKey) => ({
                            id: siteKey,
                            ...result[siteKey]
                        }))
                        .flat()
                        .filter((site) => isUndefinedOrNull(site.hidden) || site.hidden === false)
                        .sort((a, b) => (a.crawledAt > b.crawledAt ? -1 : 1));
                    setSites(data);
                    firebase.app().delete();
                }
            },
            () => {
                enqueueSnackbar({
                    message: t('crawler.failedMessages.sites'),
                    variant: 'error'
                });
            }
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleRowClick = (id) => {
        history.push(`/crawler/site/${id}`);
    };

    const page = parseInt(query.pagination.page);
    const pageSize = parseInt(query.pagination.pageSize);

    return (
        <Grid container className={classes.root}>
            <Grid item className={classes.header} xs={12}>
                <Typography variant="h2">{t('crawler.sites.title')}</Typography>
            </Grid>

            <Grid item xs={12}>
                <FilterOverview query={query} loading={loading} useResults={false} count={sites.length || 0}>
                    {sites && (
                        <Table
                            head={[
                                t('crawler.sites.table.name'),
                                t('crawler.sites.table.active'),
                                t('crawler.sites.table.searchTerms'),
                                t('crawler.sites.table.positiveKeywords'),
                                t('crawler.sites.table.negativeKeywords'),
                                ''
                            ]}
                            renderRows={() =>
                                sites
                                    .slice(page * pageSize, page * pageSize + pageSize)
                                    .map((configItem, i) => (
                                        <CrawlerSiteRow key={i} site={configItem} onClick={handleRowClick} />
                                    ))
                            }
                        />
                    )}
                </FilterOverview>
            </Grid>
            <LoaderModal loading={loading} />
        </Grid>
    );
};

export default CrawlerSites;
