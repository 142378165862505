import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { Grid, InputLabel, Modal, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { Button, CheckboxGroup, FileUpload, InputField, Loader, LoaderModal, SelectField, Switch } from 'shared';
import { useForm } from 'hooks';
import { GET_CORPORATION, GET_REGIONS, UPDATE_CORPORATION, UPLOAD_FILE } from './queries';
import { GET_PROVINCES } from '../../api';

const useStyles = makeStyles((theme) => ({
    grid: {
        maxWidth: '1165px',
        margin: '0 auto'
    },
    paper: {
        width: '100%',
        padding: theme.spacing(7, 8, 8, 7),
        borderRadius: '25px',

        '& .MuiGrid-grid-xs-6': {
            maxWidth: 'calc(50% - 50px)',
            flexBasis: 'calc(50% - 50px)',
            '& .MuiGrid-grid-xs-6': {
                maxWidth: 'calc(50% - 15px)',
                flexBasis: 'calc(50% - 15px)'
            }
        }
    },
    title: {
        textAlign: 'center',
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(2)
    },
    label: {
        padding: theme.spacing(2.25, 2)
    },
    heading: {
        ...theme.typography.h5,
        fontWeight: 600,
        paddingBottom: theme.spacing(2),
        borderBottom: '1px solid ' + theme.palette.borderColor.main,
        marginBottom: theme.spacing(1),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    input: {
        width: '100%',
        marginLeft: 0,
        '& .MuiInputLabel-shrink': {
            transform: 'none',
            top: '-20px',
            lineHeight: '28px',
            fontWeight: '500',
            fontSize: '18px'
        }
    },
    switch: {
        marginLeft: 0
    },
    loaderPopup: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        background: theme.palette.common.white,
        padding: theme.spacing(4, 5),
        borderRadius: '20px',
        zIndex: theme.zIndex.modal
    },
    logoHeading: {
        margin: theme.spacing(2, 0)
    },
    bottom: {
        marginTop: theme.spacing(2)
    },
    button: {
        marginRight: theme.spacing(2)
    },
    logo: {
        maxWidth: '450px',
        width: '100%',
        marginBottom: theme.spacing(2),

        '& img': {
            width: '100%'
        }
    },
    inputLabel: {
        position: 'relative',
        fontSize: '1.125rem',
        transform: 'none',
        fontWeight: 500,
        userSelect: 'none',
        paddingBottom: '4px',
        marginTop: '25px'
    },
    formControl: {
        width: '100%',
        margin: theme.spacing(3.125, 0, 1, 0),
        minWidth: '120px'
    },
    checkboxLabel: {
        position: 'relative',
        fontSize: '1.125rem',
        fontWeight: 500,
        transform: 'none',
        userSelect: 'none',
        paddingBottom: `${theme.spacing(0.5)}px`,
        margin: 0
    }
}));

const formSettings = {
    name: {
        required: true
    },
    domain: {
        required: true
    },
    region: {
        required: true
    }
};

const CorporationDetail = () => {
    const classes = useStyles();
    const [t] = useTranslation();
    const history = useHistory();
    const { id } = useParams();
    const { enqueueSnackbar } = useSnackbar();

    const [uploadFile] = useMutation(UPLOAD_FILE);

    const emptyCorporation = {
        id: null,
        name: '',
        domain: '',
        region: '',
        academyUrl: '',
        hasAcademyUrl: false,
        logo: {
            id: null
        }
    };
    const [draft, setDraft] = useState(emptyCorporation);

    const [form, submitForm] = useForm(formSettings);
    const { data, loading } = useQuery(GET_CORPORATION, {
        variables: {
            id: id
        }
    });

    const [updateCorporation, { loading: mutationLoading, data: dataSave }] = useMutation(UPDATE_CORPORATION);

    const { data: regionsData = {} } = useQuery(GET_REGIONS);
    const { data: provincesData = {} } = useQuery(GET_PROVINCES);

    const provinces = provincesData && provincesData.provinces ? provincesData.provinces : [];

    const handleCancel = () => {
        history.goBack();
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        submitForm().then((resp) => {
            if (resp) {
                updateCorporation({
                    variables: {
                        input: {
                            id: id,
                            name: resp.name,
                            domain: resp.domain,
                            region: resp.region,
                            academyUrl: resp.academyUrl,
                            provinces: resp.provinces,
                            ...(resp.logo && {
                                logo: {
                                    id: resp.logo
                                }
                            }),
                            active: resp.active
                        }
                    }
                })
                    .then((result) => {
                        if (result.errors) {
                            enqueueSnackbar({
                                variant: 'warning',
                                message: t('corporations.saveError')
                            });
                        }
                    })
                    .catch(() => {
                        enqueueSnackbar({
                            variant: 'warning',
                            message: t('corporations.saveError')
                        });
                    });
            }
        });
    };

    const handleFile = (file) => {
        if (file) {
            uploadFile({
                variables: {
                    file: file
                },
                context: {
                    hasUpload: true
                }
            })
                .then((response) => {
                    const fileId = response.data.uploadFile.asset.id;
                    if (fileId) form.onFieldChange({ key: 'logo', value: fileId });
                })
                .catch((error) => {
                    console.warn('uploadDocument error: ', error);
                });
        }
    };

    const clearFile = (file) => {
        form.onFieldChange({ key: 'logo', value: draft.logo.id });
    };

    const handleCorporation = (event) => {
        const { value } = event;
        form.onFieldChange({
            key: 'academyUrl',
            value: value ? 'https://decorporatieacademie.plusport.com/scripts/login.aspx' : ''
        });
    };

    useEffect(() => {
        if (data && data.corporation) {
            setDraft(data.corporation);
        }

        if (loading && !data?.id) {
            setDraft(emptyCorporation);
        }
    }, [data, loading]);

    useEffect(() => {
        if (dataSave) {
            const id = dataSave.updateCorporation.corporation.id;
            if (id) {
                enqueueSnackbar({
                    variant: 'success',
                    message: t('corporations.saved')
                });
                history.push(`/corporations`);
            } else {
                enqueueSnackbar({
                    variant: 'warning',
                    message: t('corporations.saveError')
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataSave]);

    if (loading || !draft.id) {
        return (
            <Modal
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={loading || mutationLoading}
            >
                <div className={classes.loaderPopup}>
                    <Loader />
                    <Typography>Loading...</Typography>
                </div>
            </Modal>
        );
    }

    return (
        <Grid container justify="center">
            <Grid item xs={6}>
                <Paper className={classes.paper} elevation={0}>
                    <Typography className={classes.heading}>
                        <span>{t('corporations.editTitle')}</span>
                    </Typography>
                    <Grid container justify="space-between">
                        <Grid item xs={12}>
                            <Switch
                                name="active"
                                className={classes.switch}
                                label={t('corporations.active')}
                                form={form}
                                checked={draft.active}
                            />
                            <InputField
                                name="name"
                                className={classes.input}
                                initialValue={draft.name || ''}
                                type="text"
                                label={t('corporations.form.company')}
                                form={form}
                            />
                            <InputField
                                name="domain"
                                className={classes.input}
                                initialValue={draft.domain || ''}
                                type="text"
                                label={t('corporations.form.domain')}
                                form={form}
                            />

                            <InputLabel className={classes.inputLabel}>{t('corporations.academy')}</InputLabel>
                            <Switch
                                name="academy"
                                className={classes.switch}
                                onChange={handleCorporation}
                                checked={draft.hasAcademyUrl}
                            />

                            {draft.logo && (
                                <React.Fragment>
                                    <Typography variant="h6" className={classes.logoHeading}>
                                        {t('corporations.logoPreview')}:
                                    </Typography>
                                    <div className={classes.logo}>
                                        <img className={classes.image} src={draft.logo.url} alt={draft.name} />
                                    </div>
                                </React.Fragment>
                            )}

                            <FileUpload
                                label={t('corporations.form.logo')}
                                onSucces={handleFile}
                                name="logo"
                                initialValue={draft.logo || null}
                                onClear={clearFile}
                                form={form}
                            />

                            {regionsData.regions && (
                                <SelectField
                                    label={t('corporations.form.region')}
                                    helper={false}
                                    initialValue={draft.region.id || ''}
                                    options={regionsData.regions.regions}
                                    name="region"
                                    form={form}
                                />
                            )}

                            <CheckboxGroup
                                label={t('corporations.provinces')}
                                name="provinces"
                                classes={{ formControl: classes.formControl }}
                                labelClass={classes.checkboxLabel}
                                options={
                                    provinces &&
                                    provinces.map((item) => {
                                        return {
                                            value: item,
                                            label: item
                                        };
                                    })
                                }
                                initialValue={
                                    draft.provinces
                                        ? draft.provinces.map((province) => {
                                              return { id: province };
                                          })
                                        : []
                                }
                                helper={false}
                                form={form}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className={classes.bottom}>
                        <Button
                            variant="contained"
                            className={classes.button}
                            color="primary"
                            label={t('general.cancel')}
                            onClick={handleCancel}
                        />

                        <Button variant="contained" color="secondary" onClick={handleSubmit} label="Opslaan" />
                    </Grid>
                </Paper>
            </Grid>
            <LoaderModal loading={loading && mutationLoading} />
        </Grid>
    );
};

export default CorporationDetail;
