import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import { Grid, Paper } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import Button from '@material-ui/core/Button';
import { Button as SecondaryButton, SelectField, Checkbox } from 'shared';
import { useForm } from 'hooks';
import { MERGE_CORPORATION } from './queries';
import { useMutation } from '@apollo/react-hooks';

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative'
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1
    },
    wrapper: {
        marginTop: '100px',
        maxWidth: '900px',
        width: '100%',
        margin: '0 auto'
    },
    paper: {
        padding: '20px'
    },
    button: {
        marginTop: '10px'
    },
    corpTo: {
        color: 'green'
    },
    corpFrom: {
        color: 'red'
    },
    flex: {
        marginTop: '10px'
    }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const FuseDialog = (props) => {
    const { open = false, corporations, loading } = props;
    const classes = useStyles();
    const [options, setOptions] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [result, setResult] = useState(null);
    const [corporationFrom, setCorporationFrom] = useState('');
    const [corporationTo, setCorporationTo] = useState('');
    const { enqueueSnackbar } = useSnackbar();
    const [form, checkForm] = useForm({
        corporationFrom: {
            required: true
        },
        corporationTo: {
            required: true
        }
    });

    const [mutate] = useMutation(MERGE_CORPORATION, {
        refetchQueries: ['getCorporations']
    });

    useEffect(() => {
        if (
            !loading &&
            corporations &&
            corporations.corporations &&
            corporations.corporations.corporations &&
            corporations.corporations.corporations.length
        ) {
            setOptions(
                corporations.corporations.corporations
                    .map((i) => {
                        return {
                            label: `${i.name}(${i.domain})`,
                            value: i.id
                        };
                    })
                    .sort((a, b) => {
                        if (a.label.toUpperCase() < b.label.toUpperCase()) {
                            return -1;
                        }
                        if (a.label.toUpperCase() > b.label.toUpperCase()) {
                            return 1;
                        }
                        return 0;
                    })
            );
        }
    }, [loading, corporations]);

    const handleClose = () => {
        setOpenDialog(false);
        if (props.onClose) props.onClose();
    };

    const handleClick = () => {
        checkForm()
            .then((r) => {
                setResult(r);
                setOpenDialog(true);
            })
            .catch((e) => console.log('e', e));
    };

    const handleChange = (name) => (data) => {
        options.forEach((i) => {
            if (i.value === data) {
                if (name === 'corporationTo') setCorporationTo(i.label);
                else setCorporationFrom(i.label);
            }
        });
    };

    const handleFuse = () => {
        mutate({
            variables: {
                input: {
                    ...result,
                    execute: true
                }
            }
        })
            .then((r) => {
                if (r.data && r.data.createCorporationMerge) {
                    enqueueSnackbar({
                        variant: 'success',
                        message: 'Fuseren geslaagd'
                    });
                    handleClose();
                } else {
                    enqueueSnackbar({
                        variant: 'warning',
                        message: 'Er ging iets fout'
                    });
                }
            })
            .catch(() => {
                enqueueSnackbar({
                    variant: 'warning',
                    message: 'Er ging iets fout'
                });
            });
    };

    return (
        <React.Fragment>
            <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title} color="secondary">
                            Fuseren
                        </Typography>
                    </Toolbar>
                </AppBar>
                <div className={classes.wrapper}>
                    <Grid alignContent="center" container spacing={3}>
                        <Grid item xs={6}>
                            <Paper className={classes.paper}>
                                <SelectField
                                    label="Fuseren van"
                                    placeholder="Corporatie"
                                    helper={false}
                                    loading={loading}
                                    options={options}
                                    form={form}
                                    onChange={handleChange('corporationFrom')}
                                    name="corporationFrom"
                                />
                            </Paper>
                        </Grid>
                        <Grid item xs={6}>
                            <Paper className={classes.paper}>
                                <SelectField
                                    label="Fuseren naar"
                                    placeholder="Corporatie"
                                    form={form}
                                    helper={false}
                                    options={options}
                                    onChange={handleChange('corporationTo')}
                                    name="corporationTo"
                                />
                            </Paper>
                            <div className={classes.flex}>
                                <Checkbox label={'email versturen ?'} form={form} name="sendAlert" />
                                <SecondaryButton
                                    label="Fuseren"
                                    onClick={handleClick}
                                    variant="contained"
                                    color="primary"
                                    className={classes.button}
                                />
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </Dialog>
            <Dialog
                open={openDialog}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="sm"
                fullWidth={true}
            >
                <DialogTitle id="alert-dialog-title">{'Corporaties fuseren?'}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        U staat op het punt om <span className={classes.corpFrom}>{corporationFrom}</span> te fuseren in{' '}
                        <span className={classes.corpTo}>{corporationTo}</span>.
                        <br />
                        Hierbij wordt <span className={classes.corpFrom}>{corporationFrom}</span> op inactief gezet.
                        <br />
                        <br />
                        Doorgaan met fuseren?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Annuleren
                    </Button>
                    <Button onClick={handleFuse} color="primary" autoFocus>
                        Fuseren
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};

export default FuseDialog;
