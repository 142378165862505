import React, { useState, useEffect } from 'react';
import { Button } from 'shared';
import { makeStyles } from '@material-ui/styles';
import RequestMeetingsResetDialog from './RequestMeetingsResetDialog';
import { Typography } from '@material-ui/core';
import Moment from 'moment';
import { formatDateTime } from '../../helpers';

const useStyles = makeStyles((theme) => ({
    button: {
        margin: theme.spacing(3, 0, 1)
    }
}));

const RequestMeetingsReset = (props) => {
    const { user } = props;
    const [dialogOpen, setDialogOpen] = useState(false);
    const classes = useStyles();

    const openDialog = () => {
        setDialogOpen(true);
    };

    const statusArchived = 'ARCHIVED';

    const meetingRow = (m, i) => {
        console.log(m);
        return <div key={i}>{m.id}</div>;
    };

    const getUserMeetings = (user) => {
        // return user.meetings.filter((item) => item.status === 'FINISHED' || item.status === 'ARCHIVED');
        return user.meetings.filter((item) => item.finishedOn !== null);
    };

    const showAllMeetingsUrl = (user) => {
        const url = '/meetings?page=0&pageSize=100&&sortProperties=email&search=' + encodeURIComponent(user.email);
        return <a href={url}>Bekijk alle gesprekken van deze gebruiker</a>;
    };

    return (
        <div>
            {getUserMeetings(user).map((m, i) => {
                return (
                    <div key={i}>
                        <b>ronde:</b> {m.batch}
                        <br />
                        <b>code:</b> {m.code}
                        <br />
                        <b>type:</b> {m.typeTitle}
                        <br />
                        <b>status:</b> {m.statusTitle}
                        {/*
                        <br />
                        <b>gemaakt op:</b> {formatDateTime(m.createdOn)}
                        <br />
                        <b>gepland op:</b> {formatDateTime(m.plannedOn)}
*/}
                        <br />
                        <b>gevoerd op:</b> {formatDateTime(m.finishedOn)}
                        <br />
                        {/*
                        <b>gearchiveerd op:</b> {formatDateTime(m.archivedOn)}
                        <br />
*/}
                        <hr />
                    </div>
                );
            })}
            {getUserMeetings(user).length > 0 ? (
                <div>Dit zijn alleen de gevoerde gesprekken</div>
            ) : (
                <div>Er zijn geen gevoerde gesprekken</div>
            )}
            <br />
            {showAllMeetingsUrl(user)}
            <br />
            <Button
                variant={'outlined'}
                label="Reset de loopbaangesprekken..."
                onClick={openDialog}
                className={classes.button}
            />
            <RequestMeetingsResetDialog open={dialogOpen} onClose={() => setDialogOpen(false)} user={user} />
        </div>
    );
};

export default RequestMeetingsReset;
