import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useMutation } from '@apollo/react-hooks';
import { Button } from 'shared';
import { LoaderModal } from 'shared';
import { UPDATE_MEETING } from './queries';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: `${theme.spacing(10)}px auto`,
        padding: theme.spacing(0, 4),
        maxWidth: '800px'
    },
    paper: {
        width: '100%',
        padding: theme.spacing(7, 8, 8, 7),
        borderRadius: '25px',

        '& .MuiGrid-grid-xs-6': {
            maxWidth: 'calc(50% - 50px)',
            flexBasis: 'calc(50% - 50px)',
            '& .MuiGrid-grid-xs-6': {
                maxWidth: 'calc(50% - 15px)',
                flexBasis: 'calc(50% - 15px)'
            }
        }
    },
    title: {
        textAlign: 'center',
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(2)
    },
    label: {
        padding: theme.spacing(2.25, 2)
    },
    heading: {
        ...theme.typography.h5,
        fontWeight: 600,
        paddingBottom: theme.spacing(2),
        borderBottom: '1px solid ' + theme.palette.borderColor.main,
        marginBottom: theme.spacing(1),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    input: {
        width: '100%',
        marginLeft: 0,
        '& .MuiInputLabel-shrink': {
            transform: 'none',
            top: '-20px',
            lineHeight: '28px',
            fontWeight: '500',
            fontSize: '18px'
        }
    },
    switch: {
        width: '50%',
        margin: `${theme.spacing(1)}px 0`
    },
    bottom: {
        marginTop: theme.spacing(2)
    },
    button: {
        marginRight: theme.spacing(2)
    },
    loaderPopup: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        background: theme.palette.common.white,
        padding: theme.spacing(4, 5),
        borderRadius: '20px',
        zIndex: theme.zIndex.modal
    }
}));

const RemoveMeeting = () => {
    const classes = useStyles();
    const params = useParams();
    const { t } = useTranslation();
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const { ownerId, id } = params;

    const [removeMeeting, { loading: mutationLoading, data: removeData }] = useMutation(UPDATE_MEETING);

    useEffect(() => {
        if (removeData) {
            enqueueSnackbar({
                variant: 'success',
                message: t('meetingRemove.saved')
            });
            history.push(`/meetings`);
        }
    }, [removeData, history, enqueueSnackbar, t]);

    const handleCancel = () => {
        history.goBack();
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        removeMeeting({
            variables: {
                input: {
                    id: id,
                    status: 'CANCELLED',
                    finishedOn: null,
                    owner: ownerId
                }
            }
        });
    };

    return (
        <Grid container justify="center" className={classes.root}>
            <Paper className={classes.paper} elevation={0}>
                <Typography className={classes.heading}>
                    <span>{t('meetingRemove.title')}</span>
                </Typography>

                <Typography variant="body1">
                    <span>{t('meetingRemove.introText')}</span>
                </Typography>

                <Grid container justify="space-between">
                    <Grid item xs={12} className={classes.bottom}>
                        <Button
                            variant="contained"
                            className={classes.button}
                            color="primary"
                            label={t('general.cancel')}
                            onClick={handleCancel}
                        />

                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={handleSubmit}
                            label={t('meetingRemove.button')}
                        />
                    </Grid>
                </Grid>
            </Paper>
            <LoaderModal loading={mutationLoading} />
        </Grid>
    );
};

export default RemoveMeeting;
