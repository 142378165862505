import gql from 'graphql-tag';

export const GET_SALARY_SCALES = gql`
    query salaryScales($pagination: PaginationInput) {
        salaryScales(pagination: $pagination) {
            count
            types {
                id
                description
            }
        }
    }
`;

export const GET_SALARY_SCALE = gql`
    query salaryScale($id: Long!) {
        salaryScale(id: $id) {
            description
            id
        }
    }
`;

export const CREATE_SALARY_SCALE = gql`
    mutation createSalaryScale($input: SalaryScaleInput!) {
        createSalaryScale(input: $input) {
            id
        }
    }
`;

export const UPDATE_SALARY_SCALE = gql`
    mutation updateSalaryScale($input: SalaryScaleInput!) {
        updateSalaryScale(input: $input) {
            id
        }
    }
`;

export const DELETE_SALARY_SCALE = gql`
    mutation deleteSalaryScale($input: SalaryScaleInput!) {
        deleteSalaryScale(input: $input)
    }
`;
