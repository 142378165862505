import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Loader } from 'shared';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
        width: '100vw'
    },
    text: {
        textAlign: 'center'
    }
}));

const LoadingScreen = (props) => {
    const classes = useStyles();

    return (
        <Grid container className={classes.root} justify="center" alignItems="center">
            <Grid item xs={2}>
                <Loader />
                <Typography className={classes.text} variant="h6">
                    Data portaal
                </Typography>
            </Grid>
        </Grid>
    );
};

export default LoadingScreen;
