import React, { useEffect, useState, useReducer, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import {
    Grid,
    Paper,
    Typography,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    FormControl
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Add } from '@material-ui/icons';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { InputField, Button, Checkbox } from 'shared';
import { LoaderModal } from 'shared';
import { useForm } from 'hooks';

import { UPDATE_FOLDER, GET_FOLDER, GET_ROLES } from './queries';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: `${theme.spacing(10)}px auto`,
        padding: theme.spacing(0, 4),
        maxWidth: '800px'
    },
    paper: {
        width: '100%',
        padding: theme.spacing(7, 8, 8, 7),
        borderRadius: '25px',

        '& .MuiGrid-grid-xs-6': {
            maxWidth: 'calc(50% - 50px)',
            flexBasis: 'calc(50% - 50px)',
            '& .MuiGrid-grid-xs-6': {
                maxWidth: 'calc(50% - 15px)',
                flexBasis: 'calc(50% - 15px)'
            }
        }
    },
    title: {
        textAlign: 'center',
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(2)
    },
    label: {
        padding: theme.spacing(2.25, 2)
    },
    heading: {
        ...theme.typography.h5,
        fontWeight: 600,
        paddingBottom: theme.spacing(2),
        borderBottom: '1px solid ' + theme.palette.borderColor.main,
        marginBottom: theme.spacing(1),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    input: {
        width: '100%',
        marginLeft: 0,
        '& .MuiInputLabel-shrink': {
            transform: 'none',
            top: '-20px',
            lineHeight: '28px',
            fontWeight: '500',
            fontSize: '18px'
        }
    },
    loaderPopup: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        background: theme.palette.common.white,
        padding: theme.spacing(4, 5),
        borderRadius: '20px',
        zIndex: theme.zIndex.modal
    },
    checkboxLabel: {
        position: 'relative',
        fontSize: '1.125rem',
        fontWeight: 500,
        transform: 'none',
        userSelect: 'none',
        paddingBottom: `${theme.spacing(0.5)}px`,
        margin: 0
    },
    formControl: {
        width: '100%',
        margin: theme.spacing(3.125, 0, 1, 0),
        minWidth: '120px'
    },
    formControlLabel: {
        color: theme.palette.primary.main[500],
        padding: theme.spacing(0, 0, 0.5),
        fontSize: '1.125rem',
        fontFamily: 'Galanogrotesque',
        fontWeight: '500',
        lineHeight: 1,
        margin: theme.spacing(0, 0, 2)
    },
    button: {
        margin: theme.spacing(2, 2, 0, 0)
    },
    bottom: {
        marginTop: theme.spacing(2)
    },
    checkboxFormControl: {
        width: 'auto'
    },
    checkboxFormControlLabel: {
        margin: 0
    }
}));

const EditFolder = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const params = useParams();
    const { id } = params;
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const [form, submitForm] = useForm();

    const { data = {}, loading } = useQuery(GET_FOLDER, {
        variables: {
            id: id
        },
        fetchPolicy: 'no-cache'
    });

    const { data: rolesData = {} } = useQuery(GET_ROLES);
    const roles = useMemo(() => (rolesData && rolesData.roles ? rolesData.roles.roles : []), [rolesData]);

    // states
    const [draft, setDraft] = useState({
        id: '',
        value: '',
        isPrivate: false,
        rolePermissions: []
    });

    const [rolePermissions, setRolePermissions] = useReducer((state, action) => {
        switch (action.type) {
            case 'initial':
                return action.data;
            case 'add':
                if (state[action.data.roleId] && state[action.data.roleId][action.data.name]) {
                    return {
                        ...state,
                        [action.data.roleId]: {
                            ...(state[action.data.roleId] || []),
                            [action.data.name]: false
                        }
                    };
                } else {
                    return {
                        ...state,
                        [action.data.roleId]: {
                            ...(state[action.data.roleId] || []),
                            role: true,
                            [action.data.name]: true
                        }
                    };
                }
            default:
                return state;
        }
    }, {});

    useEffect(() => {
        if (rolePermissions) {
            setRolePermissions(rolePermissions);
        }
    }, [rolePermissions]);

    const [updateFolder, { loading: mutationLoading, data: updateData, error: updateError }] =
        useMutation(UPDATE_FOLDER);

    useEffect(() => {
        if (updateError) {
            let errorMessageKey = 'editFolder.saveError';
            if (updateError.message.endsWith('error.assetfolder.not.empty')) {
                errorMessageKey = 'folders.errorFolderNotEmpty';
            }

            enqueueSnackbar({
                variant: 'warning',
                message: t(errorMessageKey)
            });
        } else if (updateData) {
            const id = updateData.updateAssetFolder.id;
            if (id) {
                enqueueSnackbar({
                    variant: 'success',
                    message: t('editFolder.saveSuccess')
                });
                history.push(`/folders`);
            } else {
                enqueueSnackbar({
                    variant: 'warning',
                    message: t('editFolder.saveError')
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateError, updateData]);

    useEffect(() => {
        if (data && data.assetFolder && roles) {
            const obj = {};

            if (data.assetFolder && data.assetFolder.rolePermissions) {
                data.assetFolder.rolePermissions.forEach((e) => {
                    obj[e.role.id] = e;
                });
            }

            setDraft({
                ...data.assetFolder
            });

            setRolePermissions({
                type: 'initial',
                data: obj
            });
        }
    }, [data, roles]);

    const handleCancel = () => {
        history.goBack();
    };

    const handleSubmit = (e) => {
        const permissionsObj = [];
        Object.entries(rolePermissions).forEach((key) => {
            if (key[1].role) {
                permissionsObj.push({
                    ...key[1],
                    role: parseFloat(key[0])
                });
            }
        });
        submitForm().then((resp) => {
            if (resp) {
                updateFolder({
                    variables: {
                        input: {
                            id: id,
                            name: resp.name,
                            canDelete: true,
                            isPrivate: resp.isPrivate,
                            rolePermissions: permissionsObj
                        }
                    }
                });
            }
        });
    };

    const handleRoleChange = (roleId, name) => () => {
        setRolePermissions({
            type: 'add',
            data: {
                roleId: roleId,
                name: name
            }
        });
    };

    return (
        <Grid container justify="center" className={classes.root}>
            <Paper className={classes.paper} elevation={0}>
                <Typography className={classes.heading}>
                    <span>{t('editFolder.title')}</span>
                </Typography>

                <Grid container justify="space-between">
                    {!loading && (
                        <Grid item xs={12}>
                            <InputField
                                name="name"
                                className={classes.input}
                                initialValue={draft.name || ''}
                                type="text"
                                label={t('folders.name')}
                                form={form}
                            />

                            <FormControl className={classes.formControl}>
                                <Typography variant="h6" className={classes.formControlLabel}>
                                    {t('folders.privateFolder')}
                                </Typography>
                                <Typography>
                                    <Checkbox
                                        classes={{
                                            formControl: classes.checkboxFormControl,
                                            labelRoot: classes.checkboxFormControlLabel
                                        }}
                                        checked={draft.isPrivate}
                                        name="isPrivate"
                                        form={form}
                                    />
                                    {form.fields.isPrivate ? 'Ja' : 'Nee'}
                                </Typography>
                                <Typography variant="subtitle2">{t('folders.privateFolderExplanation')}</Typography>
                            </FormControl>

                            <FormControl className={classes.formControl}>
                                <Typography variant="h6" className={classes.formControlLabel}>
                                    {t('folders.permissions')}
                                </Typography>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>{t('folders.role')}</TableCell>
                                            <TableCell align="center">{t('folders.access')}</TableCell>
                                            <TableCell align="center">{t('folders.add')}</TableCell>
                                            <TableCell align="center">{t('folders.delete')}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {roles &&
                                            roles.map((role) => (
                                                <TableRow key={role.id}>
                                                    <TableCell>{role.title}</TableCell>
                                                    <TableCell align="center">
                                                        <Checkbox
                                                            classes={{
                                                                formControl: classes.checkboxFormControl,
                                                                labelRoot: classes.checkboxFormControlLabel
                                                            }}
                                                            checked={
                                                                rolePermissions[role.id]
                                                                    ? rolePermissions[role.id].role
                                                                    : false
                                                            }
                                                            name="role"
                                                            onChange={handleRoleChange(role.id, 'role')}
                                                        />
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <Checkbox
                                                            classes={{
                                                                formControl: classes.checkboxFormControl,
                                                                labelRoot: classes.checkboxFormControlLabel
                                                            }}
                                                            checked={
                                                                rolePermissions[role.id]
                                                                    ? rolePermissions[role.id].filesCreate
                                                                    : false
                                                            }
                                                            onChange={handleRoleChange(role.id, 'filesCreate')}
                                                        />
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <Checkbox
                                                            classes={{
                                                                formControl: classes.checkboxFormControl,
                                                                labelRoot: classes.checkboxFormControlLabel
                                                            }}
                                                            checked={
                                                                rolePermissions[role.id]
                                                                    ? rolePermissions[role.id].filesDelete
                                                                    : false
                                                            }
                                                            onChange={handleRoleChange(role.id, 'filesDelete')}
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                    </TableBody>
                                </Table>
                            </FormControl>
                        </Grid>
                    )}

                    <Grid container className={classes.bottom}>
                        <Button
                            variant="contained"
                            className={classes.button}
                            color="primary"
                            label={t('general.cancel')}
                            onClick={handleCancel}
                        />
                        <Button
                            variant="contained"
                            color="secondary"
                            className={classes.button}
                            onClick={handleSubmit}
                            label={t('folders.save')}
                            iconLeft={() => <Add />}
                        />
                    </Grid>
                </Grid>
            </Paper>
            <LoaderModal loading={mutationLoading || loading} />
        </Grid>
    );
};

export default EditFolder;
