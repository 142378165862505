import Cookies from 'js-cookie';

export const initializeFirebase = (firebase) =>
    new Promise((resolve, reject) => {
        if (!firebase.apps.length) {
            firebase.initializeApp({
                apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
                authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
                databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
                projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
                storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
                messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
                appId: process.env.REACT_APP_FIREBASE_APP_ID
            });

            firebase.auth().onAuthStateChanged(
                (user) => {
                    // Auth changed
                    if (!user) {
                        if (!firebase.auth().currentUser) {
                            firebase
                                .auth()
                                .signInWithCustomToken(Cookies.get('firebase_token'))
                                .then(resolve)
                                .catch(reject);
                        } else {
                            resolve();
                        }
                    } else {
                        resolve();
                    }
                },
                () => {
                    firebase
                        .auth()
                        .currentUser.getIdToken(true)
                        .then((idToken) => {
                            Cookies.set('firebase_token', idToken);
                        })
                        .catch((error) => {
                            // Failed to refresh
                            console.warn(error);
                        });
                }
            );
        } else {
            resolve();
        }
    });

export const getFirebaseDataSnapshot = (enqueueSnackbar, t, firebase, ref) => {
    return new Promise((resolve, reject) => {
        initializeFirebase(firebase)
            .then(() => {
                firebase
                    .database()
                    .ref(ref)
                    .once('value')
                    .then((snapshot) => {
                        resolve(snapshot);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            })
            .catch(() =>
                enqueueSnackbar({
                    message: t('crawler.firebaseFailed'),
                    variant: 'error'
                })
            );
    });
};
