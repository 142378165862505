import React from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { loginUser } from 'store/actions';

const PrivateRoute = (props) => {
    const { component, path, superRoute, authPath = '/login', ...otherProps } = props;
    const location = useLocation();
    const token = useSelector((state) => state.auth.token);
    const dispatch = useDispatch();
    const isSuperAdmin = useSelector((state) => state.auth.roles.includes('SUPER_ADMIN'));
    const isAdmin = useSelector((state) => state.auth.roles.includes('ADMIN'));

    if (token && token !== 'deleted') {
        dispatch(loginUser(true));
        if (isSuperAdmin) {
            return <Route {...otherProps} component={component} />;
        } else if ((superRoute && !isSuperAdmin) || !isAdmin) {
            return (
                <Redirect
                    to={{
                        pathname: '/unauthorized',
                        state: { referrer: location.pathname }
                    }}
                />
            );
        }
        return <Route {...otherProps} component={component} />;
    } else {
        dispatch(loginUser(false));
        return (
            <Redirect
                to={{
                    pathname: authPath,
                    state: { referrer: location.pathname }
                }}
            />
        );
    }
};

export default PrivateRoute;
