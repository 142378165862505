import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Grid, Typography, Paper, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import HomeWorkOutlinedIcon from '@material-ui/icons/HomeWorkOutlined';
import SyncOutlinedIcon from '@material-ui/icons/SyncOutlined';
import GroupOutlinedIcon from '@material-ui/icons/GroupOutlined';
import FolderOutlinedIcon from '@material-ui/icons/FolderOutlined';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import SchoolOutlinedIcon from '@material-ui/icons/SchoolOutlined';
import ConfirmationNumberOutlinedIcon from '@material-ui/icons/ConfirmationNumberOutlined';
import SquareFootOutlinedIcon from '@material-ui/icons/SquareFootOutlined';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(0, 4)
    },
    header: {
        marginBottom: theme.spacing(2)
    },
    paper: {
        display: 'flex',
        padding: '48px',
        background: '#F8F5F9',
        borderRadius: theme.spacing(1),
        justifyContent: 'space-between',
        alignItems: 'center',
        textDecoration: 'none',
        '&:hover': {
            background: '#ddd5e0'
        }
    },
    divider: {
        margin: theme.spacing(4, 0)
    },
    link: {
        textDecoration: 'none'
    }
}));

const Dashboard = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const isSuperAdmin = useSelector((state) => state.auth.roles.includes('SUPER_ADMIN'));
    const isAdmin = useSelector((state) => state.auth.roles.includes('ADMIN'));
    const admin = isSuperAdmin || isAdmin || false;

    const appLinks = useMemo(() => {
        const links = [
            {
                path: '/vouchers',
                linkText: t('menu.vouchers'),
                adminLink: true,
                icon: <ConfirmationNumberOutlinedIcon />
            },
            {
                path: '/crawler',
                linkText: t('menu.crawler'),
                adminLink: true,
                icon: <SyncOutlinedIcon />
            },
            {
                path: '/users',
                linkText: t('menu.users'),
                adminLink: true,
                icon: <GroupOutlinedIcon />
            },
            {
                path: '/corporations',
                linkText: t('menu.corporations'),
                adminLink: true,
                icon: <HomeWorkOutlinedIcon />
            },
            {
                path: '/expertises',
                linkText: t('menu.expertises'),
                adminLink: true,
                icon: <SquareFootOutlinedIcon />
            },
            {
                path: '/regions',
                linkText: t('menu.regions'),
                adminLink: true,
                icon: <LocationOnOutlinedIcon />
            },
            {
                path: '/folders',
                linkText: t('menu.folders'),
                adminLink: true,
                icon: <FolderOutlinedIcon />
            },
            {
                path: '/education-grades',
                linkText: t('menu.educationGrades'),
                adminLink: true,
                icon: <SchoolOutlinedIcon />
            }
        ].sort((a, b) => (a.linkText > b.linkText ? 1 : b.linkText > a.linkText ? -1 : 0));

        return links;
    }, [t]);

    return (
        <Grid container className={classes.root}>
            <Grid item xs={12}>
                <Typography variant="h2" className={classes.header}>
                    {t('dashboard.title')}
                </Typography>
                <Typography variant="body1">{t('dashboard.welkomMessage')}</Typography>

                {admin && (
                    <>
                        <Divider className={classes.divider} />
                        <Typography variant="h2" className={classes.header}>
                            {t('dashboard.quickLinks')}
                        </Typography>

                        <Grid container spacing={2}>
                            {appLinks.map((item, index) => (
                                <Grid key={index} item xs={4}>
                                    <Link className={classes.link} to={item.path}>
                                        <Paper elevation={1} className={classes.paper}>
                                            <Typography className={classes.link} variant="body1">
                                                {item.linkText}
                                            </Typography>
                                            <ArrowForwardIcon />
                                        </Paper>
                                    </Link>
                                </Grid>
                            ))}
                        </Grid>
                    </>
                )}
            </Grid>
        </Grid>
    );
};

export default Dashboard;
