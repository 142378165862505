import React from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Add } from '@material-ui/icons';
import { useQuery } from '@apollo/react-hooks';
import { FilterOverview, LoaderModal } from 'shared';
import { Table, Button } from 'shared';
import { useFilter } from 'hooks';
import { GET_HOURS } from './queries';
import WorkingHoursRow from './WorkingHoursRow';

const useStyles = makeStyles((theme) => ({
    number: {
        fontWeight: 300,
        color: theme.palette.text.secondaryLight,
        fontSize: '24px',
        marginLeft: `${theme.spacing(1)}px`
    },
    item: {
        marginTop: `${theme.spacing(8)}px`
    },
    title: {
        display: 'flex',
        alignItems: 'center'
    },
    icon: {
        marginRight: `${theme.spacing(2)}px`,
        color: theme.palette.primary.main,
        padding: '2px'
    },
    button: {
        zIndex: '10'
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: theme.spacing(0, 4),
        marginBottom: theme.spacing(2)
    }
}));

const WorkingHours = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const history = useHistory();
    const query = useFilter();

    const { loading, data = {} } = useQuery(GET_HOURS, {
        variables: {
            ...query.query
        }
    });

    const { workingHoursList = [] } = data;

    const handleDeleteClick = (id) => {
        history.push(`/hours/remove/${id}`);
    };

    const handleEditClick = (id) => {
        history.push(`/hours/edit/${id}`);
    };

    return (
        <Grid container>
            <Grid item className={classes.header} xs={12}>
                <Typography variant="h4">{t('hours.title')}</Typography>
                <Button
                    component={Link}
                    to={'/hours/add'}
                    variant="contained"
                    label="Toevoegen"
                    color="secondary"
                    className={classes.button}
                    iconLeft={() => <Add />}
                />
            </Grid>
            <Grid item xs={12}>
                <FilterOverview query={query} loading={loading} useResults={false} count={workingHoursList.count || 0}>
                    {workingHoursList.workingHoursList && (
                        <Table
                            head={['Value', 'Lower', 'Upper', '']}
                            renderRows={() =>
                                workingHoursList.workingHoursList.map((hour) => (
                                    <WorkingHoursRow
                                        onDelete={handleDeleteClick}
                                        onClick={handleEditClick}
                                        key={hour.id}
                                        hour={hour}
                                    />
                                ))
                            }
                        />
                    )}
                </FilterOverview>
            </Grid>
            <LoaderModal loading={loading} />
        </Grid>
    );
};

export default WorkingHours;
