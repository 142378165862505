import React, { useEffect, useRef, useState } from 'react';
import { DeleteDialog, Table } from 'shared';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import { FilterOverview } from 'shared';
import { useFilter } from 'hooks';
import * as firebase from 'firebase/app';
import 'firebase/database';
import { getFirebaseDataSnapshot, initializeFirebase } from '../FirebaseInstance';
import { useSnackbar } from 'notistack';
import DuplicateVacancyDialog from '../Vacancy/DuplicateVacancyDialog';
import CrawlerVacancyRow, { DeploymentStatus } from '../Vacancy/CrawlerVacancyRow';
import { isUndefinedOrNullOrEmptyString } from '../../../helpers';
import { useMutation } from '@apollo/react-hooks';
import { DELETE_VACANCY } from '../queries';

const useStyles = makeStyles((theme) => ({
    root: {},
    number: {
        fontWeight: 300,
        color: theme.palette.text.secondaryLight,
        fontSize: '24px',
        marginLeft: `${theme.spacing(1)}px`
    },
    item: {
        marginTop: `${theme.spacing(8)}px`
    },
    title: {
        display: 'flex',
        alignItems: 'center'
    },
    icon: {
        marginRight: `${theme.spacing(2)}px`,
        color: theme.palette.primary.main,
        padding: '2px'
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: theme.spacing(0, 4),
        marginBottom: theme.spacing(2)
    }
}));

const CrawlerPlacedVacancies = () => {
    const mounted = useRef();
    const { t } = useTranslation();
    const classes = useStyles();
    const query = useFilter();
    const { enqueueSnackbar } = useSnackbar();
    const [vacancies, setVacancies] = useState([]);
    const [allVacancies, setAllVacancies] = useState([]);
    const [sites, setSites] = useState({});
    const [deletePlacedVacancyDialogOpen, setDeletePlacedVacancyDialogOpen] = useState(false);
    const [deleteDuplicateDialogOpen, setDeleteDuplicateDialogOpen] = useState(false);
    const [duplicateVacancyDialogOpen, setDuplicateVacancyDialogOpen] = useState(false);
    const [duplicateVacancy, setDuplicateVacancy] = useState(undefined);
    const [currentPlacedVacancyId, setCurrentPlacedVacancyId] = useState(undefined);
    const [currentVacancyId, setCurrentVacancyId] = useState(undefined);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    }, []);

    const [deleteVacancyFromKansenportaal] = useMutation(DELETE_VACANCY);

    const getVacancies = () => {
        getFirebaseDataSnapshot(enqueueSnackbar, t, firebase, 'offers').then(
            (snapshot) => {
                const result = snapshot.val();
                if (mounted.current) setLoading(false);

                if (result !== null) {
                    // The data arrives in the following format: {vacancy1:{}, vacancy2:{}}, which
                    // will be mapped to a plain old array to be used for the table
                    const data = Object.keys(result)
                        .map((vacancyKey) => ({
                            id: vacancyKey,
                            ...result[vacancyKey]
                        }))
                        .flat()
                        .filter((vacancy) => vacancy.deleted !== true);

                    if (mounted.current) {
                        setAllVacancies(data);
                        setVacancies(
                            data
                                .filter((vacancy) => vacancy.deploymentStatus !== undefined)
                                .filter((vacancy) => vacancy.deploymentOn !== undefined)
                                .sort((a, b) => new Date(b.deploymentOn) - new Date(a.deploymentOn))
                        );
                    }
                }
            },
            () => {
                showError('crawler.failedMessages.placedVacancies');
            }
        );
    };

    const getSites = () => {
        getFirebaseDataSnapshot(enqueueSnackbar, t, firebase, 'sites').then(
            (snapshot) => {
                const result = snapshot.val();
                if (result !== null && mounted.current) setSites(result);
            },
            () => {
                showError('crawler.failedMessages.sites');
            }
        );
    };

    useEffect(() => {
        initializeFirebase(firebase)
            .then(() => {
                getVacancies();
                getSites();
            })
            .catch(() => showError('crawler.firebaseFailed'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getVacancyById = (id) => {
        return allVacancies.find((vacancy) => vacancy && vacancy.id === id);
    };

    const handleRowClick = (id) => {
        const vacancy = getVacancyById(id);
        let url = '';

        if (isUndefinedOrNullOrEmptyString(vacancy.deploymentUrl)) {
            url = `/crawler/vacancy/${id}`;
        } else {
            url = `${process.env.REACT_APP_VACANCIES_FLOW_DOMAIN}${vacancy.deploymentUrl}`;
        }

        window.open(url, '_blank');
    };

    const handlePlacedVacancyDelete = (id) => {
        setCurrentPlacedVacancyId(id);
        setDeletePlacedVacancyDialogOpen(true);
    };
    const handleRowDelete = (id) => {
        setCurrentVacancyId(id);
        setDeleteDuplicateDialogOpen(true);
    };

    const handleRowEdit = (id) => {
        const vacancy = getVacancyById(id);
        if (isUndefinedOrNullOrEmptyString(vacancy.deploymentUrl)) return;
        const url = `${process.env.REACT_APP_VACANCIES_FLOW_DOMAIN}/hr-kansen/bewerken${vacancy.deploymentUrl.replace(
            '/kansen',
            ''
        )}`;
        window.open(url, '_blank');
    };

    const handleDuplicateClick = (id) => {
        const vacancy = allVacancies.find((vacancy) => vacancy.id === id);
        if (vacancy !== undefined) {
            setDuplicateVacancy(vacancy);
            setDuplicateVacancyDialogOpen(true);
        }
    };

    const deletePlacedVacancy = () => {
        const vacancy = getVacancyById(currentPlacedVacancyId);

        deleteVacancyFromKansenportaal({
            variables: {
                input: {
                    forceDelete: false,
                    id: vacancy.deploymentID
                }
            }
        })
            .then((response) => {
                if (response.errors) {
                    showError('crawler.vacancies.deleteFailed');
                }

                if (response.data) {
                    showSuccess('crawler.vacancies.deleteSuccess');

                    const updateFirebase = {};
                    if (response.data.deleteVacancy === true) {
                        updateFirebase.deploymentStatus = DeploymentStatus.REMOVED;
                    }

                    initializeFirebase(firebase)
                        .then(() => {
                            firebase
                                .database()
                                .ref(`offers/${currentPlacedVacancyId}`)
                                .update(updateFirebase, (error) => {
                                    if (error) {
                                        showError('crawler.placedVacancies.crawlerSaveFailed');
                                    } else {
                                        firebase
                                            .app()
                                            .delete()
                                            .then(() => getVacancies());
                                    }
                                });
                        })
                        .catch(() => showError('crawler.firebaseFailed'));
                }
            })
            .catch(() => {
                showError('crawler.vacancies.saveFailed');
            });
    };

    const deleteDuplicateVacancy = () => {
        initializeFirebase(firebase)
            .then(() => {
                firebase
                    .database()
                    .ref(`offers/${currentVacancyId}`)
                    .update({ deleted: true }, (error) => {
                        if (error) {
                            showError('crawler.vacancies.deleteFailed');
                        } else {
                            showSuccess('crawler.vacancies.deleteSuccess');
                            getVacancies();
                        }
                    });
            })
            .catch(() => showError('crawler.firebaseFailed'));
    };

    const showError = (messageKey) => enqueueSnackbar({ variant: 'error', message: t(messageKey) });

    const showSuccess = (messageKey) => enqueueSnackbar({ variant: 'success', message: t(messageKey) });

    const page = parseInt(query.pagination.page);
    const pageSize = parseInt(query.pagination.pageSize);

    return (
        <Grid container className={classes.root}>
            <Grid item className={classes.header} xs={12}>
                <Typography variant="h2">{t('crawler.placedVacancies.title')}</Typography>
            </Grid>
            <Grid item xs={12}>
                <FilterOverview query={query} loading={loading} useResults={false} count={vacancies.length || 0}>
                    {vacancies && (
                        <Table
                            head={[
                                t('crawler.vacancies.table.company'),
                                t('crawler.vacancies.table.site'),
                                t('crawler.vacancies.table.title'),
                                t('crawler.placedVacancies.table.placementDate'),
                                t('crawler.vacancies.table.completed'),
                                ''
                            ]}
                            renderRows={() =>
                                vacancies
                                    .slice(page * pageSize, page * pageSize + pageSize)
                                    .map((vacancy, i) => (
                                        <CrawlerVacancyRow
                                            key={i}
                                            site={sites[vacancy.siteId]}
                                            vacancy={vacancy}
                                            onClick={handleRowClick}
                                            onDelete={handlePlacedVacancyDelete}
                                            onEdit={handleRowEdit}
                                            onDuplicateClick={handleDuplicateClick}
                                            isPlacedVacancy
                                        />
                                    ))
                            }
                        />
                    )}
                </FilterOverview>
            </Grid>

            <DeleteDialog
                open={deletePlacedVacancyDialogOpen}
                title={t('crawler.vacancies.deleteDialog.title')}
                description={t('crawler.placedVacancies.deleteDialog.description')}
                positiveText={t('crawler.placedVacancies.deleteDialog.positiveText')}
                negativeText={t('crawler.placedVacancies.deleteDialog.negativeText')}
                onClose={() => setDeletePlacedVacancyDialogOpen(false)}
                onPositive={deletePlacedVacancy}
            />

            <DeleteDialog
                open={deleteDuplicateDialogOpen}
                title={t('crawler.vacancies.deleteDialog.title')}
                description={t('crawler.vacancies.deleteDialog.description')}
                positiveText={t('crawler.vacancies.deleteDialog.positiveText')}
                negativeText={t('crawler.vacancies.deleteDialog.negativeText')}
                onClose={() => setDeleteDuplicateDialogOpen(false)}
                onPositive={deleteDuplicateVacancy}
            />

            <DuplicateVacancyDialog
                vacancies={allVacancies}
                vacancy={duplicateVacancy}
                onDeleteVacancy={handleRowDelete}
                onVisitVacancy={handleRowClick}
                sites={sites}
                open={duplicateVacancyDialogOpen}
                onClose={() => setDuplicateVacancyDialogOpen(false)}
                isPlacedVacancy
            />
        </Grid>
    );
};

export default CrawlerPlacedVacancies;
