import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { Button } from 'shared';
import { LoaderModal } from 'shared';
import { DELETE_EDUCATION_GRADE, GET_EDUCATION_GRADE } from './queries';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: `${theme.spacing(10)}px auto`,
        padding: theme.spacing(0, 4),
        maxWidth: '800px'
    },
    paper: {
        width: '100%',
        padding: theme.spacing(7, 8, 8, 7),
        borderRadius: '25px',

        '& .MuiGrid-grid-xs-6': {
            maxWidth: 'calc(50% - 50px)',
            flexBasis: 'calc(50% - 50px)',
            '& .MuiGrid-grid-xs-6': {
                maxWidth: 'calc(50% - 15px)',
                flexBasis: 'calc(50% - 15px)'
            }
        }
    },
    title: {
        textAlign: 'center',
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(2)
    },
    label: {
        padding: theme.spacing(2.25, 2)
    },
    heading: {
        ...theme.typography.h5,
        fontWeight: 600,
        paddingBottom: theme.spacing(2),
        borderBottom: '1px solid ' + theme.palette.borderColor.main,
        marginBottom: theme.spacing(1),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    button: {
        margin: theme.spacing(2, 0, 0, 0)
    },
    bottom: {
        marginTop: theme.spacing(2)
    },
    loaderPopup: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        background: theme.palette.common.white,
        padding: theme.spacing(4, 5),
        borderRadius: '20px',
        zIndex: theme.zIndex.modal
    }
}));

const RemoveEducationGrade = () => {
    const classes = useStyles();
    const params = useParams();
    const { id } = params;
    const { t } = useTranslation();
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const [remove, { loading: mutationLoading, data: removeData }] = useMutation(DELETE_EDUCATION_GRADE);

    const { data = {} } = useQuery(GET_EDUCATION_GRADE, {
        variables: {
            id: id
        }
    });

    const [draft, setDraft] = useState({
        id: '',
        description: ''
    });

    useEffect(() => {
        if (data && data.educationGrade) {
            setDraft(data.educationGrade);
        }
    }, [data]);

    useEffect(() => {
        if (removeData) {
            enqueueSnackbar({
                variant: 'success',
                message: t('general.saved')
            });
            history.push(`/education-grades`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [removeData]);

    const handleCancel = () => {
        history.goBack();
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        remove({
            variables: {
                input: {
                    id: id
                }
            }
        });
    };

    return (
        <Grid container justify="center" className={classes.root}>
            <Paper className={classes.paper} elevation={0}>
                <Typography className={classes.heading}>
                    {t('removeEducationGrade.title')} : {draft.description || ''}
                </Typography>
                <Typography variant="body1">
                    <span>{t('removeEducationGrade.introText')}</span>
                </Typography>

                <Grid container className={classes.bottom}>
                    <Button
                        variant="contained"
                        className={classes.button}
                        color="primary"
                        label={t('general.cancel')}
                        onClick={handleCancel}
                    />
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleSubmit}
                        className={classes.button}
                        label={t('general.removeButton')}
                    />
                </Grid>
            </Paper>
            <LoaderModal loading={mutationLoading} />
        </Grid>
    );
};

export default RemoveEducationGrade;
