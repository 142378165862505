import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { InputField, Button, SelectField, FileUpload } from 'shared';
import { LoaderModal } from 'shared';
import { useForm } from 'hooks';
import { CREATE_CORPORATION, GET_REGIONS, UPLOAD_FILE } from './queries';

const useStyles = makeStyles((theme) => ({
    paper: {
        width: '100%',
        padding: theme.spacing(7, 8, 8, 7),
        borderRadius: '25px',

        '& .MuiGrid-grid-xs-6': {
            maxWidth: 'calc(50% - 50px)',
            flexBasis: 'calc(50% - 50px)',
            '& .MuiGrid-grid-xs-6': {
                maxWidth: 'calc(50% - 15px)',
                flexBasis: 'calc(50% - 15px)'
            }
        }
    },
    title: {
        textAlign: 'center',
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(2)
    },
    label: {
        padding: theme.spacing(2.25, 2)
    },
    heading: {
        ...theme.typography.h5,
        fontWeight: 600,
        paddingBottom: theme.spacing(2),
        borderBottom: '1px solid ' + theme.palette.borderColor.main,
        marginBottom: theme.spacing(1),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    input: {
        width: '100%',
        marginLeft: 0,
        '& .MuiInputLabel-shrink': {
            transform: 'none',
            top: '-20px',
            lineHeight: '28px',
            fontWeight: '500',
            fontSize: '18px'
        }
    },
    bottom: {
        marginTop: theme.spacing(2)
    },
    button: {
        marginRight: theme.spacing(2)
    },
    loaderPopup: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        background: theme.palette.common.white,
        padding: theme.spacing(4, 5),
        borderRadius: '20px',
        zIndex: theme.zIndex.modal
    }
}));

const formSettings = {
    name: {
        required: true
    },
    domain: {
        required: true
    },
    region: {
        required: true
    }
};

const AddCorporation = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const [form, submitForm] = useForm(formSettings);

    const [uploadFile] = useMutation(UPLOAD_FILE);
    const [createCorporation, { loading: mutationLoading, data }] = useMutation(CREATE_CORPORATION);

    const { data: regionsData = {} } = useQuery(GET_REGIONS);

    useEffect(() => {
        if (data) {
            const id = data.createCorporation.corporation.id;
            if (id) {
                enqueueSnackbar({
                    variant: 'success',
                    message: t('corporations.corporationMade')
                });
                history.push(`/corporations`);
            } else {
                enqueueSnackbar({
                    variant: 'warning',
                    message: t('corporations.saveError')
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const handleCancel = () => {
        history.goBack();
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        submitForm().then((resp) => {
            if (resp) {
                createCorporation({
                    variables: {
                        input: {
                            name: resp.name,
                            domain: resp.domain,
                            region: resp.region,
                            ...(resp.logo && {
                                logo: {
                                    id: resp.logo
                                }
                            })
                        }
                    }
                })
                    .then((result) => {
                        if (result.errors) {
                            enqueueSnackbar({
                                variant: 'warning',
                                message: t('corporations.saveError')
                            });
                        }
                    })
                    .catch(() => {
                        enqueueSnackbar({
                            variant: 'warning',
                            message: t('corporations.saveError')
                        });
                    });
            }
        });
    };

    const handleFile = (file) => {
        if (file) {
            uploadFile({
                variables: {
                    file: file
                },
                context: {
                    hasUpload: true
                }
            })
                .then((response) => {
                    const fileId = response.data.uploadFile.asset.id;
                    if (fileId) form.onFieldChange({ key: 'logo', value: fileId });
                })
                .catch((error) => {
                    console.warn('uploadDocument error: ', error);
                });
        }
    };

    const clearFile = (file) => {
        form.onFieldChange({ key: 'logo', value: '' });
    };

    return (
        <Grid container justify="center">
            <Grid item xs={6}>
                <Paper className={classes.paper} elevation={0}>
                    <Typography className={classes.heading}>
                        <span>{t('corporations.addTitle')}</span>
                    </Typography>

                    <Grid container justify="space-between">
                        <Grid item xs={12}>
                            <InputField
                                name="name"
                                className={classes.input}
                                initialValue={''}
                                type="text"
                                label={t('corporations.form.name')}
                                form={form}
                            />
                            <InputField
                                name="domain"
                                className={classes.input}
                                initialValue={''}
                                type="text"
                                label={t('corporations.form.domain')}
                                form={form}
                            />
                            <FileUpload
                                label={t('corporations.form.logo')}
                                onSucces={handleFile}
                                name="logo"
                                initialValue={''}
                                onClear={clearFile}
                                form={form}
                            />
                            {regionsData.regions && (
                                <SelectField
                                    label={t('corporations.form.region')}
                                    placeholder="regio"
                                    helper={false}
                                    options={regionsData.regions.regions}
                                    name="region"
                                    initialValue={''}
                                    form={form}
                                />
                            )}
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className={classes.bottom}>
                        <Button
                            variant="contained"
                            className={classes.button}
                            color="primary"
                            label={t('general.cancel')}
                            onClick={handleCancel}
                        />

                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={handleSubmit}
                            label={t('corporations.addButton')}
                        />
                    </Grid>
                </Paper>
            </Grid>
            <LoaderModal loading={mutationLoading} />
        </Grid>
    );
};

export default AddCorporation;
