import { gql } from 'apollo-boost';

export const LOGIN = gql`
    mutation login($email: String!, $password: String!) {
        login(input: { email: $email, password: $password }) {
            user {
                id
                email
            }
        }
    }
`;

export const LOGOUT = gql`
    mutation logout {
        logout
    }
`;

export const GET_USERS = gql`
    query users($pagination: PaginationInput!, $filter: UserFilterInput) {
        users(pagination: $pagination, filter: $filter) {
            users {
                id
                email
                profile {
                    dateOfBirth
                    firstName
                    middleName
                    lastName
                    fullName
                }
                roles {
                    id
                    name
                    title
                }
                lastLogin
                firstLogin
                corporation {
                    id
                    domain
                    name
                    academyUrl
                    region {
                        id
                    }
                    logo {
                        thumbnailUrl
                    }
                }
            }
            count
        }
    }
`;

export const SAVE_USER = gql`
    mutation updateUser($input: UpdateUserInput!) {
        updateUser(input: $input) {
            user {
                id
            }
        }
    }
`;

export const SAVE_PROFILE_PICTURE = gql`
    mutation uploadProfilePicture($file: Upload) {
        uploadProfilePicture(file: $file) {
            asset {
                id
            }
        }
    }
`;

export const GET_USER = gql`
    query user($id: Long!) {
        user(id: $id) {
            id
            profile {
                biography
                firstName
                lastName
                middleName
                fullName
                phone
                city {
                    id
                    name
                }
                region {
                    id
                    description
                }
                regionList {
                    id
                    description
                }
                profilePicture {
                    id
                    url
                    thumbnailS3Id
                }
                provinces
            }
            origin
            roles {
                id
                name
                title
            }
            corporation {
                id
                name
                domain
                academyUrl
                region {
                    id
                }
                logo {
                    thumbnailUrl
                }

                region {
                    id
                    description
                }
            }
            accountEnabled
            email
            createdOn
            lastLogin
            primaryEmail
            preferredEmail
            skillTestReset
            skillTestStartedOn
            skillTestResetOn
            skillTest1FinishedOn
            skillTestFinishedOn
            skillTestArchivedCount
            meetings {
                code
                batch
                createdOn
                lastModifiedOn
                finishedOn
                cancelledOn
                plannedOn
                archivedOn
                status
                statusTitle
                type
                typeTitle
            }
            hrOrganiserInfo {
                id
                personId
                finishedTest1
                finishedTest3
                testUrlPhase1
                testUrlPhase3
                createdOn
                lastModifiedOn
            }
        }
    }
`;

export const GET_PROVINCES = gql`
    query {
        provinces
    }
`;

export const DELETE_USER = gql`
    mutation deleteUser($input: DeleteUserInput!) {
        deleteUser(input: $input) {
            result
        }
    }
`;

export const GET_ROLES = gql`
    query getRoles {
        roles {
            roles {
                id
                name
                title
            }
        }
    }
`;

export const GET_CORPORATIONS = gql`
    query getCorporations {
        corporations {
            corporations {
                value: id
                label: name
            }
        }
    }
`;

export const GET_CITIES = gql`
    query getCities {
        cities {
            cities {
                id
                name
            }
        }
    }
`;

export const GET_REGIONS = gql`
    query getRegions {
        regions(pagination: { page: 0, pageSize: 30 }) {
            count
            regions {
                value: id
                label: description
            }
        }
    }
`;

export const USER_CSV = gql`
    query usersCsv($filter: UserFilterInput!) {
        usersCsv(filter: $filter) {
            base64Content
        }
    }
`;

export const MEETINGS_CSV = gql`
    query meetingsCsv($filter: MeetingFilterInput!) {
        meetingsCsv(filter: $filter) {
            base64Content
        }
    }
`;

export const OPEN_MEETINGS = gql`
    query meetings($filter: MeetingFilterInput!) {
        meetings(filter: $filter) {
            count
            meetings {
                id
                owner {
                    id
                    email
                    profile {
                        fullName
                    }
                }
                careerAdvisor {
                    id
                    email
                }
                code
                batch
                createdOn
                lastModifiedOn
                finishedOn
                cancelledOn
                plannedOn
                archivedOn
                status
                statusTitle
                type
                typeTitle
            }
        }
    }
`;
