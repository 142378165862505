import gql from 'graphql-tag';

export const GET_SKILLS = gql`
    query skills($pagination: PaginationInput) {
        skills(pagination: $pagination) {
            count
            skills {
                id
                description
            }
        }
    }
`;

export const GET_SKILL = gql`
    query skill($id: Long!) {
        skill(id: $id) {
            description
            id
        }
    }
`;

export const CREATE_SKILL = gql`
    mutation createSkill($input: SkillInput!) {
        createSkill(input: $input) {
            id
        }
    }
`;

export const UPDATE_SKILL = gql`
    mutation updateSkill($input: SkillInput!) {
        updateSkill(input: $input) {
            id
        }
    }
`;

export const DELETE_SKILL = gql`
    mutation deleteSkill($input: SkillInput!) {
        deleteSkill(input: $input)
    }
`;
