import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import React, { useEffect, useState } from 'react';
import * as firebase from 'firebase';
import { useHistory, useParams } from 'react-router-dom';
import { Grid, Paper, Typography } from '@material-ui/core';
import { Button, InputField, Switch } from 'shared';
import { useForm } from 'hooks';
import { LoaderModal } from 'shared';
import { useSnackbar } from 'notistack';
import { getFirebaseDataSnapshot } from '../FirebaseInstance';

const useStyles = makeStyles((theme) => ({
    root: {
        background: theme.palette.background.secondary,
        paddingBottom: `${theme.spacing(12)}px`
    },
    paper: {
        width: '100%',
        padding: theme.spacing(7, 8, 8, 7),
        borderRadius: '25px',

        '& .MuiGrid-grid-xs-6': {
            maxWidth: 'calc(50% - 50px)',
            flexBasis: 'calc(50% - 50px)',
            '& .MuiGrid-grid-xs-6': {
                maxWidth: 'calc(50% - 15px)',
                flexBasis: 'calc(50% - 15px)'
            }
        }
    },
    heading: {
        ...theme.typography.h5,
        fontWeight: 600,
        paddingBottom: theme.spacing(2),
        borderBottom: '1px solid ' + theme.palette.borderColor.main,
        marginBottom: theme.spacing(1),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    input: {
        width: '100%',
        marginLeft: 0,
        '& .MuiInputLabel-shrink': {
            transform: 'none',
            top: '-20px',
            lineHeight: '28px',
            fontWeight: '500',
            fontSize: '18px'
        }
    }
}));

const formSettings = {
    active: {},
    searchTerms: {},
    positiveKeywords: {},
    negativeKeywords: {},
    name: {}
};

const CrawlerSiteDetail = (props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const { id } = useParams();

    const [searchTermsEnabled, setSearchTermsEnabled] = useState(false);
    const [loading, setLoading] = useState(true);
    const [draft, setDraft] = useState({
        active: true,
        searchTerms: '',
        positiveKeywords: '',
        negativeKeywords: '',
        name: ''
    });

    const [form, submitForm] = useForm(formSettings);

    useEffect(() => {
        getFirebaseDataSnapshot(enqueueSnackbar, t, firebase, `sites/${id}`).then(
            (snapshot) => {
                const result = snapshot.val();
                setLoading(false);

                if (result !== null) {
                    setDraft(result);

                    if (result.searchTermsEnabled && result.searchTermsEnabled === true) {
                        setSearchTermsEnabled(true);
                    }
                }
            },
            () => {
                enqueueSnackbar({
                    message: t('crawler.failedMessages.site'),
                    variant: 'error'
                });
            }
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const handleSubmit = (e) => {
        e.preventDefault();
        submitForm().then((response) => {
            if (response) {
                firebase
                    .database()
                    .ref(`sites/${id}`)
                    .update(
                        {
                            active: response.active,
                            searchTerms: response.searchTerms,
                            positiveKeywords: response.positiveKeywords,
                            negativeKeywords: response.negativeKeywords,
                            name: response.name
                        },
                        (error) => {
                            if (error) {
                                enqueueSnackbar({
                                    message: t('crawler.config.saveFailed'),
                                    variant: 'error'
                                });
                            } else {
                                firebase
                                    .app()
                                    .delete()
                                    .then(() => {
                                        enqueueSnackbar({
                                            variant: 'success',
                                            message: t('crawler.config.saveSuccess')
                                        });
                                        history.goBack();
                                    });
                            }
                        }
                    );
            }
        });
    };

    return (
        <Grid container justify="center">
            <Grid item xs={6}>
                <Paper className={classes.paper} elevation={0}>
                    <Typography className={classes.heading}>
                        <span>{t('crawler.sites.editTitle')}</span>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={handleSubmit}
                            label={t('crawler.config.save')}
                        />
                    </Typography>
                    <Grid container justify="space-between">
                        <Grid item xs={12}>
                            <Switch
                                name="active"
                                form={form}
                                initialValue={draft.active}
                                label={t('crawler.sites.form.active')}
                                checked={draft.active}
                            />

                            <InputField
                                name="scrapeEndpoint"
                                className={classes.input}
                                initialValue={draft.scrapeEndpoint || ''}
                                type="text"
                                otherProps={{
                                    disabled: true
                                }}
                                label={t('crawler.sites.form.scrapeEndpoint')}
                                form={form}
                            />

                            <InputField
                                name="name"
                                className={classes.input}
                                initialValue={draft.name || ''}
                                type="text"
                                label={t('crawler.sites.form.name')}
                                form={form}
                            />

                            {searchTermsEnabled && (
                                <InputField
                                    name="searchTerms"
                                    className={classes.input}
                                    initialValue={draft.searchTerms || ''}
                                    type="text"
                                    label={t('crawler.sites.form.searchTerms')}
                                    form={form}
                                />
                            )}

                            <InputField
                                name="positiveKeywords"
                                className={classes.input}
                                initialValue={draft.positiveKeywords || ''}
                                type="text"
                                label={t('crawler.sites.form.positiveKeywords')}
                                form={form}
                            />

                            <InputField
                                name="negativeKeywords"
                                className={classes.input}
                                initialValue={draft.negativeKeywords || ''}
                                type="text"
                                label={t('crawler.sites.form.negativeKeywords')}
                                form={form}
                            />
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            <LoaderModal loading={loading} />
        </Grid>
    );
};

export default CrawlerSiteDetail;
