import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Add } from '@material-ui/icons';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { InputField, Button, Switch } from 'shared';
import { LoaderModal } from 'shared';
import { useForm } from 'hooks';

import { UPDATE_VACANCY_TYPE, GET_VACANCY_TYPE } from './queries';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: `${theme.spacing(10)}px auto`,
        padding: theme.spacing(0, 4),
        maxWidth: '800px'
    },
    paper: {
        width: '100%',
        padding: theme.spacing(7, 8, 8, 7),
        borderRadius: '25px',

        '& .MuiGrid-grid-xs-6': {
            maxWidth: 'calc(50% - 50px)',
            flexBasis: 'calc(50% - 50px)',
            '& .MuiGrid-grid-xs-6': {
                maxWidth: 'calc(50% - 15px)',
                flexBasis: 'calc(50% - 15px)'
            }
        }
    },
    title: {
        textAlign: 'center',
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(2)
    },
    label: {
        padding: theme.spacing(2.25, 2)
    },
    heading: {
        ...theme.typography.h5,
        fontWeight: 600,
        paddingBottom: theme.spacing(2),
        borderBottom: '1px solid ' + theme.palette.borderColor.main,
        marginBottom: theme.spacing(1),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    input: {
        width: '100%',
        marginLeft: 0,
        '& .MuiInputLabel-shrink': {
            transform: 'none',
            top: '-20px',
            lineHeight: '28px',
            fontWeight: '500',
            fontSize: '18px'
        }
    },
    bottom: {
        marginTop: theme.spacing(2)
    },
    button: {
        marginRight: theme.spacing(2)
    },
    description: {
        display: 'inline-block',
        margin: theme.spacing(0, 0, 2, 9.625),
        color: theme.palette.secondary.contrastText,
        opacity: '0.6'
    },
    loaderPopup: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        background: theme.palette.common.white,
        padding: theme.spacing(4, 5),
        borderRadius: '20px',
        zIndex: theme.zIndex.modal
    }
}));

const EditVacancyType = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const params = useParams();
    const { id } = params;
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const [form, submitForm] = useForm();
    const [update, { loading: mutationLoading, data: updateData }] = useMutation(UPDATE_VACANCY_TYPE);

    const { data = {} } = useQuery(GET_VACANCY_TYPE, {
        variables: {
            id: id
        }
    });

    const [draft, setDraft] = useState({
        id: '',
        description: ''
    });

    useEffect(() => {
        if (updateData) {
            const id = updateData.updateVacancyType.id;
            if (id) {
                enqueueSnackbar({
                    variant: 'success',
                    message: t('general.saved')
                });
                history.push(`/vacancy-types`);
            } else {
                enqueueSnackbar({
                    variant: 'warning',
                    message: t('general.saveError')
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateData]);

    useEffect(() => {
        if (data && data.vacancyType) {
            setDraft(data.vacancyType);
        }
    }, [data]);

    const handleCancel = () => {
        history.goBack();
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        submitForm().then((resp) => {
            if (resp) {
                update({
                    variables: {
                        input: {
                            id: id,
                            ...resp
                        }
                    }
                })
                    .then((result) => {
                        if (result.errors) {
                            enqueueSnackbar({
                                variant: 'warning',
                                message: t('general.saveError')
                            });
                        }
                    })
                    .catch(() => {
                        enqueueSnackbar({
                            variant: 'warning',
                            message: t('general.saveError')
                        });
                    });
            }
        });
    };

    return (
        <Grid container justify="center" className={classes.root}>
            <Paper className={classes.paper} elevation={0}>
                <Typography className={classes.heading}>
                    <span>{t('editVacancyType.title')}</span>
                </Typography>

                <Grid container justify="space-between">
                    <Grid item xs={12}>
                        <InputField
                            name="description"
                            className={classes.input}
                            initialValue={draft.description || ''}
                            type="text"
                            label={t('general.description')}
                            form={form}
                        />
                        <InputField
                            name="explanation"
                            className={classes.input}
                            initialValue={draft.explanation || ''}
                            type="text"
                            label={t('general.explanation')}
                            form={form}
                        />
                        <Switch
                            label={t('general.isAvailable')}
                            name="isAvailableAsSpecialty"
                            form={form}
                            initialValue={draft.isAvailableAsSpecialty || false}
                            loading={!draft.id}
                        />
                        <Typography className={classes.description}>{t('general.isAvailableExplenation')}</Typography>
                    </Grid>
                    <Grid item xs={12} className={classes.bottom}>
                        <Button
                            variant="contained"
                            className={classes.button}
                            color="primary"
                            label={t('general.cancel')}
                            onClick={handleCancel}
                        />

                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={handleSubmit}
                            label={t('general.button')}
                            iconLeft={() => <Add />}
                        />
                    </Grid>
                </Grid>
            </Paper>
            <LoaderModal loading={mutationLoading} />
        </Grid>
    );
};

export default EditVacancyType;
