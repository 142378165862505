import gql from 'graphql-tag';

export const GET_EDUCATION_GRADES = gql`
    query educationGrades($pagination: PaginationInput) {
        educationGrades(pagination: $pagination) {
            count
            educationGrades {
                id
                description
            }
        }
    }
`;

export const GET_EDUCATION_GRADE = gql`
    query educationGrade($id: Long!) {
        educationGrade(id: $id) {
            description
            sortOrder
            id
        }
    }
`;

export const CREATE_EDUCATION_GRADE = gql`
    mutation createEducationGrade($input: EducationGradeInput!) {
        createEducationGrade(input: $input) {
            id
        }
    }
`;

export const UPDATE_EDUCATION_GRADE = gql`
    mutation updateEducationGrade($input: EducationGradeInput!) {
        updateEducationGrade(input: $input) {
            id
        }
    }
`;

export const DELETE_EDUCATION_GRADE = gql`
    mutation deleteEducationGrade($input: EducationGradeInput!) {
        deleteEducationGrade(input: $input)
    }
`;
