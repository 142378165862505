import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { MobileNotify, Footer } from 'shared';

import MainMenu from 'shared/MainMenu';

import { AddSkills, EditSkills, RemoveSkills, Skills } from 'components/Skills';
import { AddWorkingHours, EditWorkingHours, RemoveWorkingHours, WorkingHours } from 'components/WorkingHours';
import { Crawler, CrawlerVacancyDetail, CrawlerSiteDetail } from 'components/Crawler';
import { AddUser, RemoveUser, Users } from 'components/Users';
import AppHeader from 'components/AppHeader';
import Dashboard from 'components/Dashboard';
import EditUser from 'components/EditUser';
import Login from 'components/Login';
import PrivateRoute from 'components/PrivateRoute';
import Vouchers from 'components/Vouchers';
import Unauthorized from 'components/Unauthorized';
import { AddSalaryScale, EditSalaryScale, RemoveSalaryScale, SalaryScales } from 'components/SalaryScales';
import { EditDuration, AddDuration, RemoveDuration, Durations } from 'components/Durations';
import {
    EditEducationGrade,
    AddEducationGrade,
    RemoveEducationGrade,
    EducationGrades
} from 'components/EducationGrades';
import { AddRegion, EditRegion, RemoveRegion, Regions } from 'components/Regions';
import { AddFolder, EditFolder, RemoveFolder, Folders } from 'components/Folders';
import { EditVacancyType, AddVacancyType, RemoveVacancyType, VacancyTypes } from 'components/VacancyTypes';
import { AddCorporation, CorporationDetail, RemoveCorporation, Corporations } from 'components/Corporations';
import { AddExpertise, EditExpertise, RemoveExpertise, Expertises } from 'components/Expertises';
import { Meetings, RemoveMeeting } from 'components/Meetings';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex'
    },
    appBarSpacer: {
        ...theme.mixins.toolbar,
        height: 80
    },
    main: {
        minHeight: 'calc(100vh - 180px)',
        width: '100%'
    },
    container: {
        padding: theme.spacing(2),
        maxWidth: 1024,
        margin: `${theme.spacing(2)}px auto 0`
    }
}));

const App = (props) => {
    const classes = useStyles();
    const [open, setOpen] = useState(true);
    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const handleDrawerClose = () => {
        setOpen(false);
    };

    return (
        <Router>
            <div className={classes.root}>
                <AppHeader open={open} openDrawer={handleDrawerOpen} />
                <MainMenu open={open} closeDrawer={handleDrawerClose} />
                <main className={classes.main}>
                    <div className={classes.appBarSpacer} />
                    <div className={classes.container}>
                        <Switch>
                            <Route path="/unauthorized" component={Unauthorized} />
                            <PrivateRoute path="/vouchers" exact component={Vouchers} />
                            {/* folders routes */}
                            <PrivateRoute exact path="/folders" component={Folders} />
                            <PrivateRoute path="/folders/add" component={AddFolder} />
                            <PrivateRoute path="/folders/edit/:id" component={EditFolder} />
                            <PrivateRoute path="/folders/remove/:id" component={RemoveFolder} />
                            {/* users routes */}
                            <PrivateRoute exact path="/users" component={Users} />
                            <PrivateRoute path="/users/add" exact component={AddUser} />
                            <PrivateRoute path="/users/edit/:id" component={EditUser} />
                            <PrivateRoute path="/users/remove/:id" component={RemoveUser} />
                            {/* conversation routes */}
                            <PrivateRoute exact path="/meetings" component={Meetings} />
                            <PrivateRoute exact path="/meetings/remove/:ownerId/:id" component={RemoveMeeting} />
                            {/* regions routes */}
                            <PrivateRoute exact path="/regions" component={Regions} />
                            <PrivateRoute path="/regions/add" exact component={AddRegion} />
                            <PrivateRoute path="/regions/edit/:id" component={EditRegion} />
                            <PrivateRoute path="/regions/remove/:id" component={RemoveRegion} />
                            {/* corporations routes */}
                            <PrivateRoute exact path="/corporations" component={Corporations} />
                            <PrivateRoute path="/corporations/add" exact component={AddCorporation} />
                            <PrivateRoute path="/corporations/edit/:id" component={CorporationDetail} />
                            <PrivateRoute path="/corporations/remove/:id" exact component={RemoveCorporation} />
                            {/* crawler routes */}
                            <PrivateRoute path="/crawler" exact component={Crawler} />
                            <PrivateRoute path="/crawler/vacancy/:id" exact component={CrawlerVacancyDetail} />
                            <PrivateRoute path="/crawler/site/:id" exact component={CrawlerSiteDetail} />
                            {/* hours routes */}
                            <PrivateRoute exact path="/hours" component={WorkingHours} />
                            <PrivateRoute path="/hours/add" superRoute exact component={AddWorkingHours} />
                            <PrivateRoute path="/hours/edit/:id" superRoute component={EditWorkingHours} />
                            <PrivateRoute path="/hours/remove/:id" superRoute component={RemoveWorkingHours} />
                            {/* skills routes */}
                            <PrivateRoute exact path="/skills" component={Skills} />
                            <PrivateRoute path="/skills/add" superRoute exact component={AddSkills} />
                            <PrivateRoute path="/skills/edit/:id" superRoute component={EditSkills} />
                            <PrivateRoute path="/skills/remove/:id" superRoute component={RemoveSkills} />
                            {/* salary routes */}
                            <PrivateRoute exact path="/salary-scales" component={SalaryScales} />
                            <PrivateRoute path="/salary-scales/add" superRoute exact component={AddSalaryScale} />
                            <PrivateRoute path="/salary-scales/edit/:id" superRoute component={EditSalaryScale} />
                            <PrivateRoute path="/salary-scales/remove/:id" superRoute component={RemoveSalaryScale} />
                            {/* vacancy routes */}
                            <PrivateRoute exact path="/vacancy-types" component={VacancyTypes} />
                            <PrivateRoute path="/vacancy-types/add" superRoute exact component={AddVacancyType} />
                            <PrivateRoute path="/vacancy-types/edit/:id" superRoute component={EditVacancyType} />
                            <PrivateRoute path="/vacancy-types/remove/:id" superRoute component={RemoveVacancyType} />
                            {/* duration routes */}
                            <PrivateRoute exact path="/durations" component={Durations} />
                            <PrivateRoute path="/durations/add" superRoute exact component={AddDuration} />
                            <PrivateRoute path="/durations/edit/:id" superRoute exact component={EditDuration} />
                            <PrivateRoute path="/durations/remove/:id" superRoute component={RemoveDuration} />
                            {/* education routes */}
                            <PrivateRoute exact path="/education-grades" component={EducationGrades} />
                            <PrivateRoute path="/education-grades/add" superRoute exact component={AddEducationGrade} />
                            <PrivateRoute
                                path="/education-grades/edit/:id"
                                superRoute
                                exact
                                component={EditEducationGrade}
                            />
                            <PrivateRoute
                                path="/education-grades/remove/:id"
                                superRoute
                                component={RemoveEducationGrade}
                            />
                            {/* expertises routes */}
                            <PrivateRoute exact path="/expertises" component={Expertises} />
                            <PrivateRoute path="/expertises/add" superRoute exact component={AddExpertise} />
                            <PrivateRoute path="/expertises/edit/:id" superRoute component={EditExpertise} />
                            <PrivateRoute path="/expertises/remove/:id" superRoute component={RemoveExpertise} />
                            <Route path="/login" component={() => <Login />} />
                            <Route path="/" exact component={Dashboard} />

                            {/* <PrivateRoute path="/:option" component={Data} /> */}
                        </Switch>
                    </div>
                    <MobileNotify />
                    <Footer />
                </main>
            </div>
        </Router>
    );
};

export default App;
