import React, { useEffect, useRef, useState } from 'react';
import { DeleteDialog, Table } from 'shared';
import { Grid, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import { FilterOverview } from 'shared';
import { useFilter } from 'hooks';
import CrawlerVacancyRow from './CrawlerVacancyRow';
import * as firebase from 'firebase/app';
import 'firebase/database';
import { getFirebaseDataSnapshot } from '../FirebaseInstance';
import { useSnackbar } from 'notistack';
import DuplicateVacancyDialog from './DuplicateVacancyDialog';
import { isUndefinedOrNull } from '../../../helpers';

const useStyles = makeStyles((theme) => ({
    root: {},
    number: {
        fontWeight: 300,
        color: theme.palette.text.secondaryLight,
        fontSize: '24px',
        marginLeft: `${theme.spacing(1)}px`
    },
    item: {
        marginTop: `${theme.spacing(8)}px`
    },
    title: {
        display: 'flex',
        alignItems: 'center'
    },
    icon: {
        marginRight: `${theme.spacing(2)}px`,
        color: theme.palette.primary.main,
        padding: '2px'
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: theme.spacing(0, 4),
        marginBottom: theme.spacing(2)
    }
}));

const CrawlerVacancies = () => {
    const mounted = useRef();
    const { t } = useTranslation();
    const classes = useStyles();
    const history = useHistory();
    const query = useFilter();
    const { enqueueSnackbar } = useSnackbar();
    const [vacancies, setVacancies] = useState([]);
    const [allVacancies, setAllVacancies] = useState([]);
    const [sites, setSites] = useState({});
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [duplicateVacancyDialogOpen, setDuplicateVacancyDialogOpen] = useState(false);
    const [duplicateVacancy, setDuplicateVacancy] = useState(undefined);
    const [currentVacancyId, setCurrentVacancyId] = useState(undefined);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    }, []);

    const getVacancies = () => {
        getFirebaseDataSnapshot(enqueueSnackbar, t, firebase, 'offers').then(
            (snapshot) => {
                const result = snapshot.val();
                if (mounted.current) setLoading(false);

                if (result !== null) {
                    // The data arrives in the following format: {vacancy1:{}, vacancy2:{}}, which
                    // will be mapped to a plain old array to be used for the table
                    const data = Object.keys(result)
                        .map((vacancyKey) => ({
                            id: vacancyKey,
                            ...result[vacancyKey]
                        }))
                        .flat();

                    if (mounted.current) {
                        setAllVacancies(data);
                        setVacancies(
                            data
                                .filter((vacancy) => vacancy.deleted !== true)
                                .filter(
                                    (vacancy) =>
                                        isUndefinedOrNull(vacancy.duplicateTitleAndCompany) ||
                                        vacancy.duplicateTitleAndCompany === false
                                )
                                .filter(
                                    (vacancy) =>
                                        isUndefinedOrNull(vacancy.duplicatePercentage) ||
                                        vacancy.duplicatePercentage < 0.5
                                )
                                .filter(
                                    (vacancy) =>
                                        isUndefinedOrNull(vacancy.duplicatePercentagePortal) ||
                                        vacancy.duplicatePercentagePortal < 0.5
                                )
                                .filter((vacancy) => vacancy.deploymentStatus === undefined)
                                .sort((a, b) => (a.crawledAt > b.crawledAt ? -1 : 1))
                                .sort((a, b) => (a.completed === b.completed ? 0 : a.completed ? 1 : -1))
                        );
                    }
                }
            },
            () => {
                enqueueSnackbar({
                    message: t('crawler.failedMessages.crawledVacancies'),
                    variant: 'error'
                });
            }
        );
    };

    const getSites = () => {
        getFirebaseDataSnapshot(enqueueSnackbar, t, firebase, 'sites').then(
            (snapshot) => {
                const result = snapshot.val();
                if (result !== null && mounted.current) setSites(result);
            },
            () => {
                enqueueSnackbar({
                    message: t('crawler.failedMessages.sites'),
                    variant: 'error'
                });
            }
        );
    };

    useEffect(() => {
        getVacancies();
        getSites();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleRowClick = (id, openInNewTab = false) => {
        const url = `/crawler/vacancy/${id}`;
        if (openInNewTab) window.open(url, '_blank');
        else history.push(url);
    };

    const handleRowDelete = (id) => {
        setDeleteDialogOpen(true);
        setCurrentVacancyId(id);
    };

    const handleDuplicateClick = (id) => {
        const vacancy = allVacancies.find((vacancy) => vacancy.id === id);
        if (vacancy !== undefined) {
            setDuplicateVacancy(vacancy);
            setDuplicateVacancyDialogOpen(true);
        }
    };

    const deleteVacancy = () => {
        firebase
            .database()
            .ref(`offers/${currentVacancyId}`)
            .update({ deleted: true }, (error) => {
                if (error) {
                    enqueueSnackbar({
                        message: t('crawler.vacancies.deleteFailed'),
                        variant: 'error'
                    });
                } else {
                    enqueueSnackbar({
                        variant: 'success',
                        message: t('crawler.vacancies.deleteSuccess')
                    });
                    getVacancies();
                }
            });
    };

    const page = parseInt(query.pagination.page);
    const pageSize = parseInt(query.pagination.pageSize);

    return (
        <Grid container className={classes.root}>
            <Grid item className={classes.header} xs={12}>
                <Typography variant="h2">{t('crawler.title')}</Typography>
            </Grid>
            <Grid item xs={12}>
                <FilterOverview query={query} loading={loading} useResults={false} count={vacancies.length || 0}>
                    {vacancies && (
                        <Table
                            head={[
                                t('crawler.vacancies.table.company'),
                                t('crawler.vacancies.table.site'),
                                t('crawler.vacancies.table.title'),
                                t('crawler.vacancies.table.crawlDate'),
                                t('crawler.vacancies.table.completed'),
                                ''
                            ]}
                            renderRows={() =>
                                vacancies
                                    .slice(page * pageSize, page * pageSize + pageSize)
                                    .map((vacancy, i) => (
                                        <CrawlerVacancyRow
                                            key={i}
                                            site={sites[vacancy.siteId]}
                                            vacancy={vacancy}
                                            onClick={handleRowClick}
                                            onDelete={handleRowDelete}
                                            onDuplicateClick={handleDuplicateClick}
                                        />
                                    ))
                            }
                        />
                    )}
                </FilterOverview>
            </Grid>

            <DeleteDialog
                open={deleteDialogOpen}
                title={t('crawler.vacancies.deleteDialog.title')}
                description={t('crawler.vacancies.deleteDialog.description')}
                positiveText={t('crawler.vacancies.deleteDialog.positiveText')}
                negativeText={t('crawler.vacancies.deleteDialog.negativeText')}
                onClose={() => setDeleteDialogOpen(false)}
                onPositive={deleteVacancy}
            />

            <DuplicateVacancyDialog
                vacancies={allVacancies}
                vacancy={duplicateVacancy}
                onDeleteVacancy={handleRowDelete}
                onVisitVacancy={(id) => handleRowClick(id, true)}
                sites={sites}
                open={duplicateVacancyDialogOpen}
                onClose={() => setDuplicateVacancyDialogOpen(false)}
            />
        </Grid>
    );
};

export default CrawlerVacancies;
