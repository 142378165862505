import React from 'react';
import { withStyles } from '@material-ui/styles';
import { makeStyles, Typography } from '@material-ui/core';
import { DeleteOutline, ArrowRightAlt } from '@material-ui/icons';
import { TableCell, TableRow } from 'shared';

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.background
    },
    root: {
        padding: `${theme.spacing(1.5)}px ${theme.spacing(2)}px`,
        lineHeight: 0,
        '&:first-child': {
            paddingLeft: 0
        },
        '&:last-child': {
            paddingRight: 0
        }
    }
}))(TableCell);

const useStyles = makeStyles((theme) => ({
    center: {
        display: 'inline-flex',
        alignItems: 'center',
        color: theme.palette.text.secondaryLight
    },
    title: {
        color: theme.palette.text.secondary
    },
    time: {
        marginRight: theme.spacing(1)
    },

    actionButton: {
        border: '1px solid ' + theme.palette.text.secondaryLight,
        borderRadius: '50%',
        color: theme.palette.text.secondary,
        background: theme.palette.common.white,
        width: '50px',
        height: '50px',
        padding: '10px',
        marginLeft: '20px',
        cursor: 'pointer',
        transition: 'all 300ms ease',
        '&:hover': {
            border: '1px solid ' + theme.palette.text.secondary,
            background: theme.palette.text.secondary,
            color: theme.palette.common.white
        }
    }
}));

const Row = (props) => {
    const {
        data: { id, description },
        onClick,
        onDelete
    } = props;
    const classes = useStyles();
    return (
        <TableRow className={classes.row}>
            <StyledTableCell className={classes.nameCell}>
                <Typography className={classes.center}>{id}</Typography>
            </StyledTableCell>
            <StyledTableCell>
                <Typography className={classes.title}>{description}</Typography>
            </StyledTableCell>
            <StyledTableCell align="right">
                <div className={classes.center}>
                    <DeleteOutline onClick={() => onDelete(id)} className={classes.actionButton} />
                    <ArrowRightAlt onClick={() => onClick(id)} className={classes.actionButton} />
                </div>
            </StyledTableCell>
        </TableRow>
    );
};

export default Row;
