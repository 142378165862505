import React from 'react';
import { makeStyles, Tooltip as MTooltip, Typography } from '@material-ui/core';
import { TableCell, TableRow } from 'shared';
import { ArrowRightAlt, CreateRounded, DeleteOutline, WarningRounded } from '@material-ui/icons';
import { dateToString } from '../../../helpers';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

export const DeploymentStatus = {
    PLACED: 'PLACED',
    REMOVED: 'REMOVED'
};

const useStyles = makeStyles((theme) => ({
    center: {
        display: 'inline-flex',
        alignItems: 'center',
        color: theme.palette.text.secondaryLight
    },
    title: {
        color: theme.palette.text.secondary
    },
    actionButton: {
        border: '1px solid ' + theme.palette.text.secondaryLight,
        borderRadius: '50%',
        color: theme.palette.text.secondary,
        background: theme.palette.common.white,
        width: '50px',
        height: '50px',
        padding: theme.spacing(1.25),
        marginLeft: theme.spacing(2.5),
        cursor: 'pointer',
        transition: 'all 300ms ease',
        '&:hover': {
            border: '1px solid ' + theme.palette.text.secondary,
            background: theme.palette.text.secondary,
            color: theme.palette.common.white
        }
    },
    actionButtonNoAction: {
        custor: 'default'
    },
    actionButtonExtended: {
        cursor: 'default',
        textAlign: 'center',
        minWidth: 100,
        borderRadius: 35,
        width: 'auto'
    },
    actionButtonExtendedText: {
        verticalAlign: 'top',
        paddingLeft: 8
    },
    tooltip: {
        fontSize: '14px',
        fontWeight: 400,
        color: theme.palette.primary.main,
        background: theme.palette.primary.light
    }
}));

const CrawlerVacancyRow = (props) => {
    const {
        vacancy: {
            id,
            company,
            title,
            crawledAt,
            completed,
            duplicatePercentage,
            duplicatePercentagePortal,
            deploymentStatus,
            deploymentOn
        } = {},
        portalVacancy,
        onClick,
        onDelete,
        onEdit,
        onDuplicateClick,
        site,
        showDuplicatePercentage = true,
        isDuplicateRow = false,
        isDuplicatePortalRow = false,
        isPlacedVacancy = false
    } = props;
    const { t } = useTranslation();

    const checkMiddleMouseButton = (event) => {
        if (event.button === 1) {
            onClick(id, true);
        }
    };

    const classes = useStyles();

    let crawledOrPlacedDate;
    let status;
    if (deploymentStatus === DeploymentStatus.PLACED) {
        status = t('crawler.placedVacancies.table.status.placed');
        crawledOrPlacedDate = new Date(deploymentOn);
    } else if (deploymentStatus === DeploymentStatus.REMOVED) {
        status = t('crawler.placedVacancies.table.status.removed');
        crawledOrPlacedDate = new Date(deploymentOn);
    } else {
        status = completed ? t('crawler.vacancies.table.status.accepted') : t('crawler.vacancies.table.status.new');
        crawledOrPlacedDate = new Date(crawledAt * 1000);
    }

    if (isDuplicateRow) {
        return (
            <TableRow className={classes.row}>
                <TableCell className={classes.nameCell}>
                    <Typography className={classes.center}>{company || '-'}</Typography>
                </TableCell>
                <TableCell className={classes.nameCell}>
                    <Typography className={classes.title}>{title}</Typography>
                </TableCell>
                <TableCell className={classes.nameCell}>
                    <Typography className={classes.title}>
                        {dateToString(new Date(crawledAt * 1000), { disabled: { time: true } })}
                    </Typography>
                </TableCell>
                {showDuplicatePercentage && (
                    <TableCell className={classes.nameCell}>
                        <Typography className={classes.title}>{`${parseInt(duplicatePercentage * 100)}%`}</Typography>
                    </TableCell>
                )}
                <TableCell align="right">
                    <div className={classes.center}>
                        {!isPlacedVacancy && (
                            <>
                                <DeleteOutline onClick={() => onDelete(id)} className={classes.actionButton} />
                                {!completed && (
                                    <ArrowRightAlt
                                        onClick={() => onClick(id)}
                                        onMouseDown={checkMiddleMouseButton}
                                        className={classes.actionButton}
                                    />
                                )}
                            </>
                        )}
                    </div>
                </TableCell>
            </TableRow>
        );
    } else if (isDuplicatePortalRow) {
        return (
            <TableRow className={classes.row}>
                <TableCell className={classes.nameCell}>
                    <Typography className={classes.center}>{portalVacancy.company || '-'}</Typography>
                </TableCell>
                <TableCell className={classes.nameCell}>
                    <Typography className={classes.title}>{portalVacancy.title}</Typography>
                </TableCell>
                <TableCell className={classes.nameCell}>
                    <Typography className={classes.title}>
                        {dateToString(new Date(portalVacancy.createdOn), { disabled: { time: true } })}
                    </Typography>
                </TableCell>
                {showDuplicatePercentage && (
                    <TableCell className={classes.nameCell}>
                        <Typography className={classes.title}>
                            {`${parseInt(portalVacancy.percentage * 100)}%`}
                        </Typography>
                    </TableCell>
                )}
                <TableCell align="right">
                    <div className={classes.center}>
                        <ArrowRightAlt
                            onClick={() => onClick(id)}
                            onMouseDown={checkMiddleMouseButton}
                            className={classes.actionButton}
                        />
                    </div>
                </TableCell>
            </TableRow>
        );
    } else {
        return (
            <TableRow className={classes.row}>
                <TableCell className={classes.nameCell}>
                    <Typography className={classes.center}>{company || '-'}</Typography>
                </TableCell>
                <TableCell className={classes.nameCell}>
                    <Typography className={classes.center}>{(site && site.name) || '-'}</Typography>
                </TableCell>
                <TableCell className={classes.nameCell}>
                    <Typography className={classes.title}>{title}</Typography>
                </TableCell>
                <TableCell className={classes.nameCell}>
                    <Typography className={classes.title}>
                        {dateToString(new Date(crawledOrPlacedDate), { hours: true, minutes: true, seconds: false })}
                    </Typography>
                </TableCell>
                <TableCell className={classes.nameCell}>
                    <Typography className={classes.title}>{status}</Typography>
                </TableCell>
                <TableCell align="right">
                    <div className={classes.center}>
                        {/* Only portal duplicates, no crawled duplicates */}
                        {duplicatePercentage < 0.5 && duplicatePercentagePortal >= 0.5 && !isPlacedVacancy && (
                            <MTooltip
                                title={t('crawler.vacancies.duplication.alert', {
                                    percentage: parseInt(duplicatePercentagePortal * 100)
                                })}
                                disableFocusListener
                                classes={{ tooltip: classes.tooltip }}
                            >
                                <WarningRounded
                                    className={classNames(classes.actionButton, classes.actionButtonNoAction)}
                                    onClick={() => onDuplicateClick(id)}
                                />
                            </MTooltip>
                        )}

                        {/* Crawled duplicates and possibly also portal duplicates */}
                        {duplicatePercentage >= 0.5 && !isPlacedVacancy && (
                            <MTooltip
                                title={t('crawler.vacancies.duplication.alert', {
                                    percentage: parseInt(duplicatePercentage * 100)
                                })}
                                disableFocusListener
                                classes={{ tooltip: classes.tooltip }}
                            >
                                <WarningRounded
                                    className={classNames(classes.actionButton, classes.actionButtonNoAction)}
                                    onClick={() => onDuplicateClick(id)}
                                />
                            </MTooltip>
                        )}

                        {isPlacedVacancy && (
                            <>
                                {deploymentStatus === DeploymentStatus.PLACED && (
                                    <>
                                        <DeleteOutline onClick={() => onDelete(id)} className={classes.actionButton} />
                                        <CreateRounded onClick={() => onEdit(id)} className={classes.actionButton} />
                                        <ArrowRightAlt
                                            onClick={() => onClick(id)}
                                            onMouseDown={checkMiddleMouseButton}
                                            className={classes.actionButton}
                                        />
                                    </>
                                )}
                            </>
                        )}
                        {!isPlacedVacancy && (
                            <>
                                <DeleteOutline onClick={() => onDelete(id)} className={classes.actionButton} />
                                {!completed && (
                                    <ArrowRightAlt
                                        onClick={() => onClick(id)}
                                        onMouseDown={checkMiddleMouseButton}
                                        className={classes.actionButton}
                                    />
                                )}
                            </>
                        )}
                    </div>
                </TableCell>
            </TableRow>
        );
    }
};

export default CrawlerVacancyRow;
