import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useMutation, useQuery } from '@apollo/react-hooks';

import { Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { InputField, Button, Switch, CheckboxGroup } from 'shared';
import { LoaderModal } from 'shared';
import { useForm } from 'hooks';

import { mapToSelect } from 'helpers';
import { CREATE_USER, GET_ROLES } from './queries';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: `${theme.spacing(10)}px auto`,
        padding: theme.spacing(0, 4),
        maxWidth: '800px'
    },
    paper: {
        width: '100%',
        padding: theme.spacing(7, 8, 8, 7),
        borderRadius: '25px',

        '& .MuiGrid-grid-xs-6': {
            maxWidth: 'calc(50% - 50px)',
            flexBasis: 'calc(50% - 50px)',
            '& .MuiGrid-grid-xs-6': {
                maxWidth: 'calc(50% - 15px)',
                flexBasis: 'calc(50% - 15px)'
            }
        }
    },
    title: {
        textAlign: 'center',
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(2)
    },
    label: {
        padding: theme.spacing(2.25, 2)
    },
    heading: {
        ...theme.typography.h5,
        fontWeight: 600,
        paddingBottom: theme.spacing(2),
        borderBottom: '1px solid ' + theme.palette.borderColor.main,
        marginBottom: theme.spacing(1),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    subHeading: {
        ...theme.typography.h6,
        fontWeight: 500,
        paddingBottom: theme.spacing(2),
        borderBottom: '1px solid ' + theme.palette.borderColor.main,
        margin: theme.spacing(3, 0, 1, 0),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    input: {
        width: '100%',
        marginLeft: 0,
        '& .MuiInputLabel-shrink': {
            transform: 'none',
            top: '-20px',
            lineHeight: '28px',
            fontWeight: '500',
            fontSize: '18px'
        }
    },
    switchWrapper: {
        display: 'flex',
        flexFlow: 'column nowrap',
        margin: `0 -${theme.spacing(1)}px`
    },
    switch: {
        width: '50%',
        margin: theme.spacing(1)
    },
    bottom: {
        marginTop: theme.spacing(2)
    },
    button: {
        marginRight: theme.spacing(2)
    },
    loaderPopup: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        background: theme.palette.common.white,
        padding: theme.spacing(4, 5),
        borderRadius: '20px',
        zIndex: theme.zIndex.modal
    }
}));

const formSettings = {
    email: {
        required: true
    },
    password: {
        required: false
    },
    roles: {
        required: true
    },
    firstName: {
        required: true
    },
    lastName: {
        required: true
    },
    middleName: {
        required: false
    },
    sendInviteMail: {
        required: false
    },
    sendVerifyMail: {
        required: false
    },
    accountEnabled: {
        required: false
    }
};

const AddUser = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const [form, submitForm] = useForm(formSettings);
    const [createUser, { loading: mutationLoading, data }] = useMutation(CREATE_USER);
    const { data: rolesData = {} } = useQuery(GET_ROLES);

    const roles = rolesData && rolesData.roles ? rolesData.roles.roles : [];

    useEffect(() => {
        if (data) {
            const id = data.createUser.user.id;
            if (id) {
                enqueueSnackbar({
                    variant: 'success',
                    message: t('userAdd.saved')
                });
                history.push(`/users`);
            } else {
                enqueueSnackbar({
                    variant: 'warning',
                    message: t('userAdd.saveError')
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const handleCancel = () => {
        history.goBack();
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        submitForm().then((resp) => {
            if (resp) {
                createUser({
                    variables: {
                        input: {
                            email: resp.email,
                            password: resp.password,
                            roles: resp.roles,
                            profile: {
                                firstName: resp.firstName,
                                lastName: resp.lastName,
                                middleName: resp.middleName
                            },
                            sendInviteMail: resp.sendInviteMail,
                            sendVerifyMail: resp.sendVerifyMail,
                            accountEnabled: resp.accountEnabled
                        }
                    }
                })
                    .then((result) => {
                        if (result.errors) {
                            enqueueSnackbar({
                                variant: 'warning',
                                message: t('userAdd.saveError')
                            });
                        }
                    })
                    .catch((error) => {
                        const errData = JSON.stringify(error);
                        const parsedErrors = JSON.parse(errData);

                        if (parsedErrors && parsedErrors.networkError && parsedErrors.networkError.result) {
                            enqueueSnackbar({
                                variant: 'warning',
                                message: t(parsedErrors.networkError.result.message)
                            });
                        } else {
                            enqueueSnackbar({
                                variant: 'warning',
                                message: t('userAdd.saveError')
                            });
                        }
                    });
            }
        });
    };

    return (
        <Grid container justify="center" className={classes.root}>
            <Paper className={classes.paper} elevation={0}>
                <Typography className={classes.heading}>
                    <span>{t('userAdd.title')}</span>
                </Typography>

                <Grid container justify="space-between">
                    <Grid item xs={12}>
                        <InputField
                            name="email"
                            className={classes.input}
                            initialValue={''}
                            type="text"
                            label={t('userAdd.email')}
                            form={form}
                        />

                        <InputField
                            name="password"
                            className={classes.input}
                            initialValue={''}
                            type="password"
                            label={t('userAdd.password')}
                            form={form}
                        />

                        <Typography variant="subtitle1">{t('userEdit.roles')}</Typography>

                        <CheckboxGroup
                            name="roles"
                            options={mapToSelect(roles, 'title', 'name')}
                            initialValue={''}
                            helper={false}
                            form={form}
                        />

                        <Typography className={classes.subHeading}>
                            <span>{t('userAdd.personalInfo')}</span>
                        </Typography>
                        <InputField
                            name="firstName"
                            className={classes.input}
                            initialValue={''}
                            type="text"
                            label="Voornaam"
                            error={false}
                            form={form}
                            errorMessage={t('loginForm.errorEmail')}
                        />
                        <InputField
                            name="lastName"
                            className={classes.input}
                            initialValue={''}
                            type="text"
                            label="Achternaam"
                            error={false}
                            form={form}
                            errorMessage={t('loginForm.errorEmail')}
                        />
                        <InputField
                            name="middleName"
                            className={classes.input}
                            initialValue={''}
                            type="text"
                            label="Tussenvoegsel"
                            error={false}
                            form={form}
                            errorMessage={t('loginForm.errorEmail')}
                        />

                        <Typography className={classes.subHeading}>
                            <span>{t('userAdd.accountSettings')}</span>
                        </Typography>

                        <div className={classes.switchWrapper}>
                            <Switch
                                name="sendInviteMail"
                                className={classes.switch}
                                label={t('userAdd.sendInviteMail')}
                                form={form}
                            />
                            {/* <Switch
                                name="sendVerifyMail"
                                className={classes.switch}
                                label={t('userAdd.sendVerifyMail')}
                                form={form}
                            /> */}
                            <Switch
                                name="accountEnabled"
                                className={classes.switch}
                                label={t('userAdd.accountEnabled')}
                                form={form}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} className={classes.bottom}>
                        <Button
                            variant="contained"
                            className={classes.button}
                            color="primary"
                            label={t('general.cancel')}
                            onClick={handleCancel}
                        />

                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={handleSubmit}
                            label={t('userAdd.button')}
                        />
                    </Grid>
                </Grid>
            </Paper>
            <LoaderModal loading={mutationLoading} />
        </Grid>
    );
};

export default AddUser;
