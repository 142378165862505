import React, { useEffect, useState } from 'react';
import { Button, SelectField } from 'shared';
import { Grid, Paper, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import { LoaderModal } from 'shared';
import { useForm } from 'hooks';
import * as firebase from 'firebase/app';
import 'firebase/database';
import { useSnackbar } from 'notistack';
import { getFirebaseDataSnapshot } from '../FirebaseInstance';

const useStyles = makeStyles((theme) => ({
    root: {},
    number: {
        fontWeight: 300,
        color: theme.palette.text.secondaryLight,
        fontSize: '24px',
        marginLeft: `${theme.spacing(1)}px`
    },
    item: {
        marginTop: `${theme.spacing(8)}px`
    },
    title: {
        display: 'flex',
        alignItems: 'center'
    },
    icon: {
        marginRight: `${theme.spacing(2)}px`,
        color: theme.palette.primary.main,
        padding: '2px'
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: theme.spacing(0, 4),
        marginBottom: theme.spacing(2)
    },
    paper: {
        width: '100%',
        padding: theme.spacing(0, 8, 8, 7),
        borderRadius: '25px',
        '& .MuiGrid-grid-xs-6': {
            maxWidth: 'calc(50% - 50px)',
            flexBasis: 'calc(50% - 50px)',
            '& .MuiGrid-grid-xs-6': {
                maxWidth: 'calc(50% - 15px)',
                flexBasis: 'calc(50% - 15px)'
            }
        }
    },
    button: {
        marginTop: theme.spacing(2)
    }
}));

const formSettings = {
    frequency: {}
};

const frequencies = [
    {
        label: '1x per dag',
        value: 86400
    },
    {
        label: 'Elke 2 dagen',
        value: 86400 * 2
    },
    {
        label: '1x per week',
        value: 604800
    }
];

const CrawlerConfig = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const [form, submitForm] = useForm(formSettings);

    const [config, setConfig] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getFirebaseDataSnapshot(enqueueSnackbar, t, firebase, 'config').then(
            (snapshot) => {
                const result = snapshot.val();
                setLoading(false);
                if (result !== null) setConfig(result);
            },
            () => {
                enqueueSnackbar({
                    message: t('crawler.failedMessages.config'),
                    variant: 'error'
                });
            }
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        submitForm().then((response) => {
            if (response) {
                // Manual assigning to avoid auto filling properties from Javascript object
                firebase
                    .database()
                    .ref(`config`)
                    .update(
                        {
                            frequency: parseInt(response.frequency)
                        },
                        (error) => {
                            if (error) {
                                enqueueSnackbar({
                                    message: t('crawler.config.saveFailed'),
                                    variant: 'error'
                                });
                            } else {
                                enqueueSnackbar({
                                    variant: 'success',
                                    message: t('crawler.config.saveSuccess')
                                });
                                history.push(`/crawler`);
                            }
                        }
                    );
            }
        });
    };

    return (
        <Grid container className={classes.root}>
            <Grid item className={classes.header} xs={12}>
                <Typography variant="h2">{t('crawler.config.title')}</Typography>
            </Grid>

            <Paper className={classes.paper} elevation={0}>
                <Grid container justify="space-between">
                    <Grid item xs={12}>
                        <SelectField
                            label={t('crawler.config.form.frequency')}
                            placeholder="frequency"
                            helper={false}
                            options={frequencies}
                            name="frequency"
                            initialValue={config.frequency}
                            form={form}
                            error={false}
                        />
                    </Grid>

                    <Button
                        variant="contained"
                        color="secondary"
                        className={classes.button}
                        onClick={handleSubmit}
                        label={t('crawler.config.save')}
                    />
                </Grid>
            </Paper>
            <LoaderModal loading={loading} />
        </Grid>
    );
};

export default CrawlerConfig;
