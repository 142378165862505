import React, { createRef } from 'react';
import { useTranslation } from 'react-i18next';
import Moment from 'moment';
import { Button, InputField } from 'shared';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/styles';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
    input: {
        width: '100%',
        marginLeft: 0
    }
}));

/**
 * Dialog which is shown to a SUPER_ADMIN when they request a reset link for a user.
 */
const PasswordResetLinkDialog = (props) => {
    const { onClose, open, data } = props;
    const platformLink = {
        mijnFlow: process.env.REACT_APP_MY_FLOW_DOMAIN
    };

    const linkInput = createRef();
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();
    const { t } = useTranslation();

    const copyToClipboard = (e) => {
        linkInput.current.select();
        document.execCommand('copy');
        enqueueSnackbar({
            variant: 'success',
            message: t('userEdit.passwordResetCopied')
        });
    };

    if (!data) {
        return false;
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{t('userEdit.passwordResetTitle')}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {t('userEdit.passwordResetBody')}{' '}
                    <strong>{Moment(data.requestPasswordResetLinkAsAdmin.expiry).format('D-MM-YYYY HH:mm:ss')}</strong>
                </DialogContentText>
                <DialogContentText id="alert-dialog-description">
                    <InputField
                        name="email"
                        className={classes.input}
                        initialValue={`${platformLink.mijnFlow}/register/password/${data.requestPasswordResetLinkAsAdmin.token}`}
                        type="text"
                        inputRef={linkInput}
                    />
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={copyToClipboard}
                    color="primary"
                    label={t('userEdit.passwordResetCopy')}
                    variant={'outlined'}
                />
                <Button onClick={onClose} color="primary" label={t('userEdit.passwordResetOk')} variant={'outlined'} />
            </DialogActions>
        </Dialog>
    );
};

export default PasswordResetLinkDialog;
