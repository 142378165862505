import React from 'react';
import ReactDOM from 'react-dom';
import Root from './components/Root';
import './locales/i18n';
import * as Sentry from '@sentry/browser';
import { getEnv } from './helpers';

const sentryDsn = getEnv('REACT_APP_SENTRY_DSN');

console.log('sentryDsn', sentryDsn, typeof sentryDsn);

if (typeof sentryDsn === 'string' && sentryDsn.trim().length > 0) {
    Sentry.init({
        dsn: sentryDsn,
        environment: getEnv('REACT_APP_SENTRY_ENVIRONMENT')
    });
}

ReactDOM.render(<Root />, document.getElementById('root'));
