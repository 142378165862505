import gql from 'graphql-tag';

export const CREATE_VACANCY = gql`
    mutation createVacancy($input: CreateVacancyInput!) {
        createVacancy(input: $input) {
            vacancy {
                id
                title
                createdOn
                contactPerson {
                    id
                }
                corporation {
                    id
                    name
                    domain
                    logo {
                        thumbnailUrl
                        url
                    }
                }
                description
                educationGrade {
                    id
                    description
                }
                duration {
                    id
                    description
                }
                type {
                    id
                    description
                }
                publicationPeriod {
                    id
                    endDate
                    startDate
                }
                city {
                    name
                    province
                }
                workingHoursAWeek {
                    value
                }
                expertiseList {
                    description
                    id
                }
                url
            }
        }
    }
`;

export const GET_CORPORATIONS = gql`
    query getCorporations {
        corporations(pagination: { page: 0, pageSize: 100 }) {
            corporations {
                id
                name
                region {
                    id
                }
            }
        }
    }
`;

export const GET_CONTACT_PERSONS = gql`
    query getContactPersons {
        contactPersons {
            id
            email
            profile {
                fullName
            }
        }
    }
`;

export const GET_EXPERTISES = gql`
    query expertises {
        expertises(pagination: { page: 0, pageSize: 50 }) {
            count
            expertises {
                description
                id
            }
        }
    }
`;

export const GET_WORKING_HOURS = gql`
    query getWorkingHours {
        workingHoursList {
            workingHoursList {
                value
                id
                upperLimit
                lowerLimit
            }
        }
    }
`;

export const UPLOAD_DOCUMENT = gql`
    mutation UploadFile($file: Upload!) {
        uploadDocument(file: $file) {
            asset {
                id
                contentType
                url
            }
        }
    }
`;

export const UPLOAD_FILE = gql`
    mutation uploadFile($file: Upload!) {
        uploadFile(file: $file) {
            asset {
                id
                contentType
                url
                name
                description
                thumbnailUrl
                size
                type
            }
        }
    }
`;

export const GET_CITIES = gql`
    query getCities {
        cities {
            cities {
                id
                name
            }
        }
    }
`;

export const GET_VACANCY_TYPES = gql`
    query vacancyTypes {
        paginatedVacancyTypes(pagination: { page: 0, pageSize: 50 }) {
            types {
                description
                id
                isAvailableAsSpecialty
            }
        }
    }
`;

export const GET_EDUCATION_GRADES = gql`
    query educationGrades {
        educationGrades(pagination: { page: 0, pageSize: 50 }) {
            educationGrades {
                id
                description
            }
        }
    }
`;

export const GET_DURATIONS = gql`
    query {
        durations(pagination: { page: 0, pageSize: 50 }) {
            durations {
                id
                description
            }
        }
    }
`;

export const GET_VISIBILITIES = gql`
    query visibilities {
        vacancyVisibilities(pagination: { page: 0, pageSize: 50 }) {
            vacancyVisibilities {
                description
                id
            }
        }
    }
`;

export const GET_SALARY_SCALES = gql`
    query salaryScales {
        salaryScales {
            types {
                description
                id
            }
        }
    }
`;

export const DELETE_VACANCY = gql`
    mutation deleteVacancy($input: DeleteVacancyInput!) {
        deleteVacancy(input: $input)
    }
`;
