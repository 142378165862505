import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { makeStyles } from '@material-ui/styles';
import { Add } from '@material-ui/icons';
import { Box, Grid, Paper, Typography } from '@material-ui/core';
import ClearTwoToneIcon from '@material-ui/icons/ClearTwoTone';
import {
    AutoCompleteInput,
    Button,
    Checkbox,
    DatePicker,
    FileUpload,
    InputField,
    JpegFile,
    Loader,
    PngFile,
    RichInputField,
    SelectField
} from 'shared';
import { LoaderModal, SubMenu } from 'shared';
import { useForm } from 'hooks';
import {
    CREATE_VACANCY,
    GET_CITIES,
    GET_CONTACT_PERSONS,
    GET_DURATIONS,
    GET_EDUCATION_GRADES,
    GET_EXPERTISES,
    GET_SALARY_SCALES,
    GET_VACANCY_TYPES,
    GET_VISIBILITIES,
    GET_WORKING_HOURS,
    UPLOAD_DOCUMENT,
    UPLOAD_FILE
} from '../queries';
import { useSnackbar } from 'notistack';
import { getFirebaseDataSnapshot, initializeFirebase } from '../FirebaseInstance';
import * as firebase from 'firebase';
import { convertFromHTML } from 'draft-convert';
import { compose } from 'draft-extend';
import { convertToRaw, EditorState } from 'draft-js';
import FormHelperText from '@material-ui/core/FormHelperText';
import ClearIcon from '@material-ui/icons/Clear';
import { isJson } from '../../../helpers';

// Some fields should be set to a default value when they can't be crawled
const defaultFieldInput = {
    contactPerson: 'externpackagesweb.nl',
    vacancyVisibility: 'Openbaar',
    unknown: 'Onbekend'
};

const useStyles = makeStyles((theme) => ({
    root: {
        background: theme.palette.background.secondary,
        paddingBottom: `${theme.spacing(12)}px`
    },
    grid: {
        maxWidth: '1165px',
        margin: '0 auto'
    },
    paper: {
        width: '100%',
        padding: `${theme.spacing(7)}px ${theme.spacing(8)}px ${theme.spacing(8)}px`,
        borderRadius: '25px',

        '& .MuiGrid-grid-xs-6': {
            maxWidth: 'calc(50% - 50px)',
            flexBasis: 'calc(50% - 50px)',
            '& .MuiGrid-grid-xs-6': {
                maxWidth: 'calc(50% - 15px)',
                flexBasis: 'calc(50% - 15px)'
            }
        }
    },
    title: {
        textAlign: 'center',
        paddingTop: `${theme.spacing(8)}px`,
        paddingBottom: `${theme.spacing(2)}px`
    },
    heading: {
        ...theme.typography.h6,
        fontWeight: 600,
        paddingBottom: `${theme.spacing(2)}px`,
        borderBottom: '1px solid ' + theme.palette.borderColor.main,
        marginBottom: `${theme.spacing(1)}px`
    },
    label: {
        ...theme.typography.h6,
        fontWeight: 500,
        fontSize: '1.15em',
        padding: `${theme.spacing(2.25)}px 0 ${theme.spacing(1)}px`
    },
    input: {
        width: '100%',
        marginLeft: 0,
        '& .MuiInputLabel-shrink': {
            transform: 'none',
            top: '-20px',
            lineHeight: 1.5,
            fontWeight: '500',
            fontSize: '1.15em'
        }
    },
    customGrid: {
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap'
    },
    gridItem: {
        flex: '0 0 33%'
    },
    addBar: {
        ...theme.typography.body1,
        color: theme.palette.primary.main,
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        padding: `${theme.spacing(1.5)}px 0`,
        cursor: 'pointer',
        userSelect: 'none',
        '&:hover': {
            '& .MuiSvgIcon-root': {
                transform: 'rotate(90deg)'
            }
        },
        '& .MuiSvgIcon-root': {
            marginRight: theme.spacing(1.5),
            transition: 'transform 300ms ease'
        }
    },
    lightbox: {
        marginLeft: 0,
        padding: `${theme.spacing(1.5)}px 0`
    },
    fileIcon: {
        marginRight: theme.spacing(1),
        color: 'currentColor',
        width: '24px',
        height: '24px'
    },
    clearIcon: {
        width: '16px',
        height: '16px',
        marginLeft: theme.spacing(1),
        transition: 'transform 200ms ease',
        cursor: 'pointer',
        '&:hover': {
            transform: 'scale(1.2)',
            color: theme.palette.primary.main
        }
    }
}));

const CrawlerVacancyDetail = (props) => {
    const mounted = useRef();
    const { t } = useTranslation();
    const classes = useStyles();
    const history = useHistory();
    const { id } = useParams();
    const { enqueueSnackbar } = useSnackbar();

    const [vacancy, setVacancy] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    }, []);

    const [createVacancy, { loading: mutationLoading, error: mutationError }] = useMutation(CREATE_VACANCY);

    // Only used to validate "common" fields; Draft is actually used for data storing
    const [form, submitForm] = useForm({
        title: { required: true },
        type: { required: true },
        contactPerson: { required: true },
        description: { required: true },
        duration: { required: true },
        educationGrade: { required: true },
        salaryScale: { required: true },
        workingHoursAWeek: { required: false }
    });

    const [draft, setDraft] = useState({
        title: '',
        type: '',
        educationGrade: '',
        salaryScale: '',
        city: '',
        contactPerson: '',
        duration: '',
        workingHoursAWeek: '',
        description: '',
        expertiseList: [],
        originURL: '',
        publicationPeriod: [
            {
                endDate: '',
                startDate: '',
                visibility: 'VISIBLY_FOR_ALL_CORPORATIONS'
            }
        ]
    });

    const { loading: loadingWorkingHours, data: workingData = {} } = useQuery(GET_WORKING_HOURS, {
        skip: loading,
        onCompleted: (data) => {
            if (data && data.workingHoursList && data.workingHoursList.workingHoursList) {
                const foundWorkingHour = data.workingHoursList.workingHoursList.find(
                    (workingHour) =>
                        workingHour.upperLimit === vacancy.upperHours && workingHour.lowerLimit === vacancy.lowerHours
                );

                if (foundWorkingHour) {
                    setDraft((draft) => ({
                        ...draft,
                        workingHoursAWeek: foundWorkingHour.id
                    }));
                }
            }
        }
    });

    const { loading: loadingExpertises, data: expertisesData = {} } = useQuery(GET_EXPERTISES, {
        skip: loading,
        onCompleted: (data) => {
            if (data && data.expertises && data.expertises.expertises) {
                const workingAreas =
                    (vacancy.workingAreas && vacancy.workingAreas.split(',').map((area) => area.trim())) || [];
                const foundExpertises = data.expertises.expertises.filter(
                    (expertise) =>
                        workingAreas.find(
                            (workingArea) =>
                                workingArea &&
                                expertise.description &&
                                workingArea.toUpperCase() === expertise.description.toUpperCase()
                        ) != null
                );

                // This expertise is the only one which uses comma's, the crawler also uses comma's
                // to differ expertises so this expertise will never be correctly mapped by default
                if (vacancy.workingAreas && vacancy.workingAreas.includes('Woningbeheer, Wijkbeheer en Leefbaarheid')) {
                    const inconsistentWorkingArea = data.expertises.expertises.find(
                        (expertise) =>
                            expertise.description.toUpperCase() ===
                            'Woningbeheer, Wijkbeheer en Leefbaarheid'.toUpperCase()
                    );
                    if (inconsistentWorkingArea) {
                        foundExpertises.push(inconsistentWorkingArea);
                    }
                }

                if (foundExpertises)
                    setDraft((draft) => ({
                        ...draft,
                        expertiseList: foundExpertises.map((expertise) => expertise.id)
                    }));
            }
        }
    });

    const { loading: loadingVacancyTypes, data: typesData = {} } = useQuery(GET_VACANCY_TYPES, {
        skip: loading,
        onCompleted: (data) => {
            if (data && data.paginatedVacancyTypes && data.paginatedVacancyTypes.types) {
                const foundVacancyType = data.paginatedVacancyTypes.types.find((vacancyType) =>
                    vacancyType.description.includes('Vacature')
                );

                if (foundVacancyType)
                    setDraft((draft) => ({
                        ...draft,
                        type: foundVacancyType.id
                    }));
            }
        }
    });

    let { loading: loadingContactPersons, data: contactData = {} } = useQuery(GET_CONTACT_PERSONS, {
        onCompleted: (data) => {
            if (data && data.contactPersons) {
                contactData = {
                    contactPersons: data.contactPersons.sort((a, b) => {
                        if (a.email === defaultFieldInput.contactPerson) return -1;
                        const personA = a.email.toLowerCase();
                        const personB = b.email.toLowerCase();
                        if (personA < personB) return -1;
                        if (personA > personB) return 1;
                        return 0;
                    })
                };

                const foundDefault = data.contactPersons.find(
                    (person) => person.email === defaultFieldInput.contactPerson
                );
                if (foundDefault) {
                    setDraft((draft) => ({
                        ...draft,
                        contactPerson: foundDefault.id
                    }));
                }
            }
        }
    });

    const { loading: loadingEducationGrades, data: educationData = {} } = useQuery(GET_EDUCATION_GRADES, {
        skip: loading,
        onCompleted: (data) => {
            if (data && data.educationGrades && data.educationGrades.educationGrades) {
                const educationGrade =
                    data.educationGrades.educationGrades.find(
                        (grade) => vacancy.education && vacancy.education.toUpperCase() === grade.description
                    ) ||
                    data.educationGrades.educationGrades.find(
                        (grade) => grade.description === defaultFieldInput.unknown
                    );

                if (educationGrade) {
                    setDraft((draft) => ({
                        ...draft,
                        educationGrade: educationGrade.id
                    }));
                }
            }
        }
    });

    const { loading: loadingDurations, data: durationData = {} } = useQuery(GET_DURATIONS, {
        skip: loading,
        onCompleted: (data) => {
            if (data && data.durations && data.durations.durations) {
                const duration =
                    data.durations.durations.find((duration) => duration.description === vacancy.duration) ||
                    data.durations.durations.find((duration) => duration.description === defaultFieldInput.unknown);
                if (duration) {
                    setDraft((draft) => ({
                        ...draft,
                        duration: duration.id
                    }));
                }
            }
        }
    });

    const { loading: loadingVisibilities, data: visibilityData = {} } = useQuery(GET_VISIBILITIES, {
        skip: loading,
        onCompleted: (data) => {
            if (data && data.vacancyVisibilities && data.vacancyVisibilities.vacancyVisibilities) {
                const foundKansenPortaal = data.vacancyVisibilities.vacancyVisibilities.find(
                    (visibility) => visibility.description === defaultFieldInput.vacancyVisibility
                );

                if (foundKansenPortaal) {
                    setDraft((draft) => ({
                        ...draft,
                        publicationPeriod: [
                            {
                                ...draft.publicationPeriod[0],
                                visibility: foundKansenPortaal.id
                            }
                        ]
                    }));
                }
            }
        }
    });

    const { loading: loadingCities, data: cityData = {} } = useQuery(GET_CITIES, {
        skip: loading,
        onCompleted: (data) => {
            if (data && data.cities && data.cities.cities) {
                const foundCity = data.cities.cities.find((city) => city.name === vacancy.location);
                if (foundCity) {
                    setDraft((draft) => ({
                        ...draft,
                        city: foundCity
                    }));
                }
            }
        }
    });
    const { loading: loadingSalaryScales, data: salaryData = {} } = useQuery(GET_SALARY_SCALES, {
        skip: loading,
        onCompleted: (data) => {
            if (data && data.salaryScales && data.salaryScales.types) {
                const foundSalary =
                    data.salaryScales.types.find((salary) => salary.description === vacancy.salary) ||
                    data.salaryScales.types.find((salary) => salary.description === defaultFieldInput.unknown);

                if (foundSalary) {
                    setDraft((draft) => ({
                        ...draft,
                        salaryScale: foundSalary.id
                    }));
                }
            }
        }
    });

    const { workingHoursList = [] } = workingData;
    const { expertises = [] } = expertisesData;
    const { paginatedVacancyTypes = [] } = typesData;
    const { contactPersons = [] } = contactData;
    const { educationGrades = [] } = educationData;
    const { durations = [] } = durationData;
    const { vacancyVisibilities = [] } = visibilityData;
    const { cities = [] } = cityData;
    const { salaryScales: salaryScaleTypes = {} } = salaryData;
    const { types: salaryScales = [] } = salaryScaleTypes;

    const [uploadFile] = useMutation(UPLOAD_FILE);
    const [uploadDocument] = useMutation(UPLOAD_DOCUMENT);

    const mapToSelect = (array, label, value) => {
        if (!Array.isArray(array)) return;

        return array.map((item) => {
            return {
                label: item[label],
                value: item[value]
            };
        });
    };

    const safeCheckValueById = (array, id, key) => {
        if (!array || !Array.isArray(array)) return null;

        const foundValue = array.find((value) => value.id === id);
        return foundValue ? foundValue[key] : null;
    };

    const handleSave = () => {
        submitForm().then((resp) => {
            const variables = {
                originURL: draft.originURL,
                ...resp,
                publicationPeriod: draft.publicationPeriod,
                expertiseList: draft.expertiseList,
                ...(draft.imageOrVideo &&
                    draft.imageOrVideo.id && {
                        imageOrVideo: draft.imageOrVideo.id
                    }),
                city: resp.city ? resp.city.id || null : null,
                workingHoursAWeek: resp.workingHoursAWeek ? resp.workingHoursAWeek || null : null
            };

            if (draft.attachments || draft.attachments === null)
                variables.attachments = draft.attachments === null ? [] : [draft.attachments.id];

            createVacancy({
                variables: {
                    input: variables
                }
            })
                .then((response) => {
                    if (response.errors) {
                        enqueueSnackbar({
                            message: t('crawler.vacancies.saveFailed'),
                            variant: 'error'
                        });
                    }

                    if (response.data) {
                        enqueueSnackbar({
                            variant: 'success',
                            message: t('crawler.vacancies.saveSuccess')
                        });

                        const updateFirebase = { completed: true };
                        if (
                            response.data.createVacancy &&
                            response.data.createVacancy.vacancy &&
                            response.data.createVacancy.vacancy.url
                        ) {
                            updateFirebase.completedUrl =
                                process.env.REACT_APP_VACANCIES_FLOW_DOMAIN + response.data.createVacancy.vacancy.url;
                        }

                        initializeFirebase(firebase)
                            .then(() => {
                                firebase
                                    .database()
                                    .ref(`offers/${id}`)
                                    .update(updateFirebase, (error) => {
                                        if (error) {
                                            enqueueSnackbar({
                                                message: t('crawler.vacancies.crawlerSaveFailed'),
                                                variant: 'error'
                                            });
                                        } else {
                                            firebase
                                                .app()
                                                .delete()
                                                .then(() => {
                                                    history.goBack();
                                                });
                                        }
                                    });
                            })
                            .catch(() =>
                                enqueueSnackbar({
                                    message: t('crawler.firebaseFailed'),
                                    variant: 'error'
                                })
                            );
                    }
                })
                .catch(() => {
                    enqueueSnackbar({
                        message: t('crawler.vacancies.saveFailed'),
                        variant: 'error'
                    });
                });
        });
    };

    const handleFile = useCallback(
        (file, mutation) =>
            new Promise((resolve, reject) => {
                if (file) {
                    mutation({
                        variables: {
                            file: file
                        },
                        context: {
                            hasUpload: true
                        }
                    })
                        .then((response) => {
                            if (response.errors) {
                                // eslint-disable-next-line prefer-promise-reject-errors
                                reject();
                            } else {
                                resolve(response.data);
                            }
                        })
                        .catch((error) => {
                            console.warn('upload error: ', error);
                            // eslint-disable-next-line prefer-promise-reject-errors
                            reject();
                        });
                } else {
                    // eslint-disable-next-line prefer-promise-reject-errors
                    reject();
                }
            }),
        []
    );

    const handleImageOrVideoFile = useCallback(
        (file) => {
            handleFile(file, uploadFile).then((uploadFile) => {
                setDraft((draft) => ({
                    ...draft,
                    imageOrVideo: uploadFile.uploadFile.asset
                }));
            });
        },
        [handleFile, uploadFile]
    );

    const handleAttachmentFile = useCallback(
        (file) => {
            handleFile(file, uploadDocument).then((uploadDocument) => {
                setDraft((draft) => ({
                    ...draft,
                    attachments: uploadDocument.uploadDocument.asset
                }));
            });
        },
        [handleFile, uploadDocument]
    );

    const clearFile = (name) => () => {
        // TODO do something with the file
        setDraft((draft) => ({
            ...draft,
            [name]: null
        }));
    };

    const addPublication = () => {
        const newPeriods = draft.publicationPeriod;
        newPeriods.push({
            endDate: '',
            startDate: '',
            visibility: 'VISIBLY_FOR_ALL_CORPORATIONS'
        });
        setDraft((draft) => ({
            ...draft,
            publicationPeriod: newPeriods
        }));
    };

    const handlePeriodChange = (name, id, value) => {
        const periods = draft.publicationPeriod;
        const periode = periods[id];
        periode[name] = value;

        setDraft((draft) => ({
            ...draft,
            publicationPeriod: periods
        }));
    };

    const handleCheckbox = (id) => {
        const exList = draft.expertiseList;
        if (exList.includes(id)) {
            exList.splice(exList.indexOf(id), 1);
        } else {
            exList.push(id);
        }
        setDraft((draft) => ({
            ...draft,
            expertiseList: exList
        }));
    };

    // Initialize vacancy with values from Firebase
    useEffect(() => {
        getFirebaseDataSnapshot(enqueueSnackbar, t, firebase, `offers/${id}`).then(
            (snapshot) => {
                if (!mounted.current) return;

                const result = snapshot.val();

                if (result !== null) {
                    const start = new Date(result.listedAt ? result.listedAt * 1000 : Date.now());
                    const end = new Date(
                        result.expireAt ? result.expireAt * 1000 : Date.now() + 1000 * 60 * 60 * 24 * 7 * 4
                    );

                    const publicationPeriods = [
                        {
                            startDate: start,
                            endDate: end,
                            visibility: 1
                        }
                    ];

                    let content = result.content;
                    let rawState = content;

                    // If the content is HTML, parse it to the DraftJS Json format
                    if (!isJson(content)) {
                        let html = content || '';
                        html = html.replace(new RegExp(`<p><strong>`, 'g'), '<br><p><strong>');
                        html = html.replace(new RegExp(`<p class="MsoNormal"`, 'g'), '<br><p ');

                        const plugins = compose();
                        const fromHtml = plugins(convertFromHTML);
                        content = EditorState.createWithContent(fromHtml(html));
                        rawState = JSON.stringify(convertToRaw(content.getCurrentContent()));
                    }

                    setDraft((draft) => ({
                        ...draft,
                        originURL: result.url,
                        title: result.title,
                        publicationPeriod: publicationPeriods,
                        description: rawState
                    }));

                    setVacancy(result);
                    setLoading(false);

                    if (result.imageUrl) {
                        fetch(result.imageUrl)
                            .then((response) => response.blob())
                            .then((file) => {
                                let suffix = '';
                                if (file.type === 'image/jpg') suffix = '.jpg';
                                else if (file.type === 'image/jpeg') suffix = '.jpeg';
                                else if (file.type === 'image/png') suffix = '.png';

                                handleFile(
                                    new File([file], result.title.replace(new RegExp('/', 'g'), '') + suffix, {
                                        type: file.type
                                    }),
                                    uploadFile
                                ).then((data) => {
                                    setDraft((draft) => ({
                                        ...draft,
                                        imageOrVideo: data.uploadFile.asset
                                    }));
                                });
                            });
                    }
                } else {
                    setLoading(false);
                }
            },
            () => {
                enqueueSnackbar({
                    message: t('crawler.failedMessages.vacancy'),
                    variant: 'error'
                });
            }
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [handleFile, id, uploadFile]);

    if (
        loadingWorkingHours ||
        loadingExpertises ||
        loadingVacancyTypes ||
        loadingContactPersons ||
        loadingEducationGrades ||
        loadingDurations ||
        loadingVisibilities ||
        loadingCities ||
        loadingSalaryScales ||
        loading
    )
        return <LoaderModal loading={true} />;

    return (
        <React.Fragment>
            <SubMenu
                renderLeft={() => (
                    <Button
                        variant="outlined"
                        label={t('crawler.vacancies.details.cancel')}
                        onClick={() => history.goBack()}
                        iconLeft={() => <ClearTwoToneIcon />}
                    />
                )}
                renderRight={() => (
                    <React.Fragment>
                        <Button
                            variant="contained"
                            customColor="blue"
                            onClick={handleSave}
                            label={t('crawler.vacancies.details.placeVacancy')}
                        />
                    </React.Fragment>
                )}
            />
            <div className={classes.root}>
                {mutationLoading && <Loader />}
                {mutationError && (
                    <div className={classes.message}>
                        <span>{t('login.errorMessage')}</span>
                    </div>
                )}
                <Grid className={classes.grid} container spacing={4} justify="center" alignItems="center">
                    <Grid item xs={12}>
                        <Typography className={classes.title} variant="h2">
                            {t('crawler.vacancies.details.placeVacancy')}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper className={classes.paper} elevation={0}>
                            <Typography className={classes.heading}>Algemene informatie</Typography>

                            <Grid container justify="space-between">
                                <Grid item xs={6}>
                                    <InputField
                                        className={classes.input}
                                        initialValue={draft.title}
                                        name="title"
                                        type="text"
                                        label="Functie"
                                        error={false}
                                        form={form}
                                        errorMessage={t('crawler.vacancies.details.errorTitle')}
                                    />
                                    <SelectField
                                        label="Soort kans"
                                        placeholder="Selecteer"
                                        initialValue={draft.type}
                                        name="type"
                                        options={mapToSelect(paginatedVacancyTypes.types, 'description', 'id')}
                                        helper={false}
                                        error={false}
                                        form={form}
                                        errorMessage={t('crawler.vacancies.details.errorType')}
                                    />
                                    <Grid container justify="space-between">
                                        <Grid item xs={6}>
                                            <SelectField
                                                label="Opleidingsniveau"
                                                placeholder="Selecteer"
                                                initialValue={draft.educationGrade}
                                                name="educationGrade"
                                                options={mapToSelect(
                                                    educationGrades.educationGrades,
                                                    'description',
                                                    'id'
                                                )}
                                                hint={
                                                    (draft.educationGrade === '' ||
                                                        safeCheckValueById(
                                                            educationGrades.educationGrades,
                                                            draft.educationGrade,
                                                            'description'
                                                        ) === defaultFieldInput.unknown) &&
                                                    vacancy.education !== undefined
                                                        ? t('crawler.vacancies.found', {
                                                              value: vacancy.education
                                                          })
                                                        : undefined
                                                }
                                                helper={false}
                                                form={form}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <SelectField
                                                label="Functieschaal/bedrag"
                                                placeholder="Selecteer schaal"
                                                initialValue={draft.salaryScale}
                                                name="salaryScale"
                                                options={mapToSelect(salaryScales, 'description', 'id')}
                                                hint={
                                                    (draft.salaryScale === '' ||
                                                        safeCheckValueById(
                                                            salaryScales,
                                                            draft.salaryScale,
                                                            'description'
                                                        ) === defaultFieldInput.unknown) &&
                                                    vacancy.salary !== undefined
                                                        ? t('crawler.vacancies.found', {
                                                              value: vacancy.salary
                                                          })
                                                        : undefined
                                                }
                                                helper={false}
                                                form={form}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={6}>
                                    {cities && (
                                        <AutoCompleteInput
                                            label="Plaats"
                                            placeholder="Plaats"
                                            noOptionsText="Type en selecteer de plaats van de corporatie"
                                            className={classes.input}
                                            name="city"
                                            hint={
                                                draft.city === '' && vacancy.location !== undefined
                                                    ? t('crawler.vacancies.found', {
                                                          value: vacancy.location
                                                      })
                                                    : undefined
                                            }
                                            initialValue={draft.city}
                                            form={form}
                                            data={{
                                                query: GET_CITIES,
                                                response: 'cities'
                                            }}
                                        />
                                    )}
                                    {contactPersons && (
                                        <SelectField
                                            label="Contactpersoon"
                                            placeholder="Contactpersoon"
                                            helper={false}
                                            initialValue={draft.contactPerson}
                                            options={mapToSelect(contactPersons, 'email', 'id')}
                                            name="contactPerson"
                                            form={form}
                                            error={false}
                                            errorMessage={t('crawler.vacancies.details.errorContact')}
                                        />
                                    )}
                                    <Grid container justify="space-between">
                                        <Grid item xs={6}>
                                            <SelectField
                                                label="Duur"
                                                placeholder="Duration"
                                                helper={false}
                                                options={mapToSelect(durations.durations, 'description', 'id')}
                                                name="duration"
                                                initialValue={draft.duration}
                                                form={form}
                                                error={false}
                                                errorMessage={t('crawler.vacancies.details.errorLength')}
                                            />
                                        </Grid>
                                        {workingHoursList.workingHoursList && (
                                            <Grid item xs={6}>
                                                <SelectField
                                                    label="Aantal uur per week"
                                                    placeholder="Uren"
                                                    helper={false}
                                                    options={mapToSelect(
                                                        workingHoursList.workingHoursList,
                                                        'value',
                                                        'id'
                                                    )}
                                                    hint={
                                                        draft.workingHoursAWeek === '' &&
                                                        vacancy.lowerHours !== undefined &&
                                                        vacancy.upperHours !== undefined
                                                            ? t('crawler.vacancies.found', {
                                                                  value: `${vacancy.lowerHours || '?'} - ${
                                                                      vacancy.upperHours || '?'
                                                                  }`
                                                              })
                                                            : undefined
                                                    }
                                                    name="workingHoursAWeek"
                                                    initialValue={draft.workingHoursAWeek}
                                                    form={form}
                                                    error={false}
                                                    errorMessage={t('crawler.vacancies.details.errorHours')}
                                                />
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <InputField
                                        className={classes.input}
                                        initialValue={draft.originURL}
                                        name="originURL"
                                        type="text"
                                        label="Originele Link"
                                        error={false}
                                        form={form}
                                        errorMessage={t('crawler.vacancies.details.errorTitle')}
                                    />
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>

                    <Grid item xs={12}>
                        <Paper className={classes.paper} elevation={0}>
                            <Typography className={classes.heading}>Publicatie</Typography>
                            <Grid container justify="space-between">
                                {draft.publicationPeriod &&
                                    draft.publicationPeriod.map((item, idx) => (
                                        <Grid key={idx} container justify="space-between">
                                            <Grid item xs={3}>
                                                <DatePicker
                                                    label="Publicatiedatum"
                                                    placeholder=""
                                                    helper={false}
                                                    name="publicationStart"
                                                    onChange={(val) => handlePeriodChange('startDate', idx, val)}
                                                    initialValue={item.startDate}
                                                    error={false}
                                                    errorMessage={t('newVacancy.errorPublicationStart')}
                                                />
                                            </Grid>
                                            <Grid item xs={3}>
                                                <DatePicker
                                                    label="Sluitingsdatum"
                                                    placeholder=""
                                                    helper={false}
                                                    name="publicationEnd"
                                                    onChange={(val) => handlePeriodChange('endDate', idx, val)}
                                                    initialValue={item.endDate}
                                                    error={false}
                                                    errorMessage={t('newVacancy.errorPublicationEnd')}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <SelectField
                                                    label="Zichtbaarheid"
                                                    placeholder="Zichtbaarheid"
                                                    helper={false}
                                                    options={mapToSelect(
                                                        vacancyVisibilities.vacancyVisibilities,
                                                        'description',
                                                        'id'
                                                    )}
                                                    name="visibilityTypes"
                                                    onChange={(val) => handlePeriodChange('visibility', idx, val)}
                                                    initialValue={item.visibility}
                                                    error={false}
                                                    errorMessage={t('vacancyForm.errorVisibilityTypes')}
                                                />
                                            </Grid>
                                        </Grid>
                                    ))}

                                <Grid item xs={6}>
                                    <Grid container justify="space-between">
                                        <Grid container justify="space-between">
                                            <Grid container>
                                                <Typography onClick={addPublication} className={classes.addBar}>
                                                    <Add /> {t('crawler.vacancies.details.addNewDates')}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>

                    <Grid item xs={12}>
                        <Paper className={classes.paper} elevation={0}>
                            <Typography className={classes.heading}>Vakgebieden</Typography>
                            <Typography variant="subtitle1" className={classes.label}>
                                Selecteer vakgebieden (optioneel)
                            </Typography>
                            <Box mt={2} />
                            <div className={classes.customGrid}>
                                {expertises.expertises &&
                                    expertises.expertises.map((item, idx) => (
                                        <div key={idx} className={classes.gridItem}>
                                            <Checkbox
                                                checked={draft.expertiseList.includes(item.id)}
                                                onChange={() => handleCheckbox(item.id)}
                                                label={item.description}
                                                value={item.id}
                                            />
                                        </div>
                                    ))}
                            </div>
                            <FormHelperText>
                                {vacancy &&
                                    vacancy.workingAreas &&
                                    t('crawler.vacancies.details.foundWorkingAreas', {
                                        value: vacancy.workingAreas
                                    })}
                            </FormHelperText>
                        </Paper>
                    </Grid>

                    <Grid item xs={12}>
                        <Paper className={classes.paper} elevation={0}>
                            <Typography className={classes.heading}>Informatie kans</Typography>

                            <Grid container justify="space-between">
                                <Grid item xs={8}>
                                    <Typography className={classes.label}>Upload afbeelding of video</Typography>
                                    <FileUpload
                                        label="Bestand kiezen"
                                        onSucces={handleImageOrVideoFile}
                                        onClear={clearFile('imageOrVideo')}
                                        {...(draft.imageOrVideo &&
                                            draft.imageOrVideo.name && {
                                                acceptLabel: (
                                                    <>
                                                        {draft.imageOrVideo.contentType.match(/jpg|jpeg/) && (
                                                            <JpegFile className={classes.fileIcon} />
                                                        )}
                                                        {draft.imageOrVideo.contentType.match(/png/) && (
                                                            <PngFile className={classes.fileIcon} />
                                                        )}
                                                        {draft.imageOrVideo.name}
                                                        <ClearIcon
                                                            onClick={clearFile('imageOrVideo')}
                                                            className={classes.clearIcon}
                                                        />
                                                    </>
                                                )
                                            })}
                                    />
                                    <FormHelperText>
                                        {t('crawler.vacancies.details.uploadImage')}
                                        <a href={vacancy.imageUrl} rel="noopener noreferrer" target="_blank">
                                            Link
                                        </a>
                                    </FormHelperText>
                                    {draft.description !== '' && (
                                        <RichInputField
                                            className={classes.rich}
                                            initialValue={draft.description}
                                            name="description"
                                            title="Functie omschrijving"
                                            error={false}
                                            form={form}
                                            errorMessage={t('crawler.vacancies.details.errorRichText')}
                                        />
                                    )}

                                    <Typography className={classes.label}>Upload bijlage</Typography>
                                    <FileUpload
                                        name="attachments"
                                        error={false}
                                        label={t('crawler.vacancies.details.chooseFile')}
                                        initialValue={
                                            vacancy.attachments
                                                ? vacancy.attachments.map((i) => {
                                                      return { ...i, type: i.contentType };
                                                  })[0] || null
                                                : null
                                        }
                                        onSucces={handleAttachmentFile}
                                        onClear={clearFile('attachments')}
                                    />
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        </React.Fragment>
    );
};

export default CrawlerVacancyDetail;
