import gql from 'graphql-tag';

export const REQUEST_PASSWORD_RESET_LINK_AS_ADMIN = gql`
    mutation requestPasswordResetLinkAsAdmin($id: Long!) {
        requestPasswordResetLinkAsAdmin(adminPasswordResetInput: { userId: $id }) {
            token
            expiry
        }
    }
`;

export const ARCHIVE_AND_START_NEW_SKILLTEST_FOR_USER = gql`
    mutation archiveAndStartNewSkillTestForUser($id: Long!) {
        archiveAndStartNewSkillTestForUser(userId: $id)
    }
`;

export const SEND_SKILLTEST_REINVITE_TO_USER = gql`
    mutation sendSkillTestReinviteToUser($id: Long!) {
        sendSkillTestReinviteToUser(userId: $id)
    }
`;

export const ARCHIVE_MEETINGS_FOR_USER = gql`
    mutation archiveMeetingsForUser($id: Long!) {
        archiveMeetingsForUser(userId: $id)
    }
`;
