import React from 'react';

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';

const DeleteDialog = (props) => {
    const { open, onClose, title, description, negativeText, positiveText, onNegative, onPositive } = props;

    const handleClose = () => {
        if (onClose) onClose();
    };

    const handlePositive = () => {
        if (onPositive) onPositive();
        handleClose();
    };

    const handleNegative = () => {
        if (onNegative) onNegative();
        handleClose();
    };

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">{description}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleNegative} color="primary">
                        {negativeText}
                    </Button>
                    <Button onClick={handlePositive} color="primary" autoFocus>
                        {positiveText}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default DeleteDialog;
