import React, { useState, useEffect } from 'react';
import { Button } from 'shared';
import { makeStyles } from '@material-ui/styles';

import { Typography } from '@material-ui/core';
import Moment from 'moment';
import RequestSkillTestResetDialog from './RequestSkillTestResetDialog';
import { dateToString, formatDateTime } from '../../helpers';

const useStyles = makeStyles((theme) => ({
    button: {
        margin: theme.spacing(3, 0, 1)
    }
}));

const RequestSkillTestReset = (props) => {
    const { user } = props;
    const [dialogOpen, setDialogOpen] = useState(false);
    const classes = useStyles();

    const openDialog = () => {
        setDialogOpen(true);
    };

    return (
        <div>
            <Typography variant="body1">
                <b>Aanmaak datum:</b> {formatDateTime(user.skillTestStartedOn)}
            </Typography>
            <Typography variant="body1">
                <b>Deel 1 datum afgerond:</b> {formatDateTime(user.skillTest1FinishedOn)}
            </Typography>
            <Typography variant="body1">
                <b>Deel 2 datum afgerond:</b> {formatDateTime(user.skillTestFinishedOn)}
            </Typography>
            <Typography variant="body1">
                <b>Aantal gearchiveerd:</b> {user.skillTestArchivedCount}
            </Typography>

            <Button
                variant={'outlined'}
                label="Reset loopbaanwijzer-test..."
                onClick={openDialog}
                className={classes.button}
            />
            <RequestSkillTestResetDialog open={dialogOpen} onClose={() => setDialogOpen(false)} user={user} />
        </div>
    );
};

export default RequestSkillTestReset;
