import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import classNames from 'classnames';
import { DeleteOutline, ArrowRightAlt } from '@material-ui/icons';
import { TableCell, TableRow } from 'shared';
import { useQuery } from '@apollo/react-hooks';

import { GET_REGION } from './queries';

const useStyles = makeStyles((theme) => ({
    center: {
        display: 'inline-flex',
        alignItems: 'center',
        color: theme.palette.text.secondaryLight
    },
    title: {
        color: theme.palette.text.secondary
    },
    time: {
        marginRight: theme.spacing(1)
    },

    actionButton: {
        border: '1px solid ' + theme.palette.text.secondaryLight,
        borderRadius: '50%',
        color: theme.palette.text.secondary,
        background: theme.palette.common.white,
        width: '50px',
        height: '50px',
        padding: '10px',
        marginLeft: '20px',
        cursor: 'pointer',
        transition: 'all 300ms ease',
        '&:hover': {
            border: '1px solid ' + theme.palette.text.secondary,
            background: theme.palette.text.secondary,
            color: theme.palette.common.white
        }
    }
}));

const CorporationRow = (props) => {
    const { corporation, onClick, onDelete, regionId } = props;
    const { id, name, domain, active } = corporation;

    const classes = useStyles();

    const { data: regionData = {} } = useQuery(GET_REGION, {
        variables: {
            id: regionId
        }
    });

    return (
        <TableRow className={classNames(classes.row, { [classes.link]: onClick })}>
            <TableCell className={classes.nameCell} align="left">
                <Typography className={classes.center}>{name}</Typography>
            </TableCell>
            <TableCell align="left">
                <Typography className={classes.title}>{domain}</Typography>
            </TableCell>
            <TableCell align="left">
                {regionData.region && regionData.region.description && (
                    <Typography className={classes.title}>{regionData.region.description}</Typography>
                )}
            </TableCell>
            <TableCell align="left">
                <Typography className={classes.title}>{active ? 'actief' : 'Niet actief'}</Typography>
            </TableCell>
            <TableCell align="right">
                <div className={classes.center}>
                    <DeleteOutline onClick={() => onDelete(id)} className={classes.actionButton} />
                    <ArrowRightAlt onClick={() => onClick(id)} className={classes.actionButton} />
                </div>
            </TableCell>
        </TableRow>
    );
};

export default CorporationRow;
